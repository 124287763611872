import { FC, useCallback, useReducer } from 'react'
import * as yup from 'yup'
import { locale } from '../../../locales'
import theme from '../../../styles/theme'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import LoadingButton from '@mui/lab/LoadingButton'
import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'
import { StockProductNestedItem } from '../entities/EntitiesV3'
import { StockTrackingHelpers } from '../helpers'
import { AdjustmentReasonCodes, AdjustmentReasons } from '../../../constants'
import { SelectChangeEvent } from '@mui/material/Select/SelectInput'
import FormControl from '@mui/material/FormControl/FormControl'
import InputLabel from '@mui/material/InputLabel/InputLabel'
import Select from '@mui/material/Select/Select'
import MenuItem from '@mui/material/MenuItem/MenuItem'

interface FormValues {
  currentStockPacks: number | undefined
  currentStockUnits: number | undefined
  adjustmentReason?: string | undefined
}
const translation =
  locale.translation.StockTrackingPage.TabLiveStock.InputStockForm
const reasonsTranslations = {
  ...locale.translation.DisposalReasons,
  ...locale.translation.InterBranchTransfer,
}

const reasons = [
  ...AdjustmentReasons[1].reasons,
  ...AdjustmentReasons[2].reasons,
].map((r) => {
  return {
    code: r.code,
    description: reasonsTranslations[r.code],
  }
})

interface InputStockFormState {
  product: StockProductNestedItem
  currentStockPacks: number | undefined
  currentStockUnits: number | undefined
  totalStockUnits: number | undefined
  adjustmentReason?: string | undefined
  formErrors: {
    currentStockPacks: string
    currentStockUnits: string
    adjustmentReason?: string
  }
  isLoading: boolean
  error: string
  warning: string
  textFieldVisible?: boolean
  expanded?: string
  mode: 'RESET' | 'ADJUST'
}

const validateMaxRemoval = (
  mode: 'RESET' | 'ADJUST',
  adjustmentReason: string,
  state: InputStockFormState
) => {
  if (mode === 'ADJUST') {
    if (adjustmentReason === AdjustmentReasonCodes.StockComingIn) {
      return true
    }
    const maxUnits = state.product.currentStockUnits ?? 0
    const stateUnits =
      state.product.packSize != null
        ? (state.currentStockPacks ?? 0) * state.product.packSize +
          (state.currentStockUnits ?? 0)
        : state.currentStockUnits ?? 0
    return stateUnits <= maxUnits
  }
  return true
}

export const validationSchema = (state: InputStockFormState) => {
  return yup.object({
    currentStockPacks: yup
      .number()
      .integer('INTEGER')
      .min(0, 'CURRENT_STOCK_PACKS_MIN')
      .when(
        ['mode', 'adjustmentReason'],
        ([mode, adjustmentReason], schema) => {
          return schema.test('MORE_THAN_STOCK_UNITS', () =>
            validateMaxRemoval(mode, adjustmentReason, state)
          )
        }
      ),
    currentStockUnits: yup
      .number()
      .integer('INTEGER')
      .min(0, 'CURRENT_STOCK_PACKS_MIN')
      .when(
        ['mode', 'adjustmentReason'],
        ([mode, adjustmentReason], schema) => {
          return schema.test('', 'MORE_THAN_STOCK_UNITS', () =>
            validateMaxRemoval(mode, adjustmentReason, state)
          )
        }
      ),
  })
}
export const validateForm = (state: InputStockFormState) => {
  try {
    validationSchema(state).validateSync(state, {
      abortEarly: false,
    })
    return {
      currentStockPacks: '',
      currentStockUnits: '',
      adjustmentReason: '',
    }
  } catch (err: any) {
    const errors = {
      currentStockPacks: '',
      currentStockUnits: '',
      adjustmentReason: '',
    }
    err.inner.forEach((validationError: any) => {
      if (validationError.path === 'currentStockPacks') {
        errors.currentStockPacks = validationError.message
      } else if (validationError.path === 'currentStockUnits') {
        errors.currentStockUnits = validationError.message
      } else if (validationError.path === 'adjustmentReason') {
        errors.adjustmentReason = validationError.message
      }
    })
    return errors
  }
}

type SetCurrentStockPacksAction = {
  type: 'SET_CURRENT_STOCK_PACKS'
  payload: number | undefined
}

type SetCurrentStockUnitsAction = {
  type: 'SET_CURRENT_STOCK_UNITS'
  payload: number | undefined
}

type SetReasonAction = {
  type: 'SET_REASON'
  payload: string
}

type SetLoadingAction = {
  type: 'SET_LOADING'
  payload: boolean
}

type SetFormValuesAction = {
  type: 'SET_FORM_VALUES'
  payload: {
    currentStockPacks: number
    currentStockUnits: number
    reason?: string
    warning: string
  }
}
type SetInputValuesErrorAction = {
  type: 'SET_INPUT_VALUES_ERROR'
  payload: {
    formErrors: {
      currentStockPacks: string
      currentStockUnits: string
    }
    error: string
  }
}

type InputStockFormActions =
  | SetCurrentStockPacksAction
  | SetCurrentStockUnitsAction
  | SetLoadingAction
  | SetFormValuesAction
  | SetReasonAction
  | SetInputValuesErrorAction
const reducer = (
  state: InputStockFormState,
  action: InputStockFormActions
): InputStockFormState => {
  let nextState: InputStockFormState = state
  switch (action.type) {
    case 'SET_CURRENT_STOCK_PACKS':
      nextState = { ...state, currentStockPacks: action.payload, warning: '' }
      break
    case 'SET_CURRENT_STOCK_UNITS':
      nextState = { ...state, currentStockUnits: action.payload, warning: '' }
      break
    case 'SET_REASON':
      nextState = {
        ...state,
        adjustmentReason: action.payload,
        textFieldVisible: Boolean(action.payload),
      }
      nextState = {
        ...nextState,
        formErrors: validateForm(nextState),
      }
      break
    case 'SET_LOADING':
      nextState = { ...state, isLoading: action.payload }
      break
    case 'SET_FORM_VALUES':
      nextState = {
        ...state,
        currentStockPacks: action.payload.currentStockPacks,
        currentStockUnits: action.payload.currentStockUnits,
        warning: action.payload.warning,
      }
      break
    case 'SET_INPUT_VALUES_ERROR':
      nextState = {
        ...state,
        formErrors: action.payload.formErrors,
        error: action.payload.error,
        warning: '',
        isLoading: false,
      }
      break
  }
  return {
    ...nextState,
    formErrors: validateForm(nextState),
  }
}

interface InputStockModalProps {
  selectedProductDetails: StockProductNestedItem
  onSubmitCallback: (formValues: FormValues) => void
  mode: 'RESET' | 'ADJUST'
  isProcessing: boolean
}

const InputStockForm: FC<InputStockModalProps> = ({
  selectedProductDetails,
  onSubmitCallback,
  mode,
  isProcessing,
}) => {
  const [state, dispatch] = useReducer(
    (state: InputStockFormState, action: InputStockFormActions) =>
      reducer(state, action),
    {
      currentStockPacks:
        mode === 'RESET' ? selectedProductDetails.packStockUnits : 0,
      currentStockUnits:
        mode === 'RESET' ? selectedProductDetails.packSplitStockUnits : 0,
      adjustmentReason: '',
      formErrors: {
        currentStockPacks: '',
        currentStockUnits: '',
        adjustmentReason: '',
      },
      isLoading: false,
      error: '',
      warning: '',
      expanded: 'adjust-panel',
      textFieldVisible: false,
      mode,
      product: selectedProductDetails,
    } as InputStockFormState
  )

  const handleInputStockBlur = useCallback(() => {
    // default to 0 on blur if the field is empty
    if (state.currentStockPacks == null) {
      dispatch({
        type: 'SET_CURRENT_STOCK_PACKS',
        payload: 0,
      })
    }
    if (state.currentStockUnits == null) {
      dispatch({
        type: 'SET_CURRENT_STOCK_UNITS',
        payload: 0,
      })
    }

    const errors = validateForm(state)
    dispatch({
      type: 'SET_INPUT_VALUES_ERROR',
      payload: {
        formErrors: errors,
        error: '',
      },
    })
    if (Object.values(errors).some((e) => Boolean(e))) {
      return
    }

    const totalUnits =
      (state.product.packSize ?? 1) * (state.currentStockPacks ?? 0) +
      (state.currentStockUnits ?? 0)

    const { packUnits, splitUnits } =
      StockTrackingHelpers.packsAndSplitUnitsFromUnitsAndPackSize(
        totalUnits,
        state.product.packSize
      )

    dispatch({
      type: 'SET_FORM_VALUES',
      payload: {
        currentStockPacks: packUnits ?? 0,
        currentStockUnits: splitUnits,
        warning:
          state.currentStockUnits !== splitUnits ||
          (state.product.packSize && state.currentStockPacks !== packUnits)
            ? translation.Notifications.NotificationAlreadyCalculatedSplit(
                packUnits,
                splitUnits
              )
            : '',
      },
    })
  }, [state])

  // When the Adjustment Reason is selected, show the TextField
  const handleSelectionChange = (e: SelectChangeEvent<string>) => {
    const value = e.target.value
    dispatch({ type: 'SET_REASON', payload: value })
  }

  const getHelperText = (errorKey: string | null) => {
    switch (errorKey) {
      case 'INTEGER':
        return translation.Validation.CurrentStockPacksOrUnitsValidationInteger
      case 'CURRENT_STOCK_PACKS_REQUIRED':
        return translation.Validation.CurrentStockPacksValidationRequired
      case 'CURRENT_STOCK_PACKS_MIN':
        return translation.Validation.CurrentStockPacksValidationMin
      case 'CURRENT_STOCK_UNITS_REQUIRED':
        return mode === 'RESET'
          ? translation.Validation.CurrentStockUnitsValidationRequired
          : translation.Validation.AdjustedPacksOrUnitsValidationRequired
      case 'CURRENT_STOCK_UNITS_MIN':
        return translation.Validation.CurrentStockUnitsValidationMin
      case 'MAX':
        return translation.Validation.AdjustedPacksValidationMax
      case 'LESS_THAN_PACK_SIZE':
        return translation.Validation.AdjustedUnitsValidationMax
      case 'MORE_THAN_STOCK_UNITS':
        return translation.Validation
          .AdjustedPacksOrUnitsValidationBiggerThanTotalStock
      default:
        return null
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: theme.spacing(2),
        '&:focus-visible': { outline: 'none' },
        boxShadow: 'none',
      }}
    >
      {mode !== 'RESET' && (
        <>
          <FormControl>
            <InputLabel id="select-adjustment-form-label">
              {translation.Fields.SelectAdjustmentReason.Label}
            </InputLabel>
            <Select
              value={state.adjustmentReason}
              onChange={(e) => {
                handleSelectionChange(e)
              }}
              error={Boolean(state.formErrors.adjustmentReason)}
              data-testid="disposal-or-interbranch-reason-select"
              labelId="select-adjustment-form-label"
              label={translation.Fields.SelectAdjustmentReason.Label}
            >
              {reasons.map((cr) => (
                <MenuItem key={cr.code} value={cr.code}>
                  {cr.description}
                </MenuItem>
              ))}
            </Select>
            <Typography variant="body2">
              {state.formErrors.adjustmentReason}
            </Typography>
          </FormControl>
        </>
      )}
      {(state.textFieldVisible || mode === 'RESET') && (
        <>
          {selectedProductDetails.packSize && (
            <TextField
              id="currentStockPacks"
              name="currentStockPacks"
              label={
                mode === 'RESET'
                  ? translation.Fields.StockPacks.Label
                  : state.adjustmentReason ===
                    AdjustmentReasonCodes.StockComingIn
                  ? translation.Fields.AdjustedPacks.Label(false)
                  : translation.Fields.AdjustedPacks.Label(true)
              }
              type="number"
              data-testid="current-stock-packs-input-field"
              value={state.currentStockPacks ?? ''}
              error={Boolean(state.formErrors.currentStockPacks)}
              helperText={getHelperText(state.formErrors.currentStockPacks)}
              onChange={(e) => {
                dispatch({
                  type: 'SET_CURRENT_STOCK_PACKS',
                  payload:
                    e.target.value === ''
                      ? undefined
                      : parseInt(e.target.value, 10),
                })
              }}
              onBlur={handleInputStockBlur}
            ></TextField>
          )}
          <TextField
            id="currentStockUnits"
            name="currentStockUnits"
            label={
              mode === 'RESET'
                ? translation.Fields.StockUnits.Label
                : state.adjustmentReason === AdjustmentReasonCodes.StockComingIn
                ? translation.Fields.AdjustedUnits.Label(false)
                : translation.Fields.AdjustedUnits.Label(true)
            }
            type="number"
            data-testid="current-stock-units-input-field"
            value={state.currentStockUnits ?? ''}
            error={Boolean(state.formErrors.currentStockUnits)}
            helperText={getHelperText(state.formErrors.currentStockUnits)}
            onChange={(e) => {
              dispatch({
                type: 'SET_CURRENT_STOCK_UNITS',
                payload:
                  e.target.value === ''
                    ? undefined
                    : parseInt(e.target.value, 10),
              })
            }}
            onBlur={handleInputStockBlur}
          ></TextField>
        </>
      )}

      <LoadingButton
        data-testid="complete-adding-stock-button"
        disableElevation
        variant="contained"
        color="primary"
        loading={state.isLoading || isProcessing}
        onClick={() => {
          onSubmitCallback({
            currentStockPacks: state.currentStockPacks,
            currentStockUnits: state.currentStockUnits,
            adjustmentReason: state.adjustmentReason,
          } as FormValues)
        }}
        disabled={
          Boolean(state.formErrors.currentStockPacks) ||
          Boolean(state.formErrors.currentStockUnits) ||
          (state.product.packSize != null && state.currentStockPacks == null) ||
          state.currentStockUnits == null ||
          isProcessing
        }
        sx={{
          minWidth: '160px',
        }}
      >
        {translation.Buttons.Submit(
          state.currentStockPacks,
          state.currentStockUnits,
          mode === 'RESET',
          state.product.packSize
        )}
      </LoadingButton>
      {state.warning && (
        <Alert variant="filled" severity="warning">
          {state.warning}
        </Alert>
      )}
    </Box>
  )
}

export default InputStockForm
