import React, { FC, useContext, useReducer } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { locale } from '../../../locales'
import InputStockForm from './InputStockForm'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import AccordionDetails from '@mui/material/AccordionDetails'
import Box from '@mui/material/Box'
import theme from '../../../styles/theme'
import Alert from '@mui/material/Alert'
import Markdown from 'marked-react'
import {
  StockProductNestedItem,
  StockTrackingResetState,
} from '../entities/EntitiesV3'
import { ProductStockUpdateRequest } from '../entities/ProductStockUpdateMessage'
import { GetErrorMessage } from '../../../utils/ErrorHandling'
import { PlatformApiPaths } from '../../../PlatformApiPaths'
import { ServiceContext } from '../../../providers/ServicesProvider'
import { useRecoilValue } from 'recoil'
import { selectedClientState } from '../../../state/SelectedPharmacyState'
import ModalContainer from '../../../components/Interactions/ModalContainer'

const translation =
  locale.translation.StockTrackingPage.TabLiveStock.AdjustmentForm

interface AdjustmentMenuProps {
  selectedProductDetails: StockProductNestedItem
  onCloseCallback: () => void
}

interface FormValues {
  adjustedPacks: number | undefined
  adjustedUnits: number | undefined
  adjustmentReason: string | undefined
}

interface AdjustmentFormState {
  isSubmitting: boolean
  error: string
  expanded: string
  loading: boolean
}

type SetErrorAction = {
  type: 'SET_ERROR'
  payload: string
}

type SetExpandedAction = {
  type: 'SET_EXPANDED'
  payload: string
}

type SetLoadingAction = {
  type: 'SET_LOADING'
  payload: boolean
}

type AdjustmentFormActions =
  | SetErrorAction
  | SetExpandedAction
  | SetLoadingAction

const reducer = (state: AdjustmentFormState, action: AdjustmentFormActions) => {
  let nextState: AdjustmentFormState = state
  switch (action.type) {
    case 'SET_ERROR':
      nextState = { ...state, error: action.payload, loading: false }
      break
    case 'SET_EXPANDED':
      nextState = { ...state, expanded: action.payload }
      break

    case 'SET_LOADING':
      nextState = { ...state, loading: action.payload }
      break
  }
  return {
    ...nextState,
  }
}

const AdjustmentFormNew: FC<AdjustmentMenuProps> = ({
  selectedProductDetails,
  onCloseCallback,
}) => {
  const { platformHttpService } = useContext(ServiceContext)
  const selectedClient = useRecoilValue(selectedClientState)
  const [state, dispatch] = useReducer(
    (state: AdjustmentFormState, action: AdjustmentFormActions) =>
      reducer(state, action),
    {
      isSubmitting: false,
      error: '',
      expanded: 'adjust-panel',
    } as AdjustmentFormState
  )

  const handleChangeAccordionSection =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      if (isExpanded && state.expanded !== panel) {
        dispatch({ type: 'SET_EXPANDED', payload: panel })
      } else if (!isExpanded) {
        dispatch({
          type: 'SET_EXPANDED',
          payload: panel === 'reset-panel' ? 'adjust-panel' : 'reset-panel',
        })
      }
    }

  const handleSubmit = async (formValues: FormValues) => {
    dispatch({ type: 'SET_LOADING', payload: true })
    const body: ProductStockUpdateRequest = {
      productId: selectedProductDetails.productId,
      packSize: selectedProductDetails.packSize ?? null,
      vmpId: selectedProductDetails.vmpId,
      vmppId: selectedProductDetails.vmppId ?? null,
      ampId: selectedProductDetails.ampId ?? null,
      amppId: selectedProductDetails.amppId ?? null,
      batchNumber: selectedProductDetails.batchNumber ?? null,
      expiryDate: selectedProductDetails.expiryDate ?? null,
      adjustmentReasonCode: formValues.adjustmentReason
        ? formValues.adjustmentReason
        : 'RESET',
      packsQuantity: selectedProductDetails.packSize
        ? formValues.adjustedPacks!
        : null,
      unitsQuantity: formValues.adjustedUnits ?? null,
    }

    let response
    // If the adjustment reason is not selected, then it is a reset
    if (!formValues.adjustmentReason) {
      response = await platformHttpService.postAsync(
        PlatformApiPaths.ResetProductStock(selectedClient?.clientId!),
        body,
        'StockBaseUrl'
      )
    }
    // If the adjustment reason is selected, then it is an adjustment
    else {
      response = await platformHttpService.postAsync(
        PlatformApiPaths.AdjustProductStock(selectedClient?.clientId!),
        body,
        'StockBaseUrl'
      )
    }
    if (response.hasErrors) {
      dispatch({
        type: 'SET_ERROR',
        payload: GetErrorMessage(response.statusCode),
      })
    }
  }

  return (
    <ModalContainer
      open={true}
      onClickedClose={() => {
        if (!state.loading) {
          onCloseCallback()
        }
      }}
      alignSelf="center"
      sx={{
        flexGrow: 0,
        maxWidth: '700px',
        minWidth: '600px',
      }}
      centerContent={true}
    >
      <Box>
        {state.error && (
          <Alert variant="filled" severity="error">
            {state.error}
          </Alert>
        )}
        <Box
          sx={{
            paddingY: theme.spacing(2),
            backgroundColor: theme.palette.grey[50],
            borderRadius: theme.spacing(0.5),
            marginBottom: theme.spacing(2),
          }}
        >
          <Typography
            variant="h6"
            color={theme.palette.primary.main}
            paddingLeft={theme.spacing(1)}
          >
            {translation.ProductDetails}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              paddingX: theme.spacing(1),
              gap: theme.spacing(2),
            }}
          >
            <Box
              sx={{
                flex: 1,
              }}
            >
              <Markdown>
                {translation.ProductName(selectedProductDetails.productName)}
              </Markdown>
              <Markdown>
                {translation.PackSize(selectedProductDetails.packSize)}
              </Markdown>
              <Markdown>
                {translation.UnitOfMeasure(
                  selectedProductDetails.unitOfMeasure
                )}
              </Markdown>
              <Markdown>
                {translation.UnitInStock(
                  selectedProductDetails.currentStockUnits
                )}
              </Markdown>
            </Box>
            <Box
              sx={{
                flex: 1,
              }}
            >
              <Markdown>
                {translation.PacksInStock(
                  selectedProductDetails.packStockUnits
                )}
              </Markdown>
              <Markdown>
                {translation.SplitPackUnits(
                  selectedProductDetails.packSplitStockUnits
                )}
              </Markdown>
              <Markdown>
                {translation.Distributor(selectedProductDetails.distributor)}
              </Markdown>
              <Markdown>
                {translation.ExpiryDate(selectedProductDetails.expiryDate)}
              </Markdown>
            </Box>
          </Box>
        </Box>
        <Accordion
          disableGutters={true}
          elevation={0}
          expanded={
            !(
              selectedProductDetails.flags?.resetState ===
              StockTrackingResetState.RESET
            ) || state.expanded === 'reset-panel'
          }
          onChange={handleChangeAccordionSection('reset-panel')}
          sx={{
            '&.Mui-expanded': {
              marginBottom: theme.spacing(2),
            },
            '&:before': {
              display: 'none',
            },
            marginBottom: theme.spacing(2),
            background:
              state.expanded === 'reset-panel'
                ? theme.palette.grey[50]
                : theme.palette.common.white,
            borderRadius: theme.spacing(0.5),
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid>
              <Grid item xs={6}>
                <Typography
                  variant="h6"
                  fontSize={14}
                  color={theme.palette.primary.main}
                >
                  {translation.AccordionReset}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  fontSize={12}
                  color={theme.palette.common.black}
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  {translation.AccordionDescriptionReset}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails sx={{ paddingX: theme.spacing(1) }}>
            <InputStockForm
              selectedProductDetails={selectedProductDetails}
              onSubmitCallback={(formValues) => {
                handleSubmit({
                  adjustedPacks: formValues.currentStockPacks,
                  adjustedUnits: formValues.currentStockUnits,
                } as FormValues)
              }}
              mode="RESET"
              isProcessing={state.loading}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion
          elevation={0}
          disableGutters={true}
          expanded={
            selectedProductDetails.flags?.resetState ===
              StockTrackingResetState.RESET && state.expanded === 'adjust-panel'
          }
          onChange={handleChangeAccordionSection('adjust-panel')}
          defaultExpanded={true}
          sx={{
            '&.Mui-expanded': {
              marginBottom: theme.spacing(2),
            },
            '&:before': {
              display: 'none',
            },
            marginBottom: theme.spacing(2),
            opacity: !(
              selectedProductDetails.flags?.resetState ===
              StockTrackingResetState.RESET
            )
              ? 0.5
              : 1,
            background:
              state.expanded === 'adjust-panel'
                ? theme.palette.grey[50]
                : theme.palette.common.white,
            borderRadius: theme.spacing(0.5),
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid>
              <Grid item xs={6}>
                <Typography
                  variant="h6"
                  fontSize={14}
                  color={theme.palette.primary.main}
                >
                  {translation.AccordionAdjust}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  fontSize={12}
                  color={theme.palette.common.black}
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  {translation.AccordionDescription}
                </Typography>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              paddingX: theme.spacing(3),
            }}
          >
            <InputStockForm
              selectedProductDetails={selectedProductDetails}
              onSubmitCallback={(formValues) => {
                handleSubmit({
                  adjustedPacks: formValues.currentStockPacks,
                  adjustedUnits: formValues.currentStockUnits,
                  adjustmentReason: formValues.adjustmentReason,
                } as FormValues)
              }}
              mode="ADJUST"
              isProcessing={state.loading}
            />
          </AccordionDetails>
        </Accordion>
      </Box>
    </ModalContainer>
  )
}

export default AdjustmentFormNew
