import Box from '@mui/material/Box'
import { FC, useCallback, useEffect, useMemo, useReducer, useRef } from 'react'
import theme from '../../styles/theme'
import { Virtuoso } from 'react-virtuoso'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ButtonBase from '@mui/material/ButtonBase'
import { useStateUpdatedCallback } from '../../hooks/useStateUpdatedCallback'
import { SxProps, Theme } from '@mui/material/styles'
import { locale } from '../../locales'
import Typography from '@mui/material/Typography'

const translation = locale.translation.Common

export interface VirtualizedTreeViewDataItem {
  getRootId: () => string
  children: any[]
}

type FlattenedDataItem = {
  dataItem: VirtualizedTreeViewDataItem
  itemId: string
  level: number
  hasChildren: boolean
}

const flattenDataItems = (
  items: VirtualizedTreeViewDataItem[],
  level: number = 0,
  parentId: string = ''
): FlattenedDataItem[] => {
  const result: FlattenedDataItem[] = []

  items.forEach((item, index) => {
    const itemId = level === 0 ? `${item.getRootId()}` : `${parentId}|${index}`
    result.push({
      dataItem: item,
      level,
      itemId,
      hasChildren: item.children.length > 0,
    })
    result.push(...flattenDataItems(item.children, level + 1, itemId))
  })

  return result
}

export interface VirtualizedTreeViewState {
  items: FlattenedDataItem[]
  expandedIds: string[]
}

type SetInitialItemsAction = {
  type: 'SET_INITIAL_ITEMS'
  payload: FlattenedDataItem[]
}

type SetItemsAction = {
  type: 'SET_ITEMS'
  payload: VirtualizedTreeViewDataItem[]
}

type ToggleExpandedAction = {
  type: 'TOGGLE_EXPANDED'
  payload: FlattenedDataItem
}

type TreeViewAction =
  | SetInitialItemsAction
  | SetItemsAction
  | ToggleExpandedAction

const reducer = (
  state: VirtualizedTreeViewState,
  action: TreeViewAction
): VirtualizedTreeViewState => {
  switch (action.type) {
    case 'SET_INITIAL_ITEMS':
      return {
        ...state,
        items: action.payload,
      }
    case 'SET_ITEMS':
      return {
        ...state,
        items: flattenDataItems(action.payload),
      }
    case 'TOGGLE_EXPANDED':
      const isExpanded = state.expandedIds.includes(action.payload.itemId)
      if (isExpanded) {
        return {
          ...state,
          expandedIds: state.expandedIds.filter(
            (id) => !id.startsWith(action.payload.itemId)
          ),
        }
      } else {
        return {
          ...state,
          expandedIds: [...state.expandedIds, action.payload.itemId],
        }
      }
    default:
      return state
  }
}

export interface VirtualizedTreeViewProps {
  data: VirtualizedTreeViewDataItem[]
  renderItem: (item: VirtualizedTreeViewDataItem, level: number) => JSX.Element
  onStateUpdated: (state: VirtualizedTreeViewState) => void
  maxNestingLevel: number
  levelDistanceChUnit: number
  initialState?: VirtualizedTreeViewState
  renderHeader?: () => JSX.Element
  headerSx?: SxProps<Theme>
  rowSx?: SxProps<Theme>
  onRangeChanged?: (firstItemIndex: number) => void
  onEndReached?: (index: number) => void
  initialFirstItemIndex?: number
}
const VirtualizedTreeView: FC<VirtualizedTreeViewProps> = (props) => {
  const firstRender = useRef(true)

  const [state, dispatch] = useReducer(
    reducer,
    props.initialState ?? {
      items: [],
      expandedIds: [],
    }
  )

  const dataItems = useMemo(() => {
    if (!firstRender.current) {
      return props.data
    }
    return null
  }, [props.data])

  useEffect(() => {
    if (dataItems) {
      dispatch({ type: 'SET_ITEMS', payload: dataItems })
    }
  }, [dataItems])

  const virtuosoVisibleItems = useMemo(() => {
    const itemParentIsExpanded = (item: FlattenedDataItem) => {
      const parts = item.itemId.split('|')
      const parentId = parts.slice(0, parts.length - 1).join('|')
      return state.expandedIds.includes(parentId)
    }
    return state.items.filter(
      (item) => item.level === 0 || itemParentIsExpanded(item)
    )
  }, [state.expandedIds, state.items])

  const virtuosoTableItemsWithHeader = useMemo(() => {
    if (props.data.length === 0) {
      return []
    }
    return props.renderHeader
      ? [{ headerPlaceHolder: true }, ...virtuosoVisibleItems]
      : [...virtuosoVisibleItems]
  }, [props.data, props.renderHeader, virtuosoVisibleItems])

  // It is important that this useEffect stays last!
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
    }
  }, [])

  useStateUpdatedCallback(state, props.onStateUpdated)

  const renderRow = useCallback(
    (
      rowItem: FlattenedDataItem | { headerPlaceHolder: boolean },
      isHeader: boolean,
      isExpanded: boolean
    ) => {
      // const treeViewItemStateItem = item as TreeViewItemState
      const flattenDataItem = rowItem as FlattenedDataItem
      return (
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: `${
              (props.maxNestingLevel + 1) * props.levelDistanceChUnit
            }ch 1fr`,
            borderBottom: isHeader
              ? `1px solid ${theme.palette.grey[300]}`
              : 'none',

            // backgroundColor:
            //   !isHeader &&
            //   ((treeViewItemStateItem.level === 0 && isExpanded) ||
            //     (treeViewItemStateItem.level > 0 &&
            //       treeViewItemStateItem.visible))
            //     ? theme.palette.grey[50]
            //     : 'inherit',
            ...(isHeader ? props.headerSx : props.rowSx),
          }}
        >
          {!isHeader ? (
            <>
              <ButtonBase
                disableRipple
                onClick={() =>
                  dispatch({
                    type: 'TOGGLE_EXPANDED',
                    payload: flattenDataItem!,
                  })
                }
                sx={{
                  visibility: !flattenDataItem!.hasChildren
                    ? 'hidden'
                    : 'visible',
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  padding: theme.spacing(0.5),
                }}
              >
                <div
                  style={{
                    width: `${
                      flattenDataItem!.level * props.levelDistanceChUnit
                    }ch`,
                  }}
                ></div>
                {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ButtonBase>
              {props.renderItem(
                flattenDataItem!.dataItem,
                flattenDataItem!.level
              )}
            </>
          ) : (
            <>
              <div></div>
              {props.renderHeader!()}
            </>
          )}
        </Box>
      )
    },
    [props]
  )

  const renderItem = useCallback(
    (index: number) => {
      const isHeader = (props.renderHeader && index === 0) ?? false
      const item = isHeader
        ? { headerPlaceHolder: true }
        : virtuosoVisibleItems[props.renderHeader ? index - 1 : index]
      if (!item) {
        return <></>
      }

      return renderRow(
        item,
        isHeader,
        state.expandedIds.includes((item as FlattenedDataItem).itemId)
      )
    },
    [props.renderHeader, renderRow, state.expandedIds, virtuosoVisibleItems]
  )

  return (
    <Virtuoso
      data={virtuosoTableItemsWithHeader}
      topItemCount={props.renderHeader ? 1 : 0}
      itemContent={renderItem}
      style={{ padding: 0 }}
      initialTopMostItemIndex={props.initialFirstItemIndex}
      endReached={props.onEndReached}
      rangeChanged={(range) => {
        props.onRangeChanged?.(range.startIndex)
      }}
      overscan={{ main: 10, reverse: 10 }}
      components={{
        EmptyPlaceholder: () => (
          <Box
            sx={{ padding: theme.spacing(2) }}
            data-testid="no-data-container"
          >
            <Typography variant="body1">{translation.NoData}</Typography>
          </Box>
        ),
      }}
    />
  )
}

export default VirtualizedTreeView
