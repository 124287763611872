import Box from '@mui/material/Box'
import { FC, useCallback, useContext, useReducer, useState } from 'react'
import theme from '../../styles/theme'
import AutoSizingBox from '../../components/Util/AutoSizingBox'
import {
  ClientCompanyConfig,
  EmailForwardingConfig,
  ProblemProductsConfig,
} from './entities/Entities'
import { locale } from '../../locales'
import { useRecoilValue } from 'recoil'
import { ServiceContext } from '../../providers/ServicesProvider'
import { useGlobalIsLoading } from '../../hooks/useIsLoading'
import { PlatformApiPaths } from '../../PlatformApiPaths'
import { GetErrorMessage } from '../../utils/ErrorHandling'
import { selectedClientState } from '../../state/SelectedPharmacyState'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { ChildState } from '../../types/entities/ChildState'
import PricingEmailSettingsSection, {
  emailSettingsInitialEmptyState,
  EmailsSettingsState,
} from './components/EmailSettingsSection'
import PricingIntelligenceSettingsSection, {
  pricingIntelligenceInitialEmptyState,
  PricingIntelligenceSettigsState,
} from './components/PricingIntelligenceSettingsSection'
import PricefilesReconciliationSettingsSection, {
  initialPriceFileReconciliationEmptyState,
  PriceFileReconciliationSettingsState,
} from './components/PriceFileReconciliationSettingsSection'
import EmailForwardingSection, {
  emailForwardingSettingsInitialEmptyState,
  EmailForwardingSettingsState,
} from './components/EmailForwardingSection'
import Alert from '@mui/material/Alert'

const translations =
  locale.translation.SettingsPage.CompanyManagementTab.CompanyDetailsContainer

interface State {
  pricingEmailsSettings: ChildState<EmailsSettingsState>
  pricingIntelligenceSettings: ChildState<PricingIntelligenceSettigsState>
  priceFilesReconciliationSettings: ChildState<PriceFileReconciliationSettingsState>
  emailForwardingSectionSettings: ChildState<EmailForwardingSettingsState>
}

type SetPricingEmailsSettingsAction = {
  type: 'SET_PRICING_EMAILS_SETTINGS'
  payload: EmailsSettingsState
}

type SetPricingIntelligenceSettingsAction = {
  type: 'SET_PRICING_INTELLIGENCE_SETTINGS'
  payload: PricingIntelligenceSettigsState
}

type SetPriceFileReconciliationThresholdAction = {
  type: 'SET_PRICE_FILE_RECONCILIATION_SETTINGS_SECTION'
  payload: PriceFileReconciliationSettingsState
}

type SetEmailForwardingSettingsAction = {
  type: 'SET_EMAIL_FORWARDING_SETTINGS'
  payload: EmailForwardingSettingsState
}

type Actions =
  | SetPricingEmailsSettingsAction
  | SetEmailForwardingSettingsAction
  | SetPricingIntelligenceSettingsAction
  | SetPriceFileReconciliationThresholdAction

const reducer = (state: State, action: Actions): State => {
  switch (action.type) {
    case 'SET_PRICING_EMAILS_SETTINGS':
      return {
        ...state,
        pricingEmailsSettings: {
          state: {
            ...action.payload,
          },
          key: Math.random(),
        },
      }
    case 'SET_PRICING_INTELLIGENCE_SETTINGS':
      return {
        ...state,
        pricingIntelligenceSettings: {
          state: {
            ...action.payload,
          },
          key: Math.random(),
        },
      }

    case 'SET_PRICE_FILE_RECONCILIATION_SETTINGS_SECTION':
      return {
        ...state,
        priceFilesReconciliationSettings: {
          state: {
            ...action.payload,
          },
          key: Math.random(),
        },
      }

    case 'SET_EMAIL_FORWARDING_SETTINGS':
      return {
        ...state,
        emailForwardingSectionSettings: {
          state: {
            email: action.payload.email,
            suppliers: action.payload.suppliers ?? [],
          },
          key: Math.random(),
        },
      }

    default:
      return state
  }
}

const CompanySettings: FC<{
  clientName: string | null
  clientProblemProductsConfig: ProblemProductsConfig | null
  clientEmailForwardingConfig: EmailForwardingConfig | null
  fetchCompanyDetails: () => void
}> = ({ fetchCompanyDetails, ...props }) => {
  const { platformHttpService } = useContext(ServiceContext)
  const selectedClient = useRecoilValue(selectedClientState)
  const { setIsLoading } = useGlobalIsLoading()
  const [error, setError] = useState<string | null>(null)

  const [state, dispatch] = useReducer(reducer, {
    pricingEmailsSettings: {
      state: {
        emails:
          props.clientProblemProductsConfig?.recipientEmails ??
          emailSettingsInitialEmptyState.emails,
        hasInvalidEmails: false,
      },
      key: Math.random(),
    },
    pricingIntelligenceSettings: {
      state: {
        rollConcessionsOver:
          props.clientProblemProductsConfig?.rollConcessionsOver ??
          pricingIntelligenceInitialEmptyState.rollConcessionsOver,
        useSupplierPrices:
          props.clientProblemProductsConfig?.useSupplierPrices ??
          pricingIntelligenceInitialEmptyState.useSupplierPrices,
        useTradePrices:
          props.clientProblemProductsConfig?.useTradePrices ??
          pricingIntelligenceInitialEmptyState.useTradePrices,
        profitableProductsLength:
          props.clientProblemProductsConfig?.profitableProductsLength ??
          pricingIntelligenceInitialEmptyState.profitableProductsLength,
        mostExpensiveProductsLength:
          props.clientProblemProductsConfig?.mostExpensiveProductsLength ??
          pricingIntelligenceInitialEmptyState.mostExpensiveProductsLength,
        profitThreshold:
          props.clientProblemProductsConfig?.profitThreshold ??
          pricingIntelligenceInitialEmptyState.profitThreshold,
      },
      key: Math.random(),
    },
    priceFilesReconciliationSettings: {
      state: {
        priceFileReconciliationThreshold:
          props.clientProblemProductsConfig?.priceFileReconciliationThreshold ??
          initialPriceFileReconciliationEmptyState.priceFileReconciliationThreshold,
      },
      key: Math.random(),
    },
    emailForwardingSectionSettings: {
      state: {
        email:
          props.clientEmailForwardingConfig?.email ??
          emailForwardingSettingsInitialEmptyState.email,
        suppliers:
          props.clientEmailForwardingConfig?.suppliers ??
          emailForwardingSettingsInitialEmptyState.suppliers,
        errors: { email: '' },
      },
      key: Math.random(),
    },

    hasEmailForwardingSettingsErrors: false,
  } as State)

  const handleCompanyConfigurationsUpdated = useCallback(
    async (problemProductsConfig: ProblemProductsConfig): Promise<boolean> => {
      if (selectedClient && problemProductsConfig) {
        setError(null)
        setIsLoading(true)
        const response =
          await platformHttpService.postAsync<ClientCompanyConfig>(
            PlatformApiPaths.CompanyConfiguration(selectedClient),
            {
              problemProducts: problemProductsConfig,
            },
            'ClientsBaseUri'
          )
        setIsLoading(false)
        if (!response || response?.hasErrors) {
          setError(GetErrorMessage(response.statusCode))
          return false
        }
        fetchCompanyDetails()
        return true
      }
      return false
    },
    [fetchCompanyDetails, platformHttpService, selectedClient, setIsLoading]
  )

  const handleProblemProductsConfigUpdate = useCallback(
    async (
      states: {
        pricingEmailsSettings?: EmailsSettingsState
        pricingIntelligenceSettings?: PricingIntelligenceSettigsState
        priceFileReconciliationSettings?: PriceFileReconciliationSettingsState
      },
      onSuccess: () => void
    ): Promise<boolean> => {
      states.pricingEmailsSettings =
        states.pricingEmailsSettings ?? state.pricingEmailsSettings.state
      states.pricingIntelligenceSettings =
        states.pricingIntelligenceSettings ??
        state.pricingIntelligenceSettings.state
      states.priceFileReconciliationSettings =
        states.priceFileReconciliationSettings ??
        state.priceFilesReconciliationSettings.state

      const isSuccess = await handleCompanyConfigurationsUpdated({
        recipientEmails: states.pricingEmailsSettings.emails,
        rollConcessionsOver:
          states.pricingIntelligenceSettings.rollConcessionsOver,
        useSupplierPrices: states.pricingIntelligenceSettings.useSupplierPrices,
        useTradePrices: states.pricingIntelligenceSettings.useTradePrices,
        profitableProductsLength:
          states.pricingIntelligenceSettings.profitableProductsLength,
        mostExpensiveProductsLength:
          states.pricingIntelligenceSettings.mostExpensiveProductsLength,
        profitThreshold: states.pricingIntelligenceSettings.profitThreshold,
        priceFileReconciliationThreshold:
          states.priceFileReconciliationSettings
            .priceFileReconciliationThreshold,
      })
      if (isSuccess) {
        onSuccess()
      }
      return isSuccess
    },
    [
      handleCompanyConfigurationsUpdated,
      state.priceFilesReconciliationSettings.state,
      state.pricingEmailsSettings.state,
      state.pricingIntelligenceSettings.state,
    ]
  )

  const handlePricingEmailsChanged = useCallback(
    async (pricingEmailsSettings: EmailsSettingsState) => {
      return await handleProblemProductsConfigUpdate(
        {
          pricingEmailsSettings,
        },
        () => {
          dispatch({
            type: 'SET_PRICING_EMAILS_SETTINGS',
            payload: pricingEmailsSettings,
          })
        }
      )
    },
    [handleProblemProductsConfigUpdate]
  )
  const handlePricingIntelligenceSettingsChanged = useCallback(
    async (pricingIntelligenceSettings: PricingIntelligenceSettigsState) => {
      return await handleProblemProductsConfigUpdate(
        {
          pricingIntelligenceSettings,
        },
        () => {
          dispatch({
            type: 'SET_PRICING_INTELLIGENCE_SETTINGS',
            payload: pricingIntelligenceSettings,
          })
        }
      )
    },
    [handleProblemProductsConfigUpdate]
  )

  const handlePriceFileReconciliationThresholdChanged = useCallback(
    async (
      priceFileReconciliationSettings: PriceFileReconciliationSettingsState
    ) => {
      return await handleProblemProductsConfigUpdate(
        {
          priceFileReconciliationSettings,
        },
        () => {
          dispatch({
            type: 'SET_PRICE_FILE_RECONCILIATION_SETTINGS_SECTION',
            payload: priceFileReconciliationSettings,
          })
        }
      )
    },
    [handleProblemProductsConfigUpdate]
  )

  const handleEmailForwardingConfigurationsUpdated = useCallback(
    async (emailForwardingConfig: EmailForwardingConfig): Promise<boolean> => {
      if (selectedClient && emailForwardingConfig) {
        setError(null)
        setIsLoading(true)

        const response =
          await platformHttpService.putAsync<ClientCompanyConfig>(
            PlatformApiPaths.CompanyEmailForwardingConfiguration(
              selectedClient
            ),
            {
              email: emailForwardingConfig.email,
              suppliers: emailForwardingConfig.suppliers,
            },
            'ClientsBaseUri'
          )
        setIsLoading(false)
        if (!response || response?.hasErrors) {
          setError(GetErrorMessage(response.statusCode))
          return false
        }
        dispatch({
          type: 'SET_EMAIL_FORWARDING_SETTINGS',
          payload: emailForwardingConfig,
        })
        fetchCompanyDetails()
        return true
      }
      return false
    },
    [fetchCompanyDetails, platformHttpService, selectedClient, setIsLoading]
  )

  const renderPriceEmailsSection = () => {
    return (
      <PricingEmailSettingsSection
        onChangesApplied={handlePricingEmailsChanged}
        initialState={state.pricingEmailsSettings.state}
        key={state.pricingEmailsSettings.key}
      />
    )
  }

  const renderPricingIntelligenceSection = () => {
    return (
      <PricingIntelligenceSettingsSection
        initialState={state.pricingIntelligenceSettings.state}
        onChangesApplied={handlePricingIntelligenceSettingsChanged}
        key={state.pricingIntelligenceSettings.key}
      />
    )
  }

  const renderPriceFilesReconciliationSection = () => {
    return (
      <PricefilesReconciliationSettingsSection
        initialState={state.priceFilesReconciliationSettings.state}
        onChangesApplied={handlePriceFileReconciliationThresholdChanged}
        key={state.priceFilesReconciliationSettings.key}
      />
    )
  }

  const renderEmailForwardingSection = () => {
    return (
      <EmailForwardingSection
        initialState={state.emailForwardingSectionSettings.state}
        onChangesApplied={handleEmailForwardingConfigurationsUpdated}
        key={state.emailForwardingSectionSettings.key}
      />
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          paddingLeft: theme.spacing(1),
        }}
      >
        <AutoSizingBox>
          <>
            {error && (
              <Alert variant="filled" severity="error">
                {error}
              </Alert>
            )}
            <Box
              sx={{
                display: 'flex',
                height: '100%',
                flexDirection: 'column',
                gap: theme.spacing(2),
                padding: theme.spacing(1),
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: theme.spacing(1),
                }}
              >
                <Typography
                  sx={{
                    paddingY: theme.spacing(1),
                    margin: 0,
                    color: theme.palette.grey[700],
                  }}
                  variant="button"
                >
                  {translations.CompanyDetailsSection.Title}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: theme.spacing(2),
                    marginBottom: theme.spacing(2),
                  }}
                >
                  <TextField
                    label={translations.CompanyDetailsSection.CompanyNameLabel}
                    value={selectedClient?.name}
                    variant="outlined"
                    disabled
                    fullWidth
                  />
                </Box>
                <Typography
                  sx={{
                    paddingY: theme.spacing(0.5),
                    margin: 0,
                    color: theme.palette.grey[700],
                  }}
                  variant="button"
                >
                  {translations.PricingIntelligenceSection.Title}
                </Typography>
                <Box
                  sx={{
                    border: '1px solid',
                    borderColor: 'lightgray',
                    padding: '16px',
                    borderRadius: '8px',
                  }}
                >
                  {renderPriceEmailsSection()}
                  {renderPricingIntelligenceSection()}
                  {renderPriceFilesReconciliationSection()}
                </Box>
                <Typography
                  sx={{
                    paddingBottom: theme.spacing(0.5),
                    paddingTop: theme.spacing(2),
                    margin: 0,
                    color: theme.palette.grey[700],
                  }}
                  variant="button"
                >
                  {translations.EmailForwardingSection.Title}
                </Typography>
                <Box
                  sx={{
                    border: '1px solid',
                    borderColor: 'lightgray',
                    padding: '16px',
                    borderRadius: '8px',
                  }}
                >
                  {renderEmailForwardingSection()}
                </Box>
              </Box>
            </Box>
          </>
        </AutoSizingBox>
      </Box>
    </Box>
  )
}

export default CompanySettings
