import { FC, useCallback, useReducer, useState } from 'react'
import { locale } from '../../../locales'
import EditableSection from './EditableSection'

import Box from '@mui/material/Box/Box'
import Typography from '@mui/material/Typography/Typography'
import VirtualizedSelectableList from '../../../components/Data/VirtualizedSelectableList'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { ClientPharmacy } from '../entities/Entities'
import theme from '../../../styles/theme'
const translations =
  locale.translation.SettingsPage.GroupsManagementTab.GroupsManagement
    .GroupDetailsContainer

export interface GroupPharmaciesSelectionSettingsState {
  clientPharmacies: ClientPharmacy[]
  groupPharmacies: ClientPharmacy[]
}

interface GroupPharmaciesSelectionSettingsSectionState {
  groupsPharmaciesSelectionSettings: GroupPharmaciesSelectionSettingsState
}

interface InitialiseListsAction {
  type: 'INITIALISE_LISTS'
  payload: {
    clientPharmacies: ClientPharmacy[]
    groupPharmacies: ClientPharmacy[]
  }
}

interface MovePharmacyToGroupAction {
  type: 'MOVE_PHARMACY_TO_GROUP'
  payload: ClientPharmacy
}

interface MovePharmacyToClientAction {
  type: 'MOVE_PHARMACY_TO_CLIENT'
  payload: {
    pharmacyToMove: ClientPharmacy
    initialClientPharmacies: ClientPharmacy[]
  }
}

type Actions =
  | InitialiseListsAction
  | MovePharmacyToGroupAction
  | MovePharmacyToClientAction

const reducer = (
  state: GroupPharmaciesSelectionSettingsSectionState,
  action: Actions
) => {
  let nextState: GroupPharmaciesSelectionSettingsSectionState = state
  const clientPharmaciesWithoutGroupPharmacies = (
    clientPharmacies: ClientPharmacy[],
    groupPharmacies: ClientPharmacy[]
  ) => {
    return clientPharmacies.filter(
      (p) => groupPharmacies.findIndex((gp) => gp.odsCode === p.odsCode) === -1
    )
  }

  let updatedGroupPharmacies: ClientPharmacy[]
  switch (action.type) {
    case 'INITIALISE_LISTS':
      nextState = {
        ...state,
        groupsPharmaciesSelectionSettings: {
          clientPharmacies: clientPharmaciesWithoutGroupPharmacies(
            action.payload.clientPharmacies,
            action.payload.groupPharmacies
          ),
          groupPharmacies: action.payload.groupPharmacies,
        },
      }
      break
    case 'MOVE_PHARMACY_TO_GROUP':
      updatedGroupPharmacies = [
        ...state.groupsPharmaciesSelectionSettings.groupPharmacies,
        action.payload,
      ]
      nextState = {
        ...state,
        groupsPharmaciesSelectionSettings: {
          clientPharmacies: clientPharmaciesWithoutGroupPharmacies(
            state.groupsPharmaciesSelectionSettings.clientPharmacies,
            updatedGroupPharmacies
          ),
          groupPharmacies: updatedGroupPharmacies,
        },
      }
      break
    case 'MOVE_PHARMACY_TO_CLIENT':
      updatedGroupPharmacies = [
        ...state.groupsPharmaciesSelectionSettings.groupPharmacies.filter(
          (g) => g.odsCode !== action.payload.pharmacyToMove.odsCode
        ),
      ]
      nextState = {
        ...state,
        groupsPharmaciesSelectionSettings: {
          clientPharmacies: clientPharmaciesWithoutGroupPharmacies(
            action.payload.initialClientPharmacies,
            updatedGroupPharmacies
          ),
          groupPharmacies: updatedGroupPharmacies,
        },
      }
      break
  }
  return nextState
}
export const gropPharmaciesSelectionInitialEmptyState = {
  clientPharmacies: [],
  groupPharmacies: [],
}

const GroupPharmaciesSelectionSection: FC<{
  onChangesApplied: (
    groupsPharmaciesSelectionSettings: GroupPharmaciesSelectionSettingsState
  ) => Promise<boolean>
  initialState: GroupPharmaciesSelectionSettingsState | null
  groupName: string
}> = (props) => {
  const [state, dispatch] = useReducer(reducer, {
    groupsPharmaciesSelectionSettings:
      props.initialState ?? gropPharmaciesSelectionInitialEmptyState,
  } as GroupPharmaciesSelectionSettingsSectionState)

  const [isEditingGroups, setIsEditingGroups] = useState(false)

  const handleClientPharmaciesClick = (item: object) => {
    dispatch({
      type: 'MOVE_PHARMACY_TO_GROUP',
      payload: item as ClientPharmacy,
    })
  }

  const handleGroupPharmaciesClick = (item: object) => {
    dispatch({
      type: 'MOVE_PHARMACY_TO_CLIENT',
      payload: {
        pharmacyToMove: item as ClientPharmacy,
        initialClientPharmacies:
          props.initialState?.clientPharmacies ??
          gropPharmaciesSelectionInitialEmptyState.clientPharmacies,
      },
    })
  }

  const handleApply = useCallback(async () => {
    const isSuccess = await props.onChangesApplied({
      clientPharmacies:
        state.groupsPharmaciesSelectionSettings.clientPharmacies,
      groupPharmacies: state.groupsPharmaciesSelectionSettings.groupPharmacies,
    })

    if (isSuccess) {
      setIsEditingGroups(false)
    }
  }, [props, state.groupsPharmaciesSelectionSettings])

  const handleCancel = useCallback(() => {
    dispatch({
      type: 'INITIALISE_LISTS',
      payload: {
        clientPharmacies:
          props.initialState?.clientPharmacies ??
          gropPharmaciesSelectionInitialEmptyState.clientPharmacies,
        groupPharmacies:
          props.initialState?.groupPharmacies ??
          gropPharmaciesSelectionInitialEmptyState.groupPharmacies,
      },
    })
  }, [props.initialState])

  return (
    <EditableSection
      sectionTitle={translations.GroupPharmacies}
      updateDisabled={false}
      onUpdate={handleApply}
      onCancel={handleCancel}
      onEditingChanged={setIsEditingGroups}
      isEditing={isEditingGroups}
      sx={{ flexGrow: 1 }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
          gap: theme.spacing(2),
          opacity: isEditingGroups ? 1 : 0.5,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(1),
          }}
        >
          <Typography variant="button" sx={{ fontWeight: 600 }}>
            {translations.AvailablePharmacies(props.groupName)}
          </Typography>
          <VirtualizedSelectableList
            disabled={!isEditingGroups}
            rowHeight={parseInt(theme.spacing(9))}
            rowCount={
              state.groupsPharmaciesSelectionSettings.clientPharmacies.length
            }
            height={'100%'}
            width={'400px'}
            items={state.groupsPharmaciesSelectionSettings.clientPharmacies}
            itemButtonSx={{ paddingLeft: 0 }}
            buttonContent={(p) => {
              const pharmacy = p as ClientPharmacy
              return (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Box sx={{ flexGrow: 1, textAlign: 'left' }}>
                    <Typography variant="body1">
                      {pharmacy.pharmacyName}
                    </Typography>
                    <Typography variant="body2">{pharmacy.odsCode}</Typography>
                  </Box>
                  <Box sx={{ alignSelf: 'center' }}>
                    <ArrowForwardIosIcon />
                  </Box>
                </Box>
              )
            }}
            disableSelectionHighlight={true}
            onClickedItem={handleClientPharmaciesClick}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(1),
          }}
        >
          <Typography variant="button" sx={{ fontWeight: 600 }}>
            {translations.PharmaciesInGroup(props.groupName)}
          </Typography>
          <VirtualizedSelectableList
            disabled={!isEditingGroups}
            rowHeight={parseInt(theme.spacing(2))}
            rowCount={
              state.groupsPharmaciesSelectionSettings.groupPharmacies.length
            }
            height={'100%'}
            width={'400px'}
            items={state.groupsPharmaciesSelectionSettings.groupPharmacies}
            itemButtonSx={{ paddingLeft: 0 }}
            buttonContent={(p) => {
              const pharmacy = p as ClientPharmacy
              return (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Box sx={{ alignSelf: 'center' }}>
                    <ArrowBackIosNewIcon />
                  </Box>
                  <Box
                    sx={{
                      flexGrow: 1,
                      textAlign: 'left',
                      paddingX: theme.spacing(2),
                    }}
                  >
                    <Typography variant="body1">
                      {pharmacy.pharmacyName}
                    </Typography>
                    <Typography variant="body2">{pharmacy.odsCode}</Typography>
                  </Box>
                </Box>
              )
            }}
            disableSelectionHighlight={true}
            onClickedItem={handleGroupPharmaciesClick}
          />
        </Box>
      </Box>
    </EditableSection>
  )
}

export default GroupPharmaciesSelectionSection
