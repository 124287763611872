import { FC, useMemo } from 'react'
import TableMui, { MuiTableColumn } from '../../../components/Data/TableMui'
import { locale } from '../../../locales'
import theme from '../../../styles/theme'
import { getLocaleDateMedWithWeekDayAndHour } from '../../../utils/Helpers'
import { SupplierFeedProduct } from '../entities/BookIn'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

export interface SupplierFeedTableState {
  tableData: string
  creationDate: string
  supplierName: string
}

const translation = locale.translation.GoodsInPage.MultiDeliveryForm

const SupplierFeedTable: FC<{ feedTableData: SupplierFeedTableState }> = ({
  feedTableData,
}) => {
  const products = JSON.parse(
    feedTableData.tableData.replace(/\\/g, '')
  ) as SupplierFeedProduct[]

  const hasPackSize = useMemo(() => {
    return products?.some((product) => product?.packSize != null)
  }, [products])

  const productColumns: MuiTableColumn[] = useMemo(() => {
    const columns: MuiTableColumn[] = [
      {
        label: translation.MultiDeliveryFormProduct.ProductCode,
        propertyName: 'productCode',
        width: '20%',
        calculatedCssProperties: () => ({
          borderColor: theme.palette.grey[50],
        }),
      },
      {
        label: translation.MultiDeliveryFormProduct.ProductName,
        propertyName: 'productName',
        width: '50%',
        calculatedCssProperties: () => ({
          borderColor: theme.palette.grey[50],
        }),
      },

      {
        label: translation.MultiDeliveryFormProduct.Quantity,
        propertyName: 'quantity',
        width: '10%',
        calculatedCssProperties: () => ({
          borderColor: theme.palette.grey[50],
        }),
      },
      {
        label: translation.MultiDeliveryFormProduct.Price,
        propertyName: 'price',
        width: '10%',
        columnType: 'currency',
        calculatedCssProperties: () => ({
          borderColor: theme.palette.grey[50],
        }),
      },
    ]

    if (hasPackSize) {
      columns.splice(2, 0, {
        label: translation.MultiDeliveryFormProduct.PackSize,
        propertyName: 'packSize',
        width: '10%',
        calculatedCssProperties: () => ({
          borderColor: theme.palette.grey[50],
        }),
      })
    }
    return columns
  }, [hasPackSize])

  const productRows = useMemo(() => {
    if (products?.length === 0) return []

    return products?.map((product, itemIndex) => ({
      key: itemIndex.toString(),
      productCode: product?.productCode || '-',
      productName: product.productName || '-',
      packSize: product.packSize || '-',
      quantity: product?.quantity || '-',
      price: product?.price || '-',
    }))
  }, [products])

  return (
    <Box
      sx={{
        maxWidth: '1080px',
        margin: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: theme.spacing(2),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontWeight: 700,
              fontFamily: 'Roboto Mono, monospace',
              marginRight: theme.spacing(1),
            }}
          >
            {translation.Supplier}
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontFamily: 'Roboto Mono, monospace', fontWeight: 400 }}
          >
            {feedTableData.supplierName}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontWeight: 700,
              fontFamily: 'Roboto Mono, monospace',
              marginRight: theme.spacing(1),
            }}
          >
            {translation.IngestionDate}
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontFamily: 'Roboto Mono, monospace', fontWeight: 400 }}
          >
            {getLocaleDateMedWithWeekDayAndHour(feedTableData.creationDate)}
          </Typography>
        </Box>
      </Box>
      <TableMui
        columns={productColumns}
        data={productRows}
        isLoading={false}
        hideHeader={false}
        noRowsMessage={locale.translation.Common.NoData}
        paperSx={{
          '&  .MuiTableCell-root': {
            fontFamily: 'Roboto Mono, monospace',
            fontWeight: 400,
          },
          '& .MuiTableCell-head': {
            fontWeight: 700,
          },
        }}
      />
    </Box>
  )
}

export default SupplierFeedTable
