import { FC, useCallback, useContext, useEffect, useReducer } from 'react'
import {
  PriceFilePresignedUrlResponse,
  SuppliersPricesWithSourceDetails,
  UploadedPriceFile,
} from '../price-file-entities'
import { ServiceContext } from '../../../providers/ServicesProvider'
import { PlatformApiPaths } from '../../../PlatformApiPaths'
import {
  ClientSelection,
  ClientTypes,
} from '../../../types/entities/ClientPermission'
import { GetErrorMessage } from '../../../utils/ErrorHandling'

import VirtuosoMuiTable, {
  VirtuosoColumn,
} from '../../../components/Data/VirtuosoMuiTable'
import { locale } from '../../../locales'
import ModalContainer from '../../../components/Interactions/ModalContainer'
import theme from '../../../styles/theme'
import ClearIcon from '@mui/icons-material/Clear'
import { textFiltering } from '../../../utils/FilteringUtls'
import {
  SortArrayByPropertyKeyArgs,
  sortArrayByPropertyKey,
} from '../../../utils/SortingUtils'
import LoadingButton from '@mui/lab/LoadingButton'
import ConfirmDialog from '../../../components/Interactions/ConfirmDialog'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import {
  DownloadHelpers,
  getLocaleDateMedWithoutWeekDay,
} from '../../../utils/Helpers'
import { PriceFileUploadsStates } from '../../../constants'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Alert from '@mui/material/Alert'
import EditPriceFileDatesDialog from './EditPriceFileDatesDialog'
import { DateTime } from 'luxon'

const translation =
  locale.translation.PriceFilesPage.TabUploads.PriceFileUploadDetailsPopup
const columnNames = translation.TableColumnTitles

const getColumnDefinitions =
  (): VirtuosoColumn<SuppliersPricesWithSourceDetails>[] => {
    const columnDefinitions = [
      {
        label: columnNames[0],
        dataKey: 'sourceProductCode',
        widthCss: '8%',
        sortable: true,
      },
      {
        label: columnNames[1],
        dataKey: 'sourceProductName',
        widthCss: '15%',
        sortable: true,
      },
      {
        label: columnNames[2],
        dataKey: 'sourceProductPackSize',
        widthCss: '5%',
      },
      {
        label: columnNames[3],
        dataKey: 'sourceProductStrength',
        widthCss: '5%',
      },
      {
        label: columnNames[4],
        dataKey: 'sourceProductPrice',
        widthCss: '5%',
        sortable: true,
        currency: true,
      },
    ] as VirtuosoColumn<SuppliersPricesWithSourceDetails>[]
    return columnDefinitions
  }

interface PriceFileDetailsPopupState {
  priceFileData: SuppliersPricesWithSourceDetails[] | null
  filteredPriceFileData: SuppliersPricesWithSourceDetails[]
  sortingState: SortArrayByPropertyKeyArgs
  error: string
  errorButtonBar: string | null
  filterText: string
  isLoading: boolean
  isDeleting: boolean
  showDeletePriceFile: boolean
  priceFilePresignedUrl: string | null
  startDownloading: boolean
  isDownloadingPriceFile: boolean
  isOriginalFile: boolean
  isShowingEditDates: boolean
}

type SetDataStateAction = {
  type: 'SET_PRICE_FILE_DATA'
  payload: SuppliersPricesWithSourceDetails[] | null
}

type SortingStateAction = {
  type: 'SET_SORTING_STATE'
  payload: SortArrayByPropertyKeyArgs
}

type SetErrorAction = {
  type: 'SET_ERROR'
  payload: string
}

type SetErrorButtonBarAction = {
  type: 'SET_ERROR_BUTTON_BAR'
  payload: string | null
}

type SetFilterTextAction = {
  type: 'SET_FILTER_TEXT'
  payload: string
}

type SetIsLoadingAction = {
  type: 'SET_IS_LOADING'
  payload: boolean
}

type SetIsDeletingAction = {
  type: 'SET_IS_DELETING'
  payload: boolean
}

type SetShowDeletePriceFileAction = {
  type: 'SET_SHOW_DELETE_PRICE_FILE'
  payload: boolean
}

type SetIsOriginalFileAction = {
  type: 'SET_IS_ORIGINAL_FILE'
  payload: boolean
}

type SetIsDownloadingPriceFileAction = {
  type: 'SET_IS_DOWNLOADING_PRICE_FILE'
  payload: boolean
}

type SetShowEditDatesAction = {
  type: 'SET_SHOW_EDIT_DATES'
  payload: boolean
}

type Actions =
  | SetDataStateAction
  | SortingStateAction
  | SetErrorAction
  | SetErrorButtonBarAction
  | SetFilterTextAction
  | SetIsLoadingAction
  | SetIsDeletingAction
  | SetShowDeletePriceFileAction
  | SetIsOriginalFileAction
  | SetIsDownloadingPriceFileAction
  | SetShowEditDatesAction

const reducer = (state: PriceFileDetailsPopupState, action: Actions) => {
  const updateFilteredItems = () => {
    state = {
      ...state,
      filteredPriceFileData: filterPriceFileData(state),
    }
  }
  switch (action.type) {
    case 'SET_PRICE_FILE_DATA':
      state = {
        ...state,
        priceFileData: action.payload,
        isLoading: false,
        startDownloading: false,
      }
      updateFilteredItems()
      break
    case 'SET_SORTING_STATE':
      state = {
        ...state,
        sortingState: action.payload,
      }
      updateFilteredItems()
      break
    case 'SET_ERROR':
      state = {
        ...state,
        error: action.payload,
        isLoading: false,
        startDownloading: false,
        isDownloadingPriceFile: false,
      }
      break
    case 'SET_ERROR_BUTTON_BAR':
      state = {
        ...state,
        errorButtonBar: action.payload,
      }
      break
    case 'SET_FILTER_TEXT':
      state = {
        ...state,
        filterText: action.payload,
      }
      updateFilteredItems()
      break
    case 'SET_IS_LOADING':
      state = {
        ...state,
        isLoading: action.payload,
        error: '',
      }
      break
    case 'SET_IS_DELETING':
      state = {
        ...state,
        isDeleting: action.payload,
        errorButtonBar: null,
      }
      break
    case 'SET_SHOW_DELETE_PRICE_FILE':
      state = {
        ...state,
        showDeletePriceFile: action.payload,
      }
      break
    case 'SET_IS_ORIGINAL_FILE':
      state = {
        ...state,
        isOriginalFile: action.payload,
      }
      break
    case 'SET_IS_DOWNLOADING_PRICE_FILE':
      state = {
        ...state,
        isDownloadingPriceFile: action.payload,
      }
      break
    case 'SET_SHOW_EDIT_DATES':
      state = {
        ...state,
        isShowingEditDates: action.payload,
      }
      break
  }
  return state
}

const filterPriceFileData = (
  state: PriceFileDetailsPopupState
): SuppliersPricesWithSourceDetails[] => {
  if (state.priceFileData && state.priceFileData.length > 0) {
    let items = [...state.priceFileData]
    if (state.filterText) {
      items = [
        ...state.priceFileData.filter((i) => {
          return textFiltering(
            state.filterText,
            `${i.sourceProductName} ${i.productName}`
          )
        }),
      ]
    }
    sortArrayByPropertyKey(items, state.sortingState)
    return items
  }
  return []
}

const PriceFileDetailsPopup: FC<{
  selectedPriceFile: UploadedPriceFile
  selectedClient: ClientSelection | null
  supplierName: string
  onClosed: (wasDeleted: boolean) => void
  open: boolean
}> = ({ selectedPriceFile, selectedClient, onClosed, open, supplierName }) => {
  const { platformHttpService, notificationService, baseHttpService } =
    useContext(ServiceContext)

  const [state, dispatch] = useReducer(reducer, {
    priceFileData: null,
    filteredPriceFileData: [],
    sortingState: {
      sortingType: 'ASC',
      sortingPropertyKey: 'sourceProductName',
    },
    error: '',
    errorButtonBar: null,
    filterText: '',
    isLoading: false,
    isDeleting: false,
    showDeletePriceFile: false,
    priceFilePresignedUrl: null,
    startDownloading: false,
    isDownloadingPriceFile: false,
    isOriginalFile: false,
    isShowingEditDates: false,
  } as PriceFileDetailsPopupState)

  const columnDefinitions = getColumnDefinitions()
  const isTextInSearchField = state.filterText.length > 0

  const getPresignedUrl = useCallback(
    async (isOriginalFile: boolean) => {
      const response =
        await platformHttpService.getAsync<PriceFilePresignedUrlResponse>(
          PlatformApiPaths.GetPriceFile(
            selectedClient!.clientId,
            selectedPriceFile.uploadId,
            isOriginalFile
          ),
          'SuppliersPricesBaseUrl'
        )
      if (response.hasErrors || !response.data) {
        dispatch({
          type: 'SET_ERROR',
          payload: GetErrorMessage(response.statusCode),
        })
        return null
      }
      return response.data.presignedUrl
    },
    [platformHttpService, selectedClient, selectedPriceFile.uploadId]
  )

  useEffect(() => {
    if (
      selectedClient &&
      selectedClient.clientType === ClientTypes.Company &&
      selectedPriceFile.currentState === PriceFileUploadsStates.Processed
    ) {
      dispatch({
        type: 'SET_IS_LOADING',
        payload: true,
      })
      getPresignedUrl(false).then(async (presignedUrl) => {
        if (presignedUrl) {
          const response = await baseHttpService.performRequestAsync<
            SuppliersPricesWithSourceDetails[]
          >('GET', presignedUrl, undefined, undefined, undefined, true)
          if (!response.hasErrors && response.data) {
            dispatch({
              type: 'SET_PRICE_FILE_DATA',
              payload: response.data,
            })
          } else {
            dispatch({
              type: 'SET_ERROR',
              payload: GetErrorMessage(response.statusCode),
            })
          }
        }
      })
    }
  }, [
    baseHttpService,
    getPresignedUrl,
    platformHttpService,
    selectedClient,
    selectedPriceFile.currentState,
    selectedPriceFile.uploadId,
  ])

  const handleExportOriginalPriceFile = async () => {
    dispatch({
      type: 'SET_IS_ORIGINAL_FILE',
      payload: true,
    })
    const presignedUrl = await getPresignedUrl(true)
    if (presignedUrl) {
      const response = await baseHttpService.performRequestAsync<Blob>(
        'GET',
        presignedUrl,
        undefined,
        undefined,
        undefined,
        false
      )
      if (!response.hasErrors && response.rawResponse) {
        dispatch({
          type: 'SET_IS_DOWNLOADING_PRICE_FILE',
          payload: true,
        })
        const blob = await response.rawResponse.blob()
        if (blob) {
          DownloadHelpers.storeDownloadedFile(
            blob,
            selectedPriceFile.fileOriginalName
          )
          dispatch({
            type: 'SET_IS_ORIGINAL_FILE',
            payload: false,
          })
        } else {
          dispatch({
            type: 'SET_ERROR',
            payload: GetErrorMessage(response.statusCode),
          })
        }
        dispatch({
          type: 'SET_IS_DOWNLOADING_PRICE_FILE',
          payload: false,
        })
      }
    }
  }

  // Delete a price file
  const handleDeletePriceFile = async () => {
    dispatch({
      type: 'SET_IS_DELETING',
      payload: true,
    })
    const deletePriceFileResponse = await platformHttpService.deleteAsync(
      PlatformApiPaths.DeleteUploadedPriceFile(
        selectedClient!.clientId,
        selectedPriceFile.uploadId
      ),
      null,
      'SuppliersPricesBaseUrl'
    )
    dispatch({
      type: 'SET_IS_DELETING',
      payload: false,
    })
    if (!deletePriceFileResponse.hasErrors) {
      notificationService.showNotification(
        translation.DeletePriceFileSuccessText,
        'warning',
        5000
      )
      onClosed(true)
    } else {
      dispatch({
        type: 'SET_ERROR_BUTTON_BAR',
        payload: GetErrorMessage(deletePriceFileResponse?.statusCode),
      })
    }
  }

  return (
    <>
      <ModalContainer
        open={open}
        onClickedClose={() => {
          onClosed(false)
        }}
        alignSelf="center"
        sx={{
          flexGrow: 1,
          display: 'flex',
          width: '80%',
        }}
        centerContent={true}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box>
            <Typography variant="h6" sx={{ fontWeight: 500 }}>
              {`${supplierName}: ${selectedPriceFile.fileOriginalName}`}
            </Typography>
          </Box>
          <Button
            variant="contained"
            sx={{ cursor: 'pointer' }}
            onClick={() => {
              dispatch({
                type: 'SET_SHOW_EDIT_DATES',
                payload: true,
              })
            }}
          >
            {`${getLocaleDateMedWithoutWeekDay(
              selectedPriceFile.pricesStartDate
            )} - ${
              Date.parse(selectedPriceFile.pricesEndDate) === 253402214400000 // 9999-12-31
                ? translation.Ongoing
                : getLocaleDateMedWithoutWeekDay(
                    selectedPriceFile.pricesEndDate
                  )
            }`}
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: theme.spacing(1.5),
            justifyContent: 'flex-start',
            gap: theme.spacing(1),
          }}
        >
          <TextField
            sx={{ flexGrow: 1 }}
            value={state.filterText}
            onChange={(e) =>
              dispatch({
                type: 'SET_FILTER_TEXT',
                payload: e.target.value,
              })
            }
            placeholder={translation.SearchPlaceholder}
            data-testid="search-text-field"
            InputProps={{
              endAdornment: isTextInSearchField && (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      dispatch({
                        type: 'SET_FILTER_TEXT',
                        payload: '',
                      })
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          ></TextField>
          <Button
            data-testid="export-original-price-file"
            variant="outlined"
            disabled={!state.priceFileData}
            onClick={() => {
              handleExportOriginalPriceFile()
            }}
          >
            {state.isDownloadingPriceFile ? (
              <CircularProgress size={20} />
            ) : (
              <FileDownloadIcon />
            )}
          </Button>
        </Box>
        <LinearProgress
          sx={{
            marginY: theme.spacing(0.5),
            visibility: state.isLoading ? 'visible' : 'hidden',
          }}
        />
        {!state.isLoading && state.filteredPriceFileData != null && (
          <Box sx={{ display: 'flex', flexGrow: 1 }}>
            <VirtuosoMuiTable
              rows={state.filteredPriceFileData}
              columns={columnDefinitions}
              sorting={{
                dataKey: state.sortingState.sortingPropertyKey,
                sortingType: state.sortingState.sortingType,
              }}
              onSortingChanged={(sorting) => {
                dispatch({
                  type: 'SET_SORTING_STATE',
                  payload: {
                    sortingType: sorting?.sortingType ?? 'NONE',
                    sortingPropertyKey: sorting?.dataKey,
                  },
                })
              }}
            />
          </Box>
        )}
        {state.error && (
          <Alert variant="filled" severity="error">
            {state.error}
          </Alert>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            paddingTop: theme.spacing(1.5),
            justifyContent: 'flex-end',
            alignSelf: 'flex-end',
          }}
        >
          {!state.error && !state.isLoading && (
            <LoadingButton
              disableElevation
              variant="contained"
              color="error"
              loading={state.isDeleting}
              onClick={() =>
                dispatch({ type: 'SET_SHOW_DELETE_PRICE_FILE', payload: true })
              }
              sx={{
                minWidth: '160px',
              }}
            >
              {translation.DeletePriceFile}
            </LoadingButton>
          )}
        </Box>
        {state.showDeletePriceFile && (
          <ConfirmDialog
            cancelText={translation.DeletePriceFileCancelText}
            okText={translation.DeletePriceFileOkText}
            title={translation.DeletePriceFileDialogTitle}
            text={translation.DeletePriceFileDialogText}
            isCancelPrimary={true}
            onOk={async () => {
              dispatch({
                type: 'SET_SHOW_DELETE_PRICE_FILE',
                payload: false,
              })
              await handleDeletePriceFile()
            }}
            onCancel={() => {
              dispatch({
                type: 'SET_SHOW_DELETE_PRICE_FILE',
                payload: false,
              })
            }}
          ></ConfirmDialog>
        )}
        {state.errorButtonBar && (
          <Alert
            variant="filled"
            severity="error"
            sx={{
              flexGrow: 1,
              maxHeight: theme.spacing(4),
              marginTop: theme.spacing(1),
            }}
          >
            {state.errorButtonBar}
          </Alert>
        )}
      </ModalContainer>
      {state.isShowingEditDates && (
        <EditPriceFileDatesDialog
          clientId={selectedClient!.clientId}
          uploadId={selectedPriceFile.uploadId}
          startDate={DateTime.fromISO(selectedPriceFile.pricesStartDate)}
          endDate={
            Date.parse(selectedPriceFile.pricesEndDate) === 253402214400000 // 9999-12-31
              ? null
              : DateTime.fromISO(selectedPriceFile.pricesEndDate)
          }
          onClosed={() => {
            dispatch({
              type: 'SET_SHOW_EDIT_DATES',
              payload: false,
            })
          }}
        />
      )}
    </>
  )
}

export default PriceFileDetailsPopup
