import { FC, useCallback, useReducer } from 'react'
import { ClientPharmacy } from '../entities/Entities'
import Box from '@mui/material/Box'
import theme from '../../../styles/theme'

import EditGroupNameSection, {
  EditGroupNameSettingsState,
  groupNameSettingsInitialEmptyState,
} from './EditGroupNameSection'
import { ChildState } from '../../../types/entities/ChildState'
import EmailSettingsSection, {
  EmailsSettingsState,
  emailSettingsInitialEmptyState,
} from './EmailSettingsSection'
import GroupPharmaciesSelectionSection, {
  gropPharmaciesSelectionInitialEmptyState,
  GroupPharmaciesSelectionSettingsState,
} from './GroupPharmaciesSelectionSettingsSection'

interface GroupDetailsContainerState {
  gropusPharmaciesSelectionSettings: ChildState<GroupPharmaciesSelectionSettingsState>
  groupNameSectionSettings: ChildState<EditGroupNameSettingsState>
  complianceEmailSettings: ChildState<EmailsSettingsState>
}

type SetGroupNameSettingsAction = {
  type: 'SET_GROUP_NAME_SETTINGS'
  payload: EditGroupNameSettingsState
}

type SetComplianceEmailsSectionSettingsAction = {
  type: 'SET_COMPLIANCE_EMAILS_SECTION_SETTINGS'
  payload: EmailsSettingsState
}

type SetGroupPharmaciesSelectionSettingsAction = {
  type: 'SET_GROUP_PHARMACIES_SELECTION_SETTINGS'
  payload: GroupPharmaciesSelectionSettingsState
}

const reducer = (
  state: GroupDetailsContainerState,
  action:
    | SetGroupNameSettingsAction
    | SetComplianceEmailsSectionSettingsAction
    | SetGroupPharmaciesSelectionSettingsAction
) => {
  switch (action.type) {
    case 'SET_GROUP_NAME_SETTINGS':
      return {
        ...state,
        groupNameSectionSettings: { state: action.payload, key: Math.random() },
      }
    case 'SET_COMPLIANCE_EMAILS_SECTION_SETTINGS':
      return {
        ...state,
        complianceEmailSettings: { state: action.payload, key: Math.random() },
      }
    case 'SET_GROUP_PHARMACIES_SELECTION_SETTINGS':
      return {
        ...state,
        gropusPharmaciesSelectionSettings: {
          state: action.payload,
          key: Math.random(),
        },
      }
    default:
      return state
  }
}

const GroupDetailsContainer: FC<{
  groupName: string
  groupId: string
  clientPharmacies: ClientPharmacy[]
  groupPharmacies: ClientPharmacy[]
  complianceEmailRecipients: string[]
  isGroupNameUnique: (groupName: string) => boolean
  onGroupUpdated: (
    groupId: string,
    groupName: string,
    groupPharmacies: ClientPharmacy[],
    requiresClientsListUpdate: boolean,
    complianceEmailRecipients: string[]
  ) => Promise<boolean>
}> = (props) => {
  const [state, dispatch] = useReducer(reducer, {
    groupNameSectionSettings: {
      state: {
        groupName:
          props.groupName ?? groupNameSettingsInitialEmptyState.groupName,
      },
      key: Math.random(),
    },
    complianceEmailSettings: {
      state: {
        emails:
          props.complianceEmailRecipients ??
          emailSettingsInitialEmptyState.emails,
      },
      key: Math.random(),
    },
    gropusPharmaciesSelectionSettings: {
      state: {
        clientPharmacies:
          props.clientPharmacies ??
          gropPharmaciesSelectionInitialEmptyState.clientPharmacies,
        groupPharmacies:
          props.groupPharmacies ??
          gropPharmaciesSelectionInitialEmptyState.groupPharmacies,
      },
      key: Math.random(),
    },
  } as GroupDetailsContainerState)

  const handleGroupDetailsUpdated = useCallback(
    async (
      states: {
        groupNameSectionSettings?: EditGroupNameSettingsState
        complianceEmailSettings?: EmailsSettingsState
        gropusPharmaciesSelectionSettings?: GroupPharmaciesSelectionSettingsState
      },
      onSuccess: () => void
    ): Promise<boolean> => {
      states.groupNameSectionSettings =
        states.groupNameSectionSettings ?? state.groupNameSectionSettings.state
      states.complianceEmailSettings =
        states.complianceEmailSettings ?? state.complianceEmailSettings.state
      states.gropusPharmaciesSelectionSettings =
        states.gropusPharmaciesSelectionSettings ??
        state.gropusPharmaciesSelectionSettings.state

      const isSuccess = await props.onGroupUpdated(
        props.groupId,
        states.groupNameSectionSettings.groupName,
        states.gropusPharmaciesSelectionSettings.groupPharmacies,
        true,
        states.complianceEmailSettings.emails
      )
      if (isSuccess) {
        onSuccess()
      }
      return isSuccess
    },
    [
      props,
      state.complianceEmailSettings.state,
      state.gropusPharmaciesSelectionSettings.state,
      state.groupNameSectionSettings.state,
    ]
  )

  const handleGroupNameUpdated = useCallback(
    async (
      groupNameSectionSettings: EditGroupNameSettingsState
    ): Promise<boolean> => {
      return await handleGroupDetailsUpdated(
        { groupNameSectionSettings },
        () => {
          dispatch({
            type: 'SET_GROUP_NAME_SETTINGS',
            payload: groupNameSectionSettings,
          })
        }
      )
    },
    [handleGroupDetailsUpdated]
  )

  const handleComplianceEmailsUpdated = useCallback(
    async (complianceEmailSettings: EmailsSettingsState): Promise<boolean> => {
      return await handleGroupDetailsUpdated(
        { complianceEmailSettings },
        () => {
          dispatch({
            type: 'SET_COMPLIANCE_EMAILS_SECTION_SETTINGS',
            payload: complianceEmailSettings,
          })
        }
      )
    },
    [handleGroupDetailsUpdated]
  )

  const handleGroupPharmaciesSelectionUpdated = useCallback(
    async (
      groupPharmaciesSelectionSettings: GroupPharmaciesSelectionSettingsState
    ): Promise<boolean> => {
      return await handleGroupDetailsUpdated(
        { gropusPharmaciesSelectionSettings: groupPharmaciesSelectionSettings },
        () => {
          dispatch({
            type: 'SET_GROUP_PHARMACIES_SELECTION_SETTINGS',
            payload: groupPharmaciesSelectionSettings,
          })
        }
      )
    },
    [handleGroupDetailsUpdated]
  )

  const renderGroupNameSettingsSection = () => {
    return (
      <EditGroupNameSection
        initialState={state.groupNameSectionSettings.state}
        onChangesApplied={handleGroupNameUpdated}
        isGroupNameUnique={props.isGroupNameUnique}
        key={state.groupNameSectionSettings.key}
      />
    )
  }

  const renderComplianceEmailsSection = () => {
    return (
      <EmailSettingsSection
        onChangesApplied={handleComplianceEmailsUpdated}
        initialState={state.complianceEmailSettings.state}
        key={state.complianceEmailSettings.key}
        isComplianceEmailsSection={true}
      />
    )
  }

  const renderGroupsPharmacySelectionSection = () => {
    return (
      <GroupPharmaciesSelectionSection
        onChangesApplied={handleGroupPharmaciesSelectionUpdated}
        initialState={state.gropusPharmaciesSelectionSettings.state}
        key={state.gropusPharmaciesSelectionSettings.key}
        groupName={props.groupName}
      />
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        gap: theme.spacing(2),
        padding: theme.spacing(1),
      }}
    >
      {renderGroupNameSettingsSection()}
      {renderComplianceEmailsSection()}
      {renderGroupsPharmacySelectionSection()}
    </Box>
  )
}

export default GroupDetailsContainer
