import { FC, useCallback, useReducer, useState } from 'react'
import { useSuppliersUniquePerId } from '../../../hooks/useSuppliers'
import Box from '@mui/material/Box/Box'
import TextField from '@mui/material/TextField/TextField'
import { locale } from '../../../locales'
import theme from '../../../styles/theme'
import * as yup from 'yup'
import FormGroup from '@mui/material/FormGroup/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel/FormControlLabel'
import Checkbox from '@mui/material/Checkbox/Checkbox'
import EditableSection from './EditableSection'

const translations =
  locale.translation.SettingsPage.PharmacySettingsTab.PharmacyDetailsContainer
    .EmailForwardingSection

const validationSchema = yup.object().shape({
  email: yup.string().email('EMAIL_REQUIRED'),
})

export interface EmailForwardingSettingsState {
  email: string
  suppliers: string[]
}

interface EmailForwardingSectionState {
  emailForwardingSettings: EmailForwardingSettingsState
  errors: { email: string }
}

type SetEmailAction = {
  type: 'SET_EMAIL'
  payload: string
}

type SetSuppliersAction = {
  type: 'SET_SUPPLIERS'
  payload: string[]
}

type ResetStateAction = {
  type: 'RESET_STATE'
  payload: EmailForwardingSettingsState
}

type Actions = SetEmailAction | SetSuppliersAction | ResetStateAction

const reducer = (
  state: EmailForwardingSectionState,
  action: Actions
): EmailForwardingSectionState => {
  let nextState: EmailForwardingSectionState = state

  switch (action.type) {
    case 'SET_EMAIL':
      nextState = {
        ...state,
        emailForwardingSettings: {
          ...state.emailForwardingSettings,
          email: action.payload,
        },
      }
      nextState.errors = validateEmailForwardingState(
        nextState.emailForwardingSettings.email,
        nextState.emailForwardingSettings.suppliers
      )
      break
    case 'SET_SUPPLIERS':
      nextState = {
        ...state,
        emailForwardingSettings: {
          ...state.emailForwardingSettings,
          suppliers: action.payload,
        },
      }
      nextState.errors = validateEmailForwardingState(
        nextState.emailForwardingSettings.email,
        nextState.emailForwardingSettings.suppliers
      )
      break
    case 'RESET_STATE':
      nextState = {
        emailForwardingSettings: {
          email: action.payload?.email ?? '',
          suppliers: action.payload?.suppliers ?? [],
        },
        errors: { email: '' },
      }
      break
  }

  return nextState
}

export const validateEmailForwardingState = (
  email: string,
  suppliers: string[]
) => {
  try {
    validationSchema.validateSync({ email }, { abortEarly: false })
    if (!email && suppliers.length > 0) {
      return { email: 'EMAIL_REQUIRED' }
    }
    return { email: '' }
  } catch (err: any) {
    const errors = { email: '' }
    err.inner.forEach((validationError: any) => {
      if (validationError.path === 'email') {
        errors.email = validationError.message
      }
    })
    return errors
  }
}

export const emailForwardingSettingsInitialEmptyState = {
  email: '',
  suppliers: [],
}

const EmailForwardingSection: FC<{
  initialState: EmailForwardingSettingsState | null
  onChangesApplied: (
    emailForwardingSettings: EmailForwardingSettingsState
  ) => Promise<boolean>
}> = (props) => {
  const { suppliersIdDisplayNameDictionary } = useSuppliersUniquePerId()

  const [state, dispatch] = useReducer(reducer, {
    emailForwardingSettings:
      props.initialState ?? emailForwardingSettingsInitialEmptyState,
    errors: { email: '' },
  } as EmailForwardingSectionState)

  const [
    isEditingEmailForwardingSettings,
    setIsEditingEmailForwardingSettings,
  ] = useState(false)

  const handleApply = useCallback(async () => {
    const isSuccess = await props.onChangesApplied(
      state.emailForwardingSettings
    )

    if (isSuccess) {
      setIsEditingEmailForwardingSettings(false)
    }
  }, [props, state.emailForwardingSettings])

  const handleCancel = useCallback(() => {
    setIsEditingEmailForwardingSettings(false)
    dispatch({
      type: 'RESET_STATE',
      payload: {
        email:
          props.initialState?.email ??
          emailForwardingSettingsInitialEmptyState.email,
        suppliers:
          props.initialState?.suppliers ??
          emailForwardingSettingsInitialEmptyState.suppliers,
      },
    })
  }, [props.initialState])

  return (
    <EditableSection
      sectionTitle={translations.Subtitle}
      onUpdate={handleApply}
      updateDisabled={
        !isEditingEmailForwardingSettings || Boolean(state.errors.email)
      }
      onCancel={handleCancel}
      onEditingChanged={setIsEditingEmailForwardingSettings}
      titleSx={{ fontWeight: 600 }}
      isEditing={isEditingEmailForwardingSettings}
      tooltipText={translations.InfoTooltip}
      disableAutoClose={true}
    >
      <Box
        sx={{
          display: 'flex',
          gap: theme.spacing(1),
          marginTop: theme.spacing(1),
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(1),
          }}
        >
          <TextField
            label={translations.EmailsSection.ForwardingEmailAddressesLabel}
            variant="outlined"
            value={state.emailForwardingSettings.email}
            onChange={(e) => {
              dispatch({ type: 'SET_EMAIL', payload: e.target.value })
            }}
            disabled={!isEditingEmailForwardingSettings}
            error={Boolean(state.errors.email)}
            helperText={
              state.errors.email
                ? translations.EmailsSection.ErrorEmailRequired
                : ''
            }
            sx={{ maxWidth: '300px' }}
          />
          <Box>
            <FormGroup
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: theme.spacing(1),
                border: '1px solid',
                borderColor: 'lightgray',
                borderRadius: '6px',
                padding: theme.spacing(1),
              }}
            >
              {Object.entries(suppliersIdDisplayNameDictionary).map(
                ([supplierId, supplierName]) => (
                  <FormControlLabel
                    key={supplierId}
                    control={
                      <Checkbox
                        checked={state.emailForwardingSettings.suppliers.includes(
                          supplierId
                        )}
                        onChange={(e) => {
                          const newSuppliers = e.target.checked
                            ? [
                                ...state.emailForwardingSettings.suppliers,
                                supplierId,
                              ]
                            : state.emailForwardingSettings.suppliers.filter(
                                (id) => id !== supplierId
                              )
                          dispatch({
                            type: 'SET_SUPPLIERS',
                            payload: newSuppliers,
                          })
                        }}
                        disabled={!isEditingEmailForwardingSettings}
                      />
                    }
                    label={supplierName}
                  />
                )
              )}
            </FormGroup>
          </Box>
        </Box>
      </Box>
    </EditableSection>
  )
}

export default EmailForwardingSection
