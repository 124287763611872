import { ClientTypes } from '../types/entities/ClientPermission'
import { User } from '../types/entities/User'

export const FeatureFlagHelpers = {
  getPharmacyWithOrgFeatureFlags: (
    user: User | null,
    odsCode: string | null
  ) => {
    if (!user && !odsCode) {
      return []
    }
    const pharmacy = user!.clientPermissions.find(
      (p) => p.clientId === odsCode && p.clientType === ClientTypes.Pharmacy
    )
    if (pharmacy) {
      const org = user!.clientPermissions.find(
        (p) =>
          p.clientId === pharmacy.parentId &&
          p.clientType === ClientTypes.Company
      )
      const result = [
        ...(pharmacy.setFeatureFlags ?? []),
        ...(org?.setFeatureFlags ?? []),
      ]
      return result.reduce((acc, flag) => {
        if (!acc.includes(flag)) {
          acc.push(flag)
        }
        return acc
      }, new Array<string>())
    }
    return []
  },
}
