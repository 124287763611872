import { FC, useCallback, useReducer, useState } from 'react'
import { locale } from '../../../locales'
import EditableSection from './EditableSection'
import InfoTooltip from '../../../components/Interactions/InfoTooltip'
import Box from '@mui/material/Box/Box'
import { NumericFormat } from 'react-number-format'
import { Theme } from '@mui/material/styles/createTheme'
import { SxProps } from '@mui/system/styleFunctionSx/styleFunctionSx'
import theme from '../../../styles/theme'
import TextField from '@mui/material/TextField/TextField'
const translations =
  locale.translation.SettingsPage.CompanyManagementTab.CompanyDetailsContainer
    .PricingIntelligenceSection.PriceFileReconciliationSettingsSection

const editFieldStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  minWidth: 'max-content',
  alignItems: 'center',
  '& label': {
    marginLeft: theme.spacing(1),
    fontSize: '.9em',
  },
}

const editFieldTextFieldStyle: SxProps<Theme> = {
  maxWidth: '7ch',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  '& .MuiInputBase-input.Mui-disabled': {
    backgroundColor: theme.palette.grey[50],
    WebkitTextFillColor: theme.palette.grey[800],
  },
}

export interface PriceFileReconciliationSettingsState {
  priceFileReconciliationThreshold: number
}

interface PriceFileReconciliationSettingsSectionState {
  priceFileReconciliationSettings: PriceFileReconciliationSettingsState
}

type SetPriceFileReconciliationThresholdAction = {
  type: 'SET_THRESHOLD'
  payload: number
}

type Actions = SetPriceFileReconciliationThresholdAction

const reducer = (
  state: PriceFileReconciliationSettingsSectionState,
  action: Actions
) => {
  let nextState: PriceFileReconciliationSettingsSectionState = state
  switch (action.type) {
    case 'SET_THRESHOLD':
      nextState = {
        ...state,
        priceFileReconciliationSettings: {
          priceFileReconciliationThreshold: action.payload,
        },
      }
      break
  }
  return nextState
}

export const initialPriceFileReconciliationEmptyState = {
  priceFileReconciliationThreshold: 0,
}

const PricefilesReconciliationSettingsSection: FC<{
  onChangesApplied: (
    priceFileReconciliationSettings: PriceFileReconciliationSettingsState
  ) => Promise<boolean>
  initialState: PriceFileReconciliationSettingsState | null
}> = (props) => {
  const [state, dispatch] = useReducer(reducer, {
    priceFileReconciliationSettings:
      props.initialState ?? initialPriceFileReconciliationEmptyState,
  } as PriceFileReconciliationSettingsSectionState)

  const [
    isEditingPriceFileReconciliationSettings,
    setIsEditingPriceFileReconciliationSettings,
  ] = useState(false)

  const handleApply = useCallback(async () => {
    const isSuccess = await props.onChangesApplied(
      state.priceFileReconciliationSettings
    )
    if (isSuccess) {
      setIsEditingPriceFileReconciliationSettings(false)
    }
  }, [props, state.priceFileReconciliationSettings])

  const handleCancel = useCallback(() => {
    setIsEditingPriceFileReconciliationSettings(false)
    dispatch({
      type: 'SET_THRESHOLD',
      payload:
        props.initialState?.priceFileReconciliationThreshold ??
        initialPriceFileReconciliationEmptyState.priceFileReconciliationThreshold,
    })
  }, [props.initialState?.priceFileReconciliationThreshold])

  return (
    <EditableSection
      sectionTitle={translations.Title}
      onUpdate={handleApply}
      updateDisabled={!isEditingPriceFileReconciliationSettings}
      onCancel={handleCancel}
      onEditingChanged={setIsEditingPriceFileReconciliationSettings}
      titleSx={{ fontWeight: 600 }}
      isEditing={isEditingPriceFileReconciliationSettings}
      disableAutoClose={true}
    >
      <Box sx={editFieldStyle}>
        <NumericFormat
          data-testid="company-price-file-reconciliation-threshold-field"
          customInput={TextField}
          thousandSeparator={locale.thousandSeparator}
          decimalSeparator={locale.decimalSeparator}
          decimalScale={0}
          fixedDecimalScale
          allowNegative={false}
          onValueChange={async (v) => {
            const value = parseInt(v.value)
            dispatch({ type: 'SET_THRESHOLD', payload: value })
          }}
          autoComplete="off"
          value={
            state.priceFileReconciliationSettings
              .priceFileReconciliationThreshold
          }
          size="small"
          InputProps={{
            sx: { '& input': { textAlign: 'right' } },
          }}
          sx={{
            ...editFieldTextFieldStyle,
          }}
          disabled={!isEditingPriceFileReconciliationSettings}
        />
        <Box component="label">
          {
            translations.InputNumericFields.ThresholdPriceFileReconciliation
              .Label
          }
        </Box>
        <InfoTooltip
          text={
            translations.InputNumericFields.ThresholdPriceFileReconciliation
              .Info
          }
          sx={{ marginRight: theme.spacing(1) }}
        />
      </Box>
    </EditableSection>
  )
}

export default PricefilesReconciliationSettingsSection
