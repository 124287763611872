import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { ServiceContext } from '../../providers/ServicesProvider'
import { BookInSummaryWithOdsCode } from './entities/BookInSummary'
import { locale } from '../../locales'
import Box from '@mui/material/Box'
import theme from '../../styles/theme'
import Alert from '@mui/material/Alert'
import {
  addOdsCodeColumn,
  getLocaleDateMedWithWeekDay,
  getLocaleDateMedWithWeekDayAndHour,
  getDateOnlyIsoString,
  packSizeConverter,
} from '../../utils/Helpers'
import { BookIn } from './entities/BookIn'
import { sortingTypeToDeliverySearchQueryParams } from './helpers'
import { PlatformApiPaths } from '../../PlatformApiPaths'
import { ClientSelection } from '../../types/entities/ClientPermission'
import { GetErrorMessage } from '../../utils/ErrorHandling'
import {
  BookInStatuses,
  DatePresetsLocalStorageKeys,
  EditDeliveryModes,
  RecoilPageIds,
} from '../../constants'
import VirtuosoMuiTable, {
  VirtuosoColumn,
} from '../../components/Data/VirtuosoMuiTable'
import InfoTooltip from '../../components/Interactions/InfoTooltip'
import { createRecoilPageState, usePageState } from '../PageState'
import FilterBar from '../../components/Interactions/FilterBar'
import { useGlobalIsLoading } from '../../hooks/useIsLoading'
import {
  BookInSummaryWithProductSelectionPageAction,
  BookInSummaryWithProductSelectionPageReducer,
  BookInSummaryWithProductSelectionPageState,
} from './productSelectionState'
import ButtonPopOver, {
  ButtonPopOverHandle,
} from '../../components/Interactions/ButtonPopOver'
import Button from '@mui/material/Button'
import SelectProductModal from './components/SelectProductModal'
import MultiDeliveryForm, {
  MultiDeliveryFormState,
} from './components/MultiDeliveryForm'
import SwitchWithInfo from '../../components/Forms/SwitchWithInfo'
import { useAbortableRequest } from '../../hooks/useAbortableRequest'

const translation = locale.translation.GoodsInPage.TabDeliveryHistory

// Create static objects for the recoil state and reducer
const { recoilPageState, recoilTableState, recoilLoadingAbortedState } =
  createRecoilPageState<BookInSummaryWithOdsCode>(
    RecoilPageIds.TabDeliveryHistory
  )
const pageReducer = new BookInSummaryWithProductSelectionPageReducer()

const getTableColumns = (
  selectedClient: ClientSelection | null,
  odsToPharmacyMapping: { [key: string]: string } | null
): VirtuosoColumn<BookInSummaryWithOdsCode>[] => {
  const result: VirtuosoColumn<BookInSummaryWithOdsCode>[] = [
    {
      label: '',
      dataKey: 'status',
      widthCss: '36px',
      customCellNode: (value, rowData) => {
        return (
          rowData.status === BookInStatuses.ReEdit && (
            <InfoTooltip
              mode="warning"
              text={translation.ReEdit}
              useColorWhenNotActive={true}
              tooltipSx={{ paddingX: theme.spacing(1) }}
            />
          )
        )
      },
    },
    {
      label: translation.Columns[1],
      dataKey: 'invoiceDate',
      valueTransformer: (rowData) => {
        return getLocaleDateMedWithWeekDay(rowData.invoiceDate)
      },
      sortable: true,
    },
    {
      label: translation.Columns[2],
      dataKey: 'invoiceNumber',
    },
    {
      label: translation.Columns[0],
      dataKey: 'supplierDisplayName',
    },
    {
      label: translation.Columns[3],
      dataKey: 'productsCount',
    },
    {
      label: translation.Columns[4],
      dataKey: 'adjustmentsCount',
    },
    {
      label: translation.Columns[5],
      dataKey: 'totalPrice',
      currency: true,
    },
    {
      label: translation.Columns[7],
      dataKey: 'creationDate',
      valueTransformer: (rowData) =>
        getLocaleDateMedWithWeekDayAndHour(rowData.creationDate),
      sortable: true,
      infoTooltip: translation.InfoDateReceived,
    },
  ]

  return addOdsCodeColumn<BookInSummaryWithOdsCode>(
    selectedClient?.clientType ?? null,
    odsToPharmacyMapping,
    result,
    1
  )
}

const TabDeliveryHistory: FC = () => {
  const { platformHttpService } = useContext(ServiceContext)
  const [openBookIn, setOpenBookIn] = useState<{
    odsCode: string
    bookInId: string
  } | null>(null)
  const { setIsLoading } = useGlobalIsLoading()
  const [selectProductOpen, setSelectProductOpen] = useState<boolean>(false)
  const productOpenButtonPopOver = useRef<ButtonPopOverHandle>(null)

  // Handle page state for this page
  const {
    state: genericPageState,
    dispatch: genericDispatch,
    virtuosoTableState,
    shouldRunLoadingEffect,
    odsToPharmacyNameMappings,
    virtuosoTableHelpers,
    setLoadingAbortedState,
  } = usePageState<BookInSummaryWithOdsCode>(
    pageReducer,
    recoilPageState,
    recoilTableState,
    recoilLoadingAbortedState,
    (item) => item.bookInId,
    {
      dataKey: 'invoiceDate',
      sortingType: 'DESC',
    },
    DatePresetsLocalStorageKeys.DELIVERY_HISTORY_DATE_RANGE_PRESET_KEY,
    {
      selectedProduct: null,
    },
    undefined,
    100
  )

  const state = genericPageState as BookInSummaryWithProductSelectionPageState
  const dispatch =
    genericDispatch as React.Dispatch<BookInSummaryWithProductSelectionPageAction>

  const { abortControllerRef, startAbortableRequest, finishAbortableRequest } =
    useAbortableRequest(setLoadingAbortedState)

  useEffect(() => {
    const getDeliveries = async () => {
      const { sort, sortField } = sortingTypeToDeliverySearchQueryParams(
        state.sorting || null
      )
      return await platformHttpService.postAsync<BookInSummaryWithOdsCode[]>(
        PlatformApiPaths.GetCompletedDeliveries(
          state.selectedClient!,
          `pageIndex=${state.pageIndex || 0}&pageSize=${state.pageSize!}`
        ),
        {
          invoiceDateFrom: getDateOnlyIsoString(state.dates[0]),
          invoiceDateTo: getDateOnlyIsoString(state.dates[1]),
          invoiceNumber: state.searchText,
          supplierIds: state.selectedSupplierIds,
          sort,
          sortField,
          productId: state.selectedProduct?.productId,
          productPackSize: state.selectedProduct?.packSize || null,
          showReEditsOnly: state.showReEditsOnly,
        },
        'StockBaseUrl',
        abortControllerRef.current?.signal
      )
    }
    if (shouldRunLoadingEffect) {
      // every time a new request comes in, cancel the previous one
      startAbortableRequest()
      dispatch({ type: 'setError', payload: null })

      const pageIndex = state.pageIndex
      getDeliveries()
        .then((response) => {
          // Check if the request was cancelled
          if (finishAbortableRequest(response.wasCancelled)) {
            return
          }
          // Process response
          if (response?.data && !response.hasErrors) {
            const hasMorePages = response.data.length >= state.pageSize!
            dispatch({
              type: 'addItems',
              payload: { items: response.data, hasMorePages, pageIndex },
            })
          } else {
            dispatch({
              type: 'setError',
              payload: GetErrorMessage(response?.statusCode),
            })
          }
        })
        .finally(() => {
          dispatch({ type: 'setFinishedLoadingPage' })
        })
    }
  }, [
    dispatch,
    platformHttpService,
    setIsLoading,
    shouldRunLoadingEffect,
    state.dates,
    state.pageIndex,
    state.searchText,
    state.selectedClient,
    state.selectedSupplierIds,
    state.sorting,
    state.pageSize,
    state.selectedProduct?.productId,
    state.selectedProduct?.packSize,
    state.showReEditsOnly,
    abortControllerRef,
    startAbortableRequest,
    finishAbortableRequest,
  ])

  const handleClickedRow = useCallback((item: BookInSummaryWithOdsCode) => {
    setOpenBookIn({
      odsCode: item.odsCode,
      bookInId: item.bookInId,
    })
  }, [])

  const handleMultiDeliveryFormClosed = useCallback(
    async (multiDeliveryFormState: MultiDeliveryFormState) => {
      // for now, we will only have a single book-in delivery triggered from this page
      if (multiDeliveryFormState.deletedBookInIds.length > 0) {
        for (const bookInId of multiDeliveryFormState.deletedBookInIds) {
          dispatch({ type: 'deleteItem', payload: bookInId })
        }
      } else {
        // no request is made if there are no changes
        if (
          !(
            multiDeliveryFormState.hasModifiedHeaders ||
            multiDeliveryFormState.hasModifiedProducts ||
            multiDeliveryFormState.hasModifiedStatus
          )
        ) {
          return
        }
        const bookInId = multiDeliveryFormState.bookIns[0].bookInId
        const odsCode = multiDeliveryFormState.bookIns[0].odsCode
        const response = await platformHttpService.getAsync<BookIn>(
          PlatformApiPaths.GetBookIn(odsCode, bookInId, false),
          'StockBaseUrl'
        )
        if (!response.hasErrors) {
          if (response.data) {
            const summaryFromLoadedBookIn: BookInSummaryWithOdsCode = {
              bookInId: bookInId,
              odsCode: odsCode,
              invoiceDate: response.data.invoiceDate,
              invoiceNumber: response.data.invoiceNumber,
              orderNumber: null,
              status: response.data.status,
              supplierId: response.data.supplierId,
              supplierName: response.data.supplierName,
              supplierDisplayName: response.data.supplierDisplayName,
              totalPrice: response.data.totalPrice,
              bookInHealthscore: response.data.bookInHealthscore,
              bookInHealthscorePenaltyListDict:
                response.data.bookInHealthscorePenaltyListDict,
              bookInHealthscoreReviewedDate:
                response.data.bookInHealthscoreReviewedDate,
              bookInHealthscoreReviewedUserId:
                response.data.bookInHealthscoreReviewedUserId,
              adjustmentsCount:
                response.data.invoicePages
                  ?.flatMap((p) => p.productItems)
                  .filter((i) => i?.adjustment).length ?? 0,
              productsCount:
                response.data.invoicePages?.flatMap((p) => p.productItems)
                  .length ?? 0,
              creationDate: response.data.creationDate,
            }
            dispatch({
              type: 'replaceItems',
              payload: [summaryFromLoadedBookIn],
            })
          }
        }
      }
    },
    [dispatch, platformHttpService]
  )

  const handleSelectedProduct = (
    productId: string,
    packSize: number,
    productName: string,
    unitOfMeasure: string | null,
    subPackDescription: string | null,
    amppId: string | null,
    hasAmpp?: boolean
  ) => {
    dispatch({
      type: 'setSelectedProduct',
      payload: Boolean(productId)
        ? {
            productId,
            packSize,
            productName,
            unitOfMeasure,
            subPackDescription,
            amppId,
            hasAmpp,
          }
        : null,
    })
  }

  return (
    state.selectedClient && (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          gap: theme.spacing(1),
          marginTop: theme.spacing(2),
        }}
      >
        <FilterBar
          state={state}
          dispatch={dispatch}
          searchPlaceholder={
            locale.translation.GoodsInPage.DeliveriesSearch
              .SearchByInvoiceNumber
          }
          datePickerPresetKey={
            DatePresetsLocalStorageKeys.DELIVERY_HISTORY_DATE_RANGE_PRESET_KEY
          }
          searchFieldTestId={'invoice-search-field'}
          additionalSearchElement={
            <ButtonPopOver
              variant={state.selectedProduct ? 'contained' : 'outlined'}
              ref={productOpenButtonPopOver}
              placement="bottom"
              buttonContent={
                <>
                  {state.selectedProduct
                    ? `${state.selectedProduct.productName}
                  ${packSizeConverter(
                    state.selectedProduct.packSize || null,
                    state.selectedProduct.unitOfMeasure,
                    state.selectedProduct.subPackDescription
                  )}`
                    : locale.translation.GoodsInPage.Common.AnyProduct}
                </>
              }
            >
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Button
                  variant="text"
                  onClick={() => {
                    productOpenButtonPopOver.current?.close()
                    setSelectProductOpen(true)
                  }}
                >
                  Select a product
                </Button>
                {state.selectedProduct && (
                  <Button
                    variant="text"
                    onClick={() => {
                      productOpenButtonPopOver.current?.close()
                      dispatch({ type: 'setSelectedProduct', payload: null })
                    }}
                    color="error"
                  >
                    {locale.translation.GoodsInPage.Common.Clear}
                  </Button>
                )}
              </Box>
            </ButtonPopOver>
          }
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <SwitchWithInfo
            checked={state.showReEditsOnly}
            onChange={() => {
              dispatch({
                type: 'setShowReEditsOnly',
                payload: !state.showReEditsOnly,
              })
            }}
            testId="show-re-edits-switch"
            label={translation.ReEditsToggle}
            infoTooltipText={translation.ReEditsToggleTooltip}
          />
        </Box>
        {!state.error && state.items && (
          <VirtuosoMuiTable
            noRowsMessage={translation.NoRecords}
            columns={getTableColumns(
              state.selectedClient,
              odsToPharmacyNameMappings
            )}
            rows={state.items}
            onRowClick={(row) => handleClickedRow(row)}
            onEndReached={virtuosoTableHelpers.handleInfiniteLoading}
            sorting={state.sorting}
            onSortingChanged={virtuosoTableHelpers.handleSortingChanged}
            initialFirstItemIndex={virtuosoTableState.firstItemIndex}
            onRangeChanged={virtuosoTableHelpers.handleRangeChanged}
          />
        )}
        {state.error && (
          <Alert variant="filled" severity="error">
            {state.error}
          </Alert>
        )}
        {openBookIn && (
          <MultiDeliveryForm
            bookInsToLoad={[openBookIn]}
            editDeliveryMode={EditDeliveryModes.DeliveryHistory}
            onClosed={(formState) => {
              setOpenBookIn(null)
              handleMultiDeliveryFormClosed(formState)
            }}
          />
        )}
        {selectProductOpen && (
          <SelectProductModal
            product={{
              packSize: state.selectedProduct?.packSize ?? null,
              productId: state.selectedProduct?.productId ?? '',
              productName: state.selectedProduct?.productName ?? '',
              productRawOcrName: null,
              amppId: state.selectedProduct?.amppId ?? null,
              hasAmpp: state.selectedProduct?.hasAmpp,
            }}
            onClosedCallback={async (selectedProduct) => {
              setSelectProductOpen(false)
              if (selectedProduct) {
                await handleSelectedProduct(
                  selectedProduct.productId,
                  selectedProduct.packSize,
                  selectedProduct.productName,
                  selectedProduct.unitOfMeasure,
                  selectedProduct.subPackDescription,
                  selectedProduct.ampp
                )
              }
            }}
            ignoreSpecials={true}
            showUnlistedProduct={false}
            packSizeOptional={true}
            showAmppList={false}
          />
        )}
      </Box>
    )
  )
}
export default TabDeliveryHistory
