import { FC, useCallback, useReducer, useRef, useState } from 'react'
import { locale } from '../../../locales'
import EditableSection from './EditableSection'
import MultiEmailInput, {
  MultiEmailInputHandle,
} from '../../../components/Forms/MultiEmailInput'
const translations =
  locale.translation.SettingsPage.CompanyManagementTab.CompanyDetailsContainer
    .PricingEmailsSection

const complianceTranslations =
  locale.translation.SettingsPage.GroupsManagementTab.GroupsManagement
    .GroupDetailsContainer

export interface EmailsSettingsState {
  emails: string[]
}

interface EmailSettigsSectionState {
  pricingEmailSettings: EmailsSettingsState
  hasInvalidEmails: boolean
}

type SetEmailsAction = {
  type: 'SET_EMAILS'
  payload: string[]
}
type ResetEmailsAction = {
  type: 'RESET_EMAILS'
  payload: string[]
}

type SetHasInvalidEmailsAction = {
  type: 'SET_HAS_INVALID_EMAILS'
  payload: boolean
}

type Actions = SetEmailsAction | ResetEmailsAction | SetHasInvalidEmailsAction

const reducer = (state: EmailSettigsSectionState, action: Actions) => {
  let nextState: EmailSettigsSectionState = state
  switch (action.type) {
    case 'SET_EMAILS':
      nextState = {
        ...state,
        pricingEmailSettings: {
          ...state.pricingEmailSettings,
          emails: action.payload,
        },
      }
      break
    case 'RESET_EMAILS':
      nextState = {
        ...state,
        pricingEmailSettings: {
          ...state.pricingEmailSettings,
          emails: action.payload,
        },
        hasInvalidEmails: false,
      }
      break
    case 'SET_HAS_INVALID_EMAILS':
      nextState = {
        ...state,
        hasInvalidEmails: action.payload,
      }
      break
  }
  return nextState
}
export const emailSettingsInitialEmptyState = {
  emails: [],
}

const EmailSettingsSection: FC<{
  onChangesApplied: (emailsSettings: EmailsSettingsState) => Promise<boolean>
  initialState: EmailsSettingsState | null
  isComplianceEmailsSection?: boolean
}> = (props) => {
  const [state, dispatch] = useReducer(reducer, {
    pricingEmailSettings: props.initialState ?? emailSettingsInitialEmptyState,
  } as EmailSettigsSectionState)

  const [isEditingEmails, setIsEditingEmails] = useState(false)
  const emailsInputSectionWithTagsHandle = useRef<MultiEmailInputHandle>(null)
  const handleErrorsChanged = (hasError: boolean) => {
    dispatch({ type: 'SET_HAS_INVALID_EMAILS', payload: hasError })
  }

  const handleApply = useCallback(async () => {
    const emailTags = emailsInputSectionWithTagsHandle.current?.getTags() ?? []
    const isSuccess = await props.onChangesApplied({
      emails: emailTags,
    })
    dispatch({ type: 'SET_EMAILS', payload: emailTags })
    if (isSuccess) {
      dispatch({ type: 'SET_EMAILS', payload: emailTags })

      setIsEditingEmails(false)
    }
  }, [props])

  const handleCancel = useCallback(() => {
    dispatch({
      type: 'RESET_EMAILS',
      payload:
        props.initialState?.emails ?? emailSettingsInitialEmptyState.emails,
    })
    emailsInputSectionWithTagsHandle.current?.clearValue()
  }, [props.initialState?.emails])

  return (
    <EditableSection
      sectionTitle={
        props.isComplianceEmailsSection
          ? complianceTranslations.DailyComplianceReportSectionTitle
          : translations.EmailsSection.Title
      }
      onUpdate={handleApply}
      updateDisabled={!isEditingEmails || state.hasInvalidEmails}
      onCancel={handleCancel}
      onEditingChanged={setIsEditingEmails}
      titleSx={{ fontWeight: 600 }}
      isEditing={isEditingEmails}
      disableAutoClose={true}
    >
      <MultiEmailInput
        ref={emailsInputSectionWithTagsHandle}
        emails={state.pricingEmailSettings.emails}
        isDisabled={!isEditingEmails}
        onErrorsChanged={handleErrorsChanged}
        label={translations.EmailsSection.RecipientsEmailsLabel}
      />
    </EditableSection>
  )
}

export default EmailSettingsSection
