import Box from '@mui/material/Box'
import { FC, useCallback, useReducer, useState } from 'react'
import theme from '../../../styles/theme'
import TextField from '@mui/material/TextField'
import { SxProps, Theme } from '@mui/material/styles'
import EditableSection from './EditableSection'
import { locale } from '../../../locales'

const translations =
  locale.translation.SettingsPage.GroupsManagementTab.GroupsManagement
    .GroupDetailsContainer

const editFieldTextFieldStyle: SxProps<Theme> = {
  '& .MuiInputBase-input.Mui-disabled': {
    backgroundColor: theme.palette.grey[50],
    WebkitTextFillColor: theme.palette.grey[800],
  },
}

export interface EditGroupNameSettingsState {
  groupName: string
}

interface EditGroupNameSectionState {
  groupNameSettings: EditGroupNameSettingsState
  nameError: string | null
}

type SetGroupNameAction = {
  type: 'SET_GROUP_NAME'
  payload: string
}

type SetGroupNameErrorAction = {
  type: 'SET_GROUP_NAME_ERROR'
  payload: string | null
}

type ResetGroupNameSettingsSectionAction = {
  type: 'RESET_GROUP_NAME_SETTINGS_SECTION'
  payload: string
}

type Actions =
  | SetGroupNameAction
  | SetGroupNameErrorAction
  | ResetGroupNameSettingsSectionAction

const reducer = (state: EditGroupNameSectionState, action: Actions) => {
  let nextState: EditGroupNameSectionState = state
  switch (action.type) {
    case 'SET_GROUP_NAME':
      nextState = {
        ...state,
        groupNameSettings: {
          ...state.groupNameSettings,
          groupName: action.payload,
        },
      }
      break
    case 'SET_GROUP_NAME_ERROR':
      nextState = {
        ...state,
        nameError: action.payload,
      }
      break
    case 'RESET_GROUP_NAME_SETTINGS_SECTION':
      nextState = {
        ...state,
        groupNameSettings: {
          groupName: action.payload,
        },
        nameError: null,
      }
      break
  }
  return nextState
}

export const groupNameSettingsInitialEmptyState = {
  groupName: '',
}

const EditGroupNameSection: FC<{
  onChangesApplied: (
    pricingEmailsSettings: EditGroupNameSettingsState
  ) => Promise<boolean>
  initialState: EditGroupNameSettingsState | null
  isGroupNameUnique: (groupName: string) => boolean
  autoEdit?: boolean
  onCancel?: () => void
}> = ({ onCancel, ...props }) => {
  const [isEditingGroupName, setIsEditingGroupName] = useState(
    Boolean(props.autoEdit)
  )

  const [state, dispatch] = useReducer(reducer, {
    groupNameSettings: props.initialState ?? groupNameSettingsInitialEmptyState,
    nameError: props.autoEdit ? translations.ErrorGroupName : null,
  } as EditGroupNameSectionState)

  const handleGroupNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newName = e.target.value
    dispatch({ type: 'SET_GROUP_NAME', payload: newName })
    if (newName.length < 4) {
      dispatch({
        type: 'SET_GROUP_NAME_ERROR',
        payload: translations.ErrorGroupName,
      })
    } else if (props.isGroupNameUnique(newName)) {
      dispatch({ type: 'SET_GROUP_NAME_ERROR', payload: null })
    } else {
      dispatch({
        type: 'SET_GROUP_NAME_ERROR',
        payload: translations.ErrorGroupNameNotUnique,
      })
    }
  }

  const handleApply = useCallback(async () => {
    const isSuccess = await props.onChangesApplied(state.groupNameSettings)
    if (isSuccess) {
      setIsEditingGroupName(false)
    }
  }, [props, state.groupNameSettings])

  const handleCancel = useCallback(() => {
    setIsEditingGroupName(false)
    dispatch({
      type: 'RESET_GROUP_NAME_SETTINGS_SECTION',
      payload:
        props.initialState?.groupName ??
        groupNameSettingsInitialEmptyState.groupName,
    })
    onCancel?.()
  }, [onCancel, props.initialState?.groupName])

  return (
    <EditableSection
      sectionTitle={translations.GroupName}
      updateDisabled={Boolean(state.nameError)}
      onUpdate={handleApply}
      onCancel={handleCancel}
      onEditingChanged={setIsEditingGroupName}
      autoEdit={Boolean(props.autoEdit)}
      isEditing={isEditingGroupName}
      disableAutoClose={true}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(2),
          paddingY: theme.spacing(1),
        }}
      >
        <TextField
          sx={{ ...editFieldTextFieldStyle }}
          label={translations.GroupName}
          value={state.groupNameSettings.groupName}
          onChange={handleGroupNameChange}
          disabled={!isEditingGroupName && !Boolean(props.autoEdit)}
          error={Boolean(state.nameError)}
          helperText={state.nameError}
          autoComplete="off"
          variant="outlined"
        />
      </Box>
    </EditableSection>
  )
}
export default EditGroupNameSection
