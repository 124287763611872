import Box from '@mui/material/Box'
import { FC, useCallback, useContext, useRef, useState } from 'react'
import theme from '../../styles/theme'
import { ServiceContext } from '../../providers/ServicesProvider'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { selectedClientState } from '../../state/SelectedPharmacyState'
import Alert from '@mui/material/Alert'
import { locale } from '../../locales'
import {
  Client,
  ClientPharmacy,
  ClientPharmacyGroup,
} from './entities/Entities'
import { PlatformApiPaths } from '../../PlatformApiPaths'
import MoreMenu from '../../components/Interactions/MoreMenu'

import Typography from '@mui/material/Typography'
import VirtualizedSelectableList, {
  VirtualizedSelectableListHandle,
} from '../../components/Data/VirtualizedSelectableList'
import GroupDetailsContainer from './components/GroupDetailsContainer'
import ModalDialog from '../../components/Interactions/ModalDialog'
import { NewGroupId } from '../../constants'
import ConfirmDialog from '../../components/Interactions/ConfirmDialog'
import { GetErrorMessage } from '../../utils/ErrorHandling'
import { authenticatedUserState } from '../../state/AuthenticationState'
import AutoSizingBox from '../../components/Util/AutoSizingBox'
import { useGlobalIsLoading } from '../../hooks/useIsLoading'
import EditGroupNameSection, {
  EditGroupNameSettingsState,
} from './components/EditGroupNameSection'

const translations =
  locale.translation.SettingsPage.GroupsManagementTab.GroupsManagement

const GroupsManagement: FC<{
  clientDetails: Client
  fetchCompanyDetails: () => void
  shouldBustLoadUserCacheRef: React.MutableRefObject<boolean>
}> = ({ clientDetails, fetchCompanyDetails, shouldBustLoadUserCacheRef }) => {
  const { platformHttpService, authenticationService } =
    useContext(ServiceContext)
  const setAuthenticatedUser = useSetRecoilState(authenticatedUserState)
  const selectedClient = useRecoilValue(selectedClientState)
  const { setIsLoading } = useGlobalIsLoading()
  const [error, setError] = useState<string | null>(null)
  const [client, setClient] = useState<Client | null>(clientDetails)
  const [selectedGroup, setSelectedGroup] =
    useState<ClientPharmacyGroup | null>(null)
  const [isAddingGroup, setIsAddingGroup] = useState<boolean>(false)
  const [isRemovingGroup, setIsRemovingGroup] = useState<boolean>(false)
  const listHandleRef = useRef<VirtualizedSelectableListHandle>(null)

  const handleSelectedGroupIndexChanged = async (index: number) => {
    if (index >= 0 && client?.pharmacyGroups) {
      const group = client.pharmacyGroups[index]
      setSelectedGroup(group)
    }
  }

  const handleRefreshUser = useCallback(async () => {
    const refreshResult = await authenticationService.refreshTokenAsync()
    if (refreshResult.user) {
      setAuthenticatedUser({ ...refreshResult.user, bustCache: true })
      shouldBustLoadUserCacheRef.current = true
    }
  }, [authenticationService, setAuthenticatedUser, shouldBustLoadUserCacheRef])

  const validateGroupNameUnique = (groupName: string) => {
    var occurancesFound =
      client?.pharmacyGroups.filter(
        (g) =>
          g.groupName?.toLowerCase().trim() === groupName.toLowerCase().trim()
      ).length || 0

    var currentOriginalGroupName = selectedGroup?.groupName
      ?.toLowerCase()
      .trim()

    if (
      !isAddingGroup &&
      currentOriginalGroupName === groupName.toLowerCase().trim()
    ) {
      return occurancesFound <= 1
    }
    return occurancesFound === 0
  }

  const handleGroupUpdated = useCallback(
    async (
      groupId: string,
      updatedGroupName: string,
      updatedGroupPharmacies: ClientPharmacy[],
      requiresClientsListUpdate: boolean,
      complianceEmailRecipients: string[]
    ): Promise<boolean> => {
      setError(null)
      if (client?.pharmacyGroups && selectedClient) {
        const copyOfGroups = [...client.pharmacyGroups]

        const pharmacyGroupIndex = client.pharmacyGroups.findIndex(
          (g) => g.groupId === groupId
        )

        let newSelectedGroup: ClientPharmacyGroup | null = null
        if (pharmacyGroupIndex >= 0) {
          setIsLoading(true)
          const response = await platformHttpService.putAsync(
            PlatformApiPaths.UpdateDeletePharmacyGroup(selectedClient, groupId),
            {
              groupName: updatedGroupName,
              pharmacyOdsCodes: updatedGroupPharmacies.map((p) => p.odsCode),
              complianceEmailRecipients: complianceEmailRecipients,
            },
            'ClientsBaseUri'
          )
          setIsLoading(false)
          if (response && !response.hasErrors) {
            const copyOfGroup = {
              ...client.pharmacyGroups[pharmacyGroupIndex],
            }
            copyOfGroup.groupName = updatedGroupName
            copyOfGroup.pharmacies = updatedGroupPharmacies
            copyOfGroup.complianceEmailRecipients = complianceEmailRecipients
            copyOfGroups.splice(pharmacyGroupIndex, 1, copyOfGroup)
            newSelectedGroup = copyOfGroup
            if (requiresClientsListUpdate) {
              await handleRefreshUser()
              fetchCompanyDetails()
            }
          } else {
            setError(GetErrorMessage(response.statusCode))
            return false
          }
        } else if (groupId === NewGroupId) {
          setIsLoading(true)
          const response =
            await platformHttpService.postAsync<ClientPharmacyGroup>(
              PlatformApiPaths.CreatePharmacyGroup(selectedClient),
              {
                groupName: updatedGroupName,
              },
              'ClientsBaseUri'
            )
          setIsLoading(false)
          if (response && !response.hasErrors && response.data) {
            const newGroup = response.data
            copyOfGroups.unshift(newGroup)
            newSelectedGroup = newGroup
            fetchCompanyDetails()
            newSelectedGroup.complianceEmailRecipients =
              client.complianceEmailRecipients
          } else {
            setError(GetErrorMessage(response.statusCode))
            return false
          }
        }

        if (newSelectedGroup) {
          setClient({ ...client, pharmacyGroups: copyOfGroups })
          setSelectedGroup(newSelectedGroup)
          listHandleRef.current?.selectIndex(pharmacyGroupIndex)
          shouldBustLoadUserCacheRef.current = true
          return true
        }
      }
      return false
    },
    [
      client,
      fetchCompanyDetails,
      handleRefreshUser,
      platformHttpService,
      selectedClient,
      setIsLoading,
      shouldBustLoadUserCacheRef,
    ]
  )

  const handleRemoveGroup = async (groupId: string) => {
    setIsRemovingGroup(false)
    setError(null)
    if (client?.pharmacyGroups && selectedClient) {
      setIsLoading(true)
      const response = await platformHttpService.deleteAsync(
        PlatformApiPaths.UpdateDeletePharmacyGroup(selectedClient, groupId),
        null,
        'ClientsBaseUri'
      )
      setIsLoading(false)
      if (response && !response.hasErrors) {
        setSelectedGroup(null)
        setClient({
          ...client,
          pharmacyGroups: client.pharmacyGroups.filter(
            (g) => g.groupId !== groupId
          ),
        })
        await handleRefreshUser()
        shouldBustLoadUserCacheRef.current = true
        fetchCompanyDetails()
      } else {
        setError(GetErrorMessage(response.statusCode))
      }
    }
  }

  const handleAddGroup = useCallback(
    async (gropNameSettings: EditGroupNameSettingsState): Promise<boolean> => {
      handleGroupUpdated(NewGroupId, gropNameSettings.groupName, [], false, [])

      setIsAddingGroup(false)
      return true
    },
    [handleGroupUpdated]
  )

  return (
    <>
      {error && (
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      )}
      {client && client.pharmacyGroups && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            height: '100%',
          }}
        >
          <Box
            data-testid="groups-list-pane"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              borderRight: `1px solid ${theme.palette.grey[300]}`,
            }}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                height: theme.spacing(4),
              }}
            >
              <MoreMenu
                items={[
                  {
                    label: translations.MoreMenu.AddGroup,
                    onClicked: () => {
                      setIsAddingGroup(true)
                    },
                  },
                ]}
              />
            </Box>
            <VirtualizedSelectableList
              ref={listHandleRef}
              rowHeight={parseInt(theme.spacing(9))}
              rowCount={client.pharmacyGroups.length}
              height={'100%'}
              width={'400px'}
              items={client.pharmacyGroups}
              buttonContent={(g) => {
                const group = g as ClientPharmacyGroup
                return (
                  <>
                    <Typography variant="body1">{group.groupName}</Typography>
                    <Typography variant="body2">{`${group.pharmacies.length} ${
                      group.pharmacies.length === 1
                        ? translations.Pharmacy
                        : translations.Pharmacies
                    }`}</Typography>
                  </>
                )
              }}
              onSelectedItemIndexChanged={handleSelectedGroupIndexChanged}
              selectedIndex={
                client?.pharmacyGroups
                  ? client.pharmacyGroups.findIndex(
                      (u) => u.groupId === selectedGroup?.groupId
                    )
                  : undefined
              }
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              paddingLeft: theme.spacing(1),
            }}
            data-testid="groups-details-pane"
          >
            <Box>
              <Box
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  height: theme.spacing(4),
                }}
              >
                {selectedGroup && (
                  <MoreMenu
                    items={[
                      {
                        label: translations.MoreMenu.RemoveGroup,
                        onClicked: () => {
                          setIsRemovingGroup(true)
                        },
                      },
                    ]}
                  />
                )}
              </Box>
            </Box>
            <AutoSizingBox>
              {selectedGroup?.groupName && selectedGroup?.groupId && (
                <GroupDetailsContainer
                  key={selectedGroup.groupId}
                  groupId={selectedGroup.groupId}
                  groupName={selectedGroup.groupName}
                  clientPharmacies={client.pharmacies}
                  groupPharmacies={selectedGroup.pharmacies}
                  complianceEmailRecipients={
                    selectedGroup.complianceEmailRecipients
                  }
                  onGroupUpdated={handleGroupUpdated}
                  isGroupNameUnique={validateGroupNameUnique}
                />
              )}
            </AutoSizingBox>
          </Box>
        </Box>
      )}
      {isAddingGroup && (
        <ModalDialog
          onClosed={() => setIsAddingGroup(false)}
          title={translations.MoreMenu.AddTitle}
        >
          <EditGroupNameSection
            onChangesApplied={handleAddGroup}
            initialState={null}
            isGroupNameUnique={validateGroupNameUnique}
            key={Math.random()}
            autoEdit={true}
            onCancel={() => setIsAddingGroup(false)}
          />
        </ModalDialog>
      )}
      {isRemovingGroup && selectedGroup?.groupId && (
        <ConfirmDialog
          isCancelPrimary={true}
          cancelText={translations.RemoveGroupDialog.Cancel}
          title={translations.RemoveGroupDialog.Title}
          okText={translations.RemoveGroupDialog.Yes}
          text={translations.RemoveGroupDialog.Text(
            selectedGroup!.groupName,
            selectedClient!.name
          )}
          onCancel={() => setIsRemovingGroup(false)}
          onOk={() => handleRemoveGroup(selectedGroup.groupId!)}
        />
      )}
    </>
  )
}
export default GroupsManagement
