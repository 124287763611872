import { StockTrackingHelpers } from '../helpers'

type StockBatchLevelItemResponse = {
  currentStockUnits: number
  batchNumber?: string
  expiryDate?: string
  hasBeenReset?: boolean
}

type StockDistributorLevelItemResponse = {
  amppId?: string
  distributor?: string
  currentStockUnits?: number
  hasBeenReset?: boolean
  i?: StockBatchLevelItemResponse[]
}

type StockPackLevelItemResponse = {
  packSize?: number
  vmppId?: string
  subPackDescription?: string
  i: StockDistributorLevelItemResponse[]
}

export type StockProductLevelItemResponse = {
  productId: string
  vmpId: string
  ampId?: string
  updatedTimestamp: string
  productName: string
  unitOfMeasure?: string
  brandedOrGeneric?: string
  legalCategory?: string
  isControlledDrug?: boolean
  i: StockPackLevelItemResponse[]
}

export type StockTrackingListResponse = {
  products: StockProductLevelItemResponse[]
  includesBatchNumbers: boolean
}

export type StockTrackingItemResponse = {
  product: StockProductLevelItemResponse
  includesBatchNumbers: boolean
}

export type StockProductRow = {
  productId: string
  productName: string
  currentStockUnits: number
  unitOfMeasure: string
}

export enum StockTrackingResetState {
  'RESET',
  'NOT_RESET',
  'MIXED',
}

export enum StockTrackingNegativeStockState {
  'POSITIVE',
  'NEGATIVE',
  'MIXED',
}

type StockProductItemFlags = {
  resetState?: StockTrackingResetState
  negativeStockState?: StockTrackingNegativeStockState
}

type StockProductItemBase = {
  productId: string
  vmpId: string
  ampId?: string
  updatedTimestamp: string
  productName: string
  unitOfMeasure?: string
  subPackDescription?: string
  brandedOrGeneric?: string
  legalCategory?: string
  isControlledDrug?: boolean
  packSize?: number
  vmppId?: string
  amppId?: string
  distributor?: string
  currentStockUnits?: number
  batchNumber?: string
  expiryDate?: string
  packStockUnits?: number
  packSplitStockUnits?: number
  isConcessionary?: boolean

  flags?: StockProductItemFlags
}

export type StockProductNestedItem = StockProductItemBase & {
  children: StockProductNestedItem[]
  getRootId: () => string
}

export const stockProductNestedItemFromStockProductLevelResponse = (
  responseProduct: StockProductLevelItemResponse,
  includesBatchNumbers: boolean
): StockProductNestedItem => {
  const calculateStockUnits = (root: StockProductNestedItem): number => {
    let result = 0
    if (root.currentStockUnits != null) {
      return root.currentStockUnits
    } else if (root.children && root.children.length > 0) {
      for (const child of root.children) {
        result += calculateStockUnits(child)
      }
    }
    return result
  }

  const fillStockUnits = (
    item: StockProductNestedItem
  ): StockProductNestedItem => {
    const stockUnits = calculateStockUnits(item)
    const { packUnits, splitUnits } =
      StockTrackingHelpers.packsAndSplitUnitsFromUnitsAndPackSize(
        stockUnits,
        item.packSize
      )
    return {
      ...item,
      currentStockUnits: stockUnits,
      packStockUnits: packUnits,
      packSplitStockUnits: splitUnits,
    }
  }

  const fillFlags = (root: StockProductNestedItem): StockProductNestedItem => {
    const calculateFlags = (
      root: StockProductNestedItem
    ): StockProductItemFlags => {
      if (root.children.length === 0) {
        return {
          resetState: root.flags?.resetState,
          negativeStockState: root.flags?.negativeStockState,
        }
      }

      const flags = root.children.map(calculateFlags)
      return {
        resetState: flags.every(
          (f) => f.resetState === StockTrackingResetState.RESET
        )
          ? StockTrackingResetState.RESET
          : flags.every(
              (f) => f.resetState === StockTrackingResetState.NOT_RESET
            )
          ? StockTrackingResetState.NOT_RESET
          : StockTrackingResetState.MIXED,
        negativeStockState: flags.every(
          (f) =>
            f.negativeStockState === StockTrackingNegativeStockState.NEGATIVE
        )
          ? StockTrackingNegativeStockState.NEGATIVE
          : flags.every(
              (f) =>
                f.negativeStockState ===
                StockTrackingNegativeStockState.POSITIVE
            )
          ? StockTrackingNegativeStockState.POSITIVE
          : StockTrackingNegativeStockState.MIXED,
      }
    }
    for (const child of root.children) {
      fillFlags(child)
      child.flags = calculateFlags(child)
    }
    return { ...root, flags: calculateFlags(root) }
  }

  const product: StockProductNestedItem = {
    productId: responseProduct.productId,
    vmpId: responseProduct.vmpId,
    ampId: responseProduct.ampId,
    updatedTimestamp: responseProduct.updatedTimestamp,
    productName: responseProduct.productName,
    isControlledDrug: responseProduct.isControlledDrug,
    unitOfMeasure: responseProduct.unitOfMeasure,
    brandedOrGeneric: responseProduct.brandedOrGeneric,
    legalCategory: responseProduct.legalCategory,
    children: [],
    getRootId: () => responseProduct.productId,
  }

  for (const rPackSize of responseProduct.i) {
    const productPackSize: StockProductNestedItem = {
      ...product,
      packSize: rPackSize.packSize,
      vmppId: rPackSize.vmppId,
      subPackDescription: rPackSize.subPackDescription,
      children: [],
    }
    for (const rAmpp of rPackSize.i) {
      const productAmpp: StockProductNestedItem = {
        ...productPackSize,
        amppId: rAmpp.amppId,
        distributor: rAmpp.distributor,
        currentStockUnits: !includesBatchNumbers
          ? rAmpp.currentStockUnits
          : undefined,
        flags: !includesBatchNumbers
          ? {
              resetState: rAmpp.hasBeenReset
                ? StockTrackingResetState.RESET
                : StockTrackingResetState.NOT_RESET,
              negativeStockState:
                rAmpp.currentStockUnits! < 0
                  ? StockTrackingNegativeStockState.NEGATIVE
                  : StockTrackingNegativeStockState.POSITIVE,
            }
          : undefined,
        children: [],
      }

      if (includesBatchNumbers) {
        for (const rBatch of rAmpp.i!) {
          const productBatch: StockProductNestedItem = {
            ...productAmpp,
            batchNumber: rBatch.batchNumber,
            expiryDate: rBatch.expiryDate,
            currentStockUnits: rBatch.currentStockUnits,
            flags: {
              resetState: rBatch.hasBeenReset
                ? StockTrackingResetState.RESET
                : StockTrackingResetState.NOT_RESET,
              negativeStockState:
                rBatch.currentStockUnits < 0
                  ? StockTrackingNegativeStockState.NEGATIVE
                  : StockTrackingNegativeStockState.POSITIVE,
            },
            children: [],
          }
          productAmpp.children.push(fillStockUnits(productBatch))
        }
      }
      productPackSize.children.push(fillStockUnits(productAmpp))
    }
    product.children.push(fillStockUnits(productPackSize))
  }

  const productAggregatedStockUnits = product.children.reduce(
    (p, c) => {
      p.packSplitStockUnits += c.packSplitStockUnits ?? 0
      p.packStockUnits += c.packStockUnits ?? 0
      return p
    },
    {
      packSplitStockUnits: 0,
      packStockUnits: 0,
    }
  )

  return fillFlags({
    ...product,
    currentStockUnits: calculateStockUnits(product),
    ...productAggregatedStockUnits,
  })
}
