import { FC, useCallback, useContext, useEffect, useState } from 'react'
import { ServiceContext } from '../../providers/ServicesProvider'
import { useRecoilState } from 'recoil'
import { BookInSummaryWithOdsCode } from './entities/BookInSummary'
import { locale } from '../../locales'
import Box from '@mui/material/Box'
import theme from '../../styles/theme'
import Alert from '@mui/material/Alert'
import {
  addOdsCodeColumn,
  getLocaleDateMedWithWeekDay,
  getLocaleDateMedWithWeekDayAndHour,
  getDateOnlyIsoString,
} from '../../utils/Helpers'
import { BookIn } from './entities/BookIn'
import { PlatformApiPaths } from '../../PlatformApiPaths'
import { ClientSelection } from '../../types/entities/ClientPermission'
import { GetErrorMessage } from '../../utils/ErrorHandling'
import { SxProps, Theme } from '@mui/material/styles'
import {
  BookInStatuses,
  DatePresetsLocalStorageKeys,
  EditDeliveryModes,
  RecoilPageIds,
  WebSocketActions,
} from '../../constants'
import { webSocketLastMessageState } from '../../state/WebSocketState'
import VirtuosoMuiTable, {
  VirtuosoColumn,
} from '../../components/Data/VirtuosoMuiTable'
import { sortingTypeToDeliverySearchQueryParams } from './helpers'
import InfoTooltip from '../../components/Interactions/InfoTooltip'
import {
  PageAction,
  PageReducer,
  PageState,
  ReducerHelpers,
  createRecoilPageState,
  usePageState,
} from '../PageState'
import FilterBar from '../../components/Interactions/FilterBar'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { useGlobalIsLoading } from '../../hooks/useIsLoading'
import MultiDeliveryForm from './components/MultiDeliveryForm'
import { useAbortableRequest } from '../../hooks/useAbortableRequest'

type BookInUpdateddWebSocketMessage = {
  action?: string
  odsCode?: string
  bookInId?: string
}

const translation = locale.translation.GoodsInPage.TabBookInHealthscore

// Page-specific action types
type SetShowReviewedInvoicesAction = {
  type: 'setShowReviewedInvoices'
  payload: boolean
}
type SetShowPerfectScoreInvoicesAction = {
  type: 'setShowPerfectScoreInvoices'
  payload: boolean
}
type BookInHealthScorePageAction =
  | PageAction<BookInSummaryWithOdsCode>
  | SetShowReviewedInvoicesAction
  | SetShowPerfectScoreInvoicesAction

// Page-specific state
interface BookInHealthScorePageState
  extends PageState<BookInSummaryWithOdsCode> {
  showPerfectScoreInvoices: boolean
  showReviewedInvoices: boolean
}

// Page specific reducer
class BookInHealthScorePageReducer extends PageReducer<BookInSummaryWithOdsCode> {
  reducer(
    state: BookInHealthScorePageState,
    action: BookInHealthScorePageAction
  ): BookInHealthScorePageState {
    state = {
      ...(super.reducer(
        state,
        action as PageAction<BookInSummaryWithOdsCode>
      ) as BookInHealthScorePageState),
    }
    switch (action.type) {
      case 'setShowReviewedInvoices':
        state = {
          ...(ReducerHelpers.resetPaging(
            state as PageState<BookInSummaryWithOdsCode>
          ) as BookInHealthScorePageState),
          showReviewedInvoices: action.payload,
        }

        break
      case 'setShowPerfectScoreInvoices':
        state = {
          ...(ReducerHelpers.resetPaging(
            state as PageState<BookInSummaryWithOdsCode>
          ) as BookInHealthScorePageState),
          showPerfectScoreInvoices: action.payload,
        }
        ReducerHelpers.resetPaging(state as PageState<BookInSummaryWithOdsCode>)
        break
    }

    return state
  }
}

// Create static objects for the recoil state and reducer
const { recoilPageState, recoilTableState, recoilLoadingAbortedState } =
  createRecoilPageState<BookInSummaryWithOdsCode>(
    RecoilPageIds.TabBookInHealthscore
  )
const pageReducer = new BookInHealthScorePageReducer()

const getTableColumns = (
  selectedClient: ClientSelection | null,
  odsToPharmacyMapping: { [key: string]: string } | null
): VirtuosoColumn<BookInSummaryWithOdsCode>[] => {
  const result: VirtuosoColumn<BookInSummaryWithOdsCode>[] = [
    {
      label: '',
      dataKey: 'status',
      widthCss: '36px',
      customCellNode: (value, rowData) => {
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: theme.palette.common.white,
              width: '100%',
              height: '100%',
            }}
          >
            {rowData.status === BookInStatuses.ReEdit && (
              <InfoTooltip
                mode="warning"
                text={translation.ReEdit}
                useColorWhenNotActive={true}
                tooltipSx={{ paddingX: theme.spacing(2) }}
              />
            )}
          </Box>
        )
      },
      calculatedCellSx: () => {
        return {
          padding: 0,
        }
      },
    },
    {
      label: translation.Columns[1],
      dataKey: 'invoiceDate',
      valueTransformer: (rowData) => {
        return getLocaleDateMedWithWeekDay(rowData.invoiceDate)
      },
      sortable: true,
    },
    {
      label: translation.Columns[2],
      dataKey: 'invoiceNumber',
    },
    {
      label: translation.Columns[0],
      dataKey: 'supplierDisplayName',
    },
    {
      label: translation.Columns[3],
      dataKey: 'productsCount',
    },
    {
      label: translation.Columns[4],
      dataKey: 'adjustmentsCount',
    },
    {
      label: translation.Columns[5],
      dataKey: 'totalPrice',
      currency: true,
    },
    {
      label: translation.Columns[9],
      dataKey: 'creationDate',
      valueTransformer: (rowData) =>
        getLocaleDateMedWithWeekDayAndHour(rowData.creationDate),
      sortable: true,
      infoTooltip: translation.InfoDateReceived,
    },
    {
      label: translation.Columns[6],
      dataKey: 'bookInHealthscore',
      sortable: true,
    },
    {
      label: translation.Columns[7],
      dataKey: 'bookInHealthscoreReviewedDate',
      valueTransformer: (rowData) => {
        return getLocaleDateMedWithWeekDayAndHour(
          rowData.bookInHealthscoreReviewedDate
        )
      },
      sortable: true,
    },
  ]

  return addOdsCodeColumn<BookInSummaryWithOdsCode>(
    selectedClient?.clientType ?? null,
    odsToPharmacyMapping,
    result,
    1
  )
}

const HighLightedRowStyle: SxProps<Theme> = {
  backgroundColor: theme.palette.warning.main,
  '&:hover': {
    backgroundColor: theme.palette.warning.light,
  },
  color: theme.palette.grey[100],
}

const TabBookInHealthscore: FC = () => {
  const { platformHttpService } = useContext(ServiceContext)
  const [openBookIn, setOpenBookIn] = useState<{
    odsCode: string
    bookInId: string
  } | null>(null)

  const [skipWebSocket, setSkipWebSocket] = useState<boolean>(true)
  const [webSocketLastMessage, setWebSocketLastMessage] = useRecoilState(
    webSocketLastMessageState
  )
  const { setIsLoading } = useGlobalIsLoading()

  // Handle page state for this page
  const {
    state: genericPageState,
    dispatch: genericDispatch,
    virtuosoTableState,
    shouldRunLoadingEffect,
    odsToPharmacyNameMappings,
    virtuosoTableHelpers,
    setLoadingAbortedState,
  } = usePageState<BookInSummaryWithOdsCode>(
    pageReducer,
    recoilPageState,
    recoilTableState,
    recoilLoadingAbortedState,
    (item) => item.bookInId,
    {
      dataKey: 'bookInHealthscore',
      sortingType: 'ASC',
    },
    DatePresetsLocalStorageKeys.HEALTHSCORE_DATE_RANGE_PRESET_KEY,
    { showPerfectScoreInvoices: false, showReviewedInvoices: false },
    undefined,
    50
  )

  // Type conversion to page specific state
  const state = genericPageState as BookInHealthScorePageState
  const dispatch =
    genericDispatch as React.Dispatch<BookInHealthScorePageAction>

  const { abortControllerRef, startAbortableRequest, finishAbortableRequest } =
    useAbortableRequest(setLoadingAbortedState)

  useEffect(() => {
    const getCompletedDeliveries = async () => {
      const { sort, sortField } = sortingTypeToDeliverySearchQueryParams(
        state.sorting || null
      )
      return await platformHttpService.postAsync<BookInSummaryWithOdsCode[]>(
        PlatformApiPaths.GetCompletedDeliveriesWithBookInHealthscores(
          state.selectedClient!,
          `pageIndex=${state.pageIndex || 0}&pageSize=${state.pageSize!}`
        ),
        {
          invoiceDateFrom: getDateOnlyIsoString(state.dates[0]),
          invoiceDateTo: getDateOnlyIsoString(state.dates[1]),
          invoiceNumber: state.searchText,
          supplierIds: state.selectedSupplierIds,
          sort,
          sortField,
          showPerfectBookIns: state.showPerfectScoreInvoices,
          showReviewedBookIns: state.showReviewedInvoices,
        },
        'StockBaseUrl',
        abortControllerRef.current?.signal
      )
    }
    if (shouldRunLoadingEffect) {
      // every time a new request comes in, cancel the previous one
      startAbortableRequest()
      dispatch({ type: 'setError', payload: null })

      const pageIndex = state.pageIndex
      getCompletedDeliveries().then((response) => {
        // Check if the request was cancelled
        if (finishAbortableRequest(response.wasCancelled)) {
          return
        }

        // Clean up any websocket messages that might exist
        if (
          webSocketLastMessage?.action ===
          WebSocketActions.BookInHealthscoreBookInUpdated
        ) {
          setWebSocketLastMessage(null)
        }
        setSkipWebSocket(false)

        // Process response
        if (response?.data && !response.hasErrors) {
          const hasMorePages = response.data.length >= state.pageSize!
          dispatch({
            type: 'addItems',
            payload: { items: response.data, hasMorePages, pageIndex },
          })
        } else {
          dispatch({
            type: 'setError',
            payload: GetErrorMessage(response?.statusCode),
          })
        }
      })
      .finally(() => {
        dispatch({ type: 'setFinishedLoadingPage' })
      })
    }
  }, [
    dispatch,
    platformHttpService,
    setIsLoading,
    setWebSocketLastMessage,
    shouldRunLoadingEffect,
    state.dates,
    state.pageIndex,
    state.searchText,
    state.selectedClient,
    state.selectedSupplierIds,
    state.showPerfectScoreInvoices,
    state.showReviewedInvoices,
    state.sorting,
    webSocketLastMessage?.action,
    state.pageSize,
    abortControllerRef,
    startAbortableRequest,
    finishAbortableRequest,
  ])

  const handleClickedRow = useCallback((item: BookInSummaryWithOdsCode) => {
    setOpenBookIn({
      odsCode: item.odsCode,
      bookInId: item.bookInId,
    })
  }, [])

  const onModalClosedCallback = useCallback(
    async (
      bookInId: string | null,
      odsCode: string | null,
      wasDeleted: boolean,
      waitForUpdateFromWebsocket: boolean
    ) => {
      // setOpenBookInId(null)
      // setOpenBookInOdsCode(null)

      setIsLoading(waitForUpdateFromWebsocket)

      // When updateFromWebSocket is true, the update will happen through the WS useEffect
      if (waitForUpdateFromWebsocket) {
        setSkipWebSocket(false)
        return
      }
      if (bookInId && odsCode) {
        if (!wasDeleted) {
          setIsLoading(true)
          const response = await platformHttpService.getAsync<BookIn>(
            PlatformApiPaths.GetBookIn(odsCode, bookInId, false),
            'StockBaseUrl'
          )
          setIsLoading(false)
          if (!response.hasErrors) {
            if (response.data) {
              const summaryFromLoadedBookIn: BookInSummaryWithOdsCode = {
                bookInId: bookInId,
                odsCode: odsCode,
                invoiceDate: response.data.invoiceDate,
                invoiceNumber: response.data.invoiceNumber,
                orderNumber: null,
                status: response.data.status,
                supplierId: response.data.supplierId,
                supplierName: response.data.supplierName,
                supplierDisplayName: response.data.supplierDisplayName,
                totalPrice: response.data.totalPrice,
                bookInHealthscore: response.data.bookInHealthscore,
                bookInHealthscorePenaltyListDict:
                  response.data.bookInHealthscorePenaltyListDict,
                bookInHealthscoreReviewedDate:
                  response.data.bookInHealthscoreReviewedDate,
                bookInHealthscoreReviewedUserId:
                  response.data.bookInHealthscoreReviewedUserId,
                adjustmentsCount:
                  response.data.invoicePages
                    ?.flatMap((p) => p.productItems)
                    .filter((i) => i?.adjustment).length ?? 0,
                productsCount:
                  response.data.invoicePages?.flatMap((p) => p.productItems)
                    .length ?? 0,
                creationDate: response.data.creationDate,
              }
              dispatch({
                type: 'replaceItems',
                payload: [summaryFromLoadedBookIn],
              })
            }
          }
        } else {
          dispatch({
            type: 'deleteItem',
            payload: bookInId,
          })
        }
      }
    },
    [dispatch, platformHttpService, setIsLoading]
  )

  useEffect(() => {
    if (
      !skipWebSocket &&
      webSocketLastMessage?.action ===
        WebSocketActions.BookInHealthscoreBookInUpdated
    ) {
      const { odsCode, bookInId } =
        webSocketLastMessage as BookInUpdateddWebSocketMessage
      if (odsCode && bookInId) {
        setWebSocketLastMessage(null)
        onModalClosedCallback(bookInId, odsCode, false, false)
      }
    }
  }, [
    onModalClosedCallback,
    setWebSocketLastMessage,
    skipWebSocket,
    webSocketLastMessage,
  ])

  return (
    state.selectedClient && (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          gap: theme.spacing(1),
          marginTop: theme.spacing(2),
        }}
      >
        <FilterBar
          state={state}
          dispatch={dispatch}
          searchPlaceholder={
            locale.translation.GoodsInPage.DeliveriesSearch
              .SearchByInvoiceNumber
          }
          datePickerPresetKey={
            DatePresetsLocalStorageKeys.HEALTHSCORE_DATE_RANGE_PRESET_KEY
          }
          searchFieldTestId={'invoice-search-field'}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={state.showReviewedInvoices}
                onChange={() => {
                  dispatch({
                    type: 'setShowReviewedInvoices',
                    payload: !state.showReviewedInvoices,
                  })
                }}
                data-testid="show-reviewed-bookins"
              />
            }
            label={translation.ShowReviewedInvoicesToggle}
          />
          <FormControlLabel
            control={
              <Switch
                checked={state.showPerfectScoreInvoices}
                onChange={() => {
                  dispatch({
                    type: 'setShowPerfectScoreInvoices',
                    payload: !state.showPerfectScoreInvoices,
                  })
                }}
                data-testid="show-perfect-bookins-toggle"
              />
            }
            label={translation.ShowPerfectScoreInvoicesToggle}
          />
        </Box>
        {!state.error && state.items && (
          <VirtuosoMuiTable
            noRowsMessage={translation.NoRecords}
            columns={getTableColumns(
              state.selectedClient,
              odsToPharmacyNameMappings
            )}
            rows={state.items}
            onRowClick={(row) => handleClickedRow(row)}
            onEndReached={virtuosoTableHelpers.handleInfiniteLoading}
            sorting={state.sorting}
            onSortingChanged={virtuosoTableHelpers.handleSortingChanged}
            initialFirstItemIndex={virtuosoTableState.firstItemIndex}
            onRangeChanged={virtuosoTableHelpers.handleRangeChanged}
            calculateRowSx={(row) =>
              row.bookInHealthscore !== 100 ? HighLightedRowStyle : {}
            }
          />
        )}
        {state.error && (
          <Alert variant="filled" severity="error">
            {state.error}
          </Alert>
        )}
        {openBookIn && (
          <MultiDeliveryForm
            bookInsToLoad={[openBookIn]}
            editDeliveryMode={EditDeliveryModes.HealthScore}
            onClosed={(formState) => {
              setOpenBookIn(null)
              // handleMultiDeliveryFormClosed(formState)
            }}
          />
        )}
      </Box>
    )
  )
}
export default TabBookInHealthscore
