import { FC, useCallback, useReducer, useRef, useState } from 'react'
import TextField from '@mui/material/TextField/TextField'
import { locale } from '../../../locales'
import theme from '../../../styles/theme'
import EditableSection from './EditableSection'
import MultiEmailInput, {
  MultiEmailInputHandle,
} from '../../../components/Forms/MultiEmailInput'
const translations =
  locale.translation.SettingsPage.PharmacySettingsTab.PharmacyDetailsContainer
    .CompolianceEmailsSection

export interface ComplianceEmailSettingsState {
  emails: string[]
  phoneNumber: string
}

interface ComplianceEmailSettigsSectionState {
  complianceEmailSettings: ComplianceEmailSettingsState
  hasInvalidEmails: boolean
}

type SetEmailsAction = {
  type: 'SET_EMAILS'
  payload: string[]
}
type ResetDailyComplianceSectionAction = {
  type: 'RESET_DAILY_COMPLIANCE_SECTION'
  payload: {
    emails: string[]
    phoneNumber: string
  }
}
type SetPhoneNumberAction = {
  type: 'SET_PHONE_NUMBER'
  payload: string
}
type SetHasInvalidEmailsAction = {
  type: 'SET_HAS_INVALID_EMAILS'
  payload: boolean
}

type Actions =
  | SetEmailsAction
  | ResetDailyComplianceSectionAction
  | SetPhoneNumberAction
  | SetHasInvalidEmailsAction

const reducer = (
  state: ComplianceEmailSettigsSectionState,
  action: Actions
) => {
  let nextState: ComplianceEmailSettigsSectionState = state
  switch (action.type) {
    case 'SET_EMAILS':
      nextState = {
        ...state,
        complianceEmailSettings: {
          ...state.complianceEmailSettings,
          emails: action.payload,
        },
      }
      break
    case 'RESET_DAILY_COMPLIANCE_SECTION':
      nextState = {
        ...state,
        complianceEmailSettings: {
          emails: action.payload.emails,
          phoneNumber: action.payload.phoneNumber,
        },
        hasInvalidEmails: false,
      }
      break
    case 'SET_PHONE_NUMBER':
      nextState = {
        ...state,
        complianceEmailSettings: {
          ...state.complianceEmailSettings,
          phoneNumber: action.payload,
        },
      }
      break
    case 'SET_HAS_INVALID_EMAILS':
      nextState = {
        ...state,
        hasInvalidEmails: action.payload,
      }
      break
  }
  return nextState
}

export const complianceEmailSettingsInitialEmptyState = {
  emails: [],
  phoneNumber: '',
}

const ComplianceEmailSettingsSection: FC<{
  onChangesApplied: (state: ComplianceEmailSettingsState) => Promise<boolean>
  initialState: ComplianceEmailSettingsState | null
}> = (props) => {
  const [state, dispatch] = useReducer(reducer, {
    complianceEmailSettings:
      props.initialState ?? complianceEmailSettingsInitialEmptyState,
  } as ComplianceEmailSettigsSectionState)

  const [isEditingComplianceEmails, setIsEditingComplianceEmails] =
    useState(false)
  const emailsInputSectionWithTagsHandle = useRef<MultiEmailInputHandle>(null)
  const handleErrorsChanged = (hasError: boolean) => {
    dispatch({ type: 'SET_HAS_INVALID_EMAILS', payload: hasError })
  }

  const handleApply = useCallback(async () => {
    const emailTags = emailsInputSectionWithTagsHandle.current?.getTags() ?? []
    const isSuccess = await props.onChangesApplied({
      emails: emailTags,
      phoneNumber: state.complianceEmailSettings.phoneNumber,
    })
    if (isSuccess) {
      dispatch({ type: 'SET_EMAILS', payload: emailTags })
      setIsEditingComplianceEmails(false)
    }
  }, [props, state.complianceEmailSettings.phoneNumber])

  const handleCancel = useCallback(() => {
    dispatch({
      type: 'RESET_DAILY_COMPLIANCE_SECTION',
      payload: {
        emails:
          props.initialState?.emails ??
          complianceEmailSettingsInitialEmptyState.emails,
        phoneNumber:
          props.initialState?.phoneNumber ??
          complianceEmailSettingsInitialEmptyState.phoneNumber,
      },
    })
    emailsInputSectionWithTagsHandle.current?.clearValue()
  }, [props.initialState?.emails, props.initialState?.phoneNumber])

  return (
    <EditableSection
      sectionTitle={translations.DailyComplianceReportSectionTitel}
      onUpdate={handleApply}
      updateDisabled={!isEditingComplianceEmails || state.hasInvalidEmails}
      onCancel={handleCancel}
      onEditingChanged={setIsEditingComplianceEmails}
      titleSx={{ fontWeight: 600 }}
      isEditing={isEditingComplianceEmails}
      disableAutoClose={true}
    >
      <MultiEmailInput
        ref={emailsInputSectionWithTagsHandle}
        emails={state.complianceEmailSettings.emails}
        isDisabled={!isEditingComplianceEmails}
        onErrorsChanged={handleErrorsChanged}
        label={translations.RecipientsEmailsLabel}
      />
      <TextField
        label={translations.PhoneNumberLabel}
        value={state.complianceEmailSettings.phoneNumber}
        variant="outlined"
        name="phoneNumber"
        fullWidth
        onChange={(e) => {
          dispatch({ type: 'SET_PHONE_NUMBER', payload: e.target.value })
        }}
        inputProps={{ inputMode: 'numeric' }}
        disabled={!isEditingComplianceEmails}
        sx={{ marginBottom: theme.spacing(2), marginTop: theme.spacing(1) }}
      />
    </EditableSection>
  )
}

export default ComplianceEmailSettingsSection
