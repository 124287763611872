import { FC, PropsWithChildren, useCallback } from 'react'
import VirtualizedTreeView, {
  VirtualizedTreeViewDataItem,
  VirtualizedTreeViewState,
} from './VirtualizedTreeView'
import { SxProps, Theme } from '@mui/material/styles'
import Box from '@mui/material/Box'

export interface VirtualizedTableTreeViewProps {
  gridTemplateColumns: string
  data: VirtualizedTreeViewDataItem[]
  onStateUpdated: (state: VirtualizedTreeViewState) => void
  maxNestingLevel: number
  levelDistanceChUnit: number
  renderItemRow: (
    item: VirtualizedTreeViewDataItem,
    level: number
  ) => JSX.Element
  renderHeaderRow: () => JSX.Element
  headerSx?: SxProps<Theme>
  rowSx?: SxProps<Theme>
  initialState?: VirtualizedTreeViewState
  onRangeChanged?: (firstItemIndex: number) => void
  onEndReached?: (index: number) => void
  initialFirstItemIndex?: number
}

interface TableTreeViewCellProps extends PropsWithChildren<{}> {
  isLastColumn?: boolean
  sx?: SxProps<Theme>
}
export const TableTreeViewCell: FC<TableTreeViewCellProps> = (props) => {
  return <Box sx={{ ...props.sx }}>{props.children}</Box>
}

interface TableTreeViewRowProps extends PropsWithChildren {
  isLastRow?: boolean
  sx?: SxProps<Theme>
}
export const TableTreeViewRow: FC<TableTreeViewRowProps> = (props) => {
  return (
    <Box
      sx={{
        display: 'grid',
        ...props.sx,
      }}
    >
      {props.children}
    </Box>
  )
}

export const VirtualizedTableTreeView: FC<VirtualizedTableTreeViewProps> = (
  props
) => {
  const renderHeader = useCallback(() => {
    return (
      <TableTreeViewRow sx={{ gridTemplateColumns: props.gridTemplateColumns }}>
        {props.renderHeaderRow()}
      </TableTreeViewRow>
    )
  }, [props])

  const renderItem = useCallback(
    (item: VirtualizedTreeViewDataItem, level: number) => {
      return (
        <TableTreeViewRow
          sx={{ gridTemplateColumns: props.gridTemplateColumns }}
        >
          {props.renderItemRow(item, level)}
        </TableTreeViewRow>
      )
    },
    [props]
  )

  return (
    <VirtualizedTreeView
      {...props}
      renderHeader={renderHeader}
      renderItem={renderItem}
    />
  )
}
