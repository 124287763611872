export const StockTrackingHelpers = {
  packsAndSplitUnitsFromUnitsAndPackSize: (
    stockUnits: number,
    packSize: number | undefined
  ): { packUnits: number | undefined; splitUnits: number } => {
    const splitUnits = packSize ? stockUnits % packSize : stockUnits
    const packUnits =
      packSize && splitUnits != null ? (stockUnits - splitUnits) / packSize : undefined
    return { packUnits, splitUnits }
  },
}
