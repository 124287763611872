import { FC, PropsWithChildren, useState } from 'react'
import Modal from '@mui/material/Modal'
import CloseModalButton from './CloseModalButton'
import Box from '@mui/material/Box'
import theme from '../../styles/theme'
import Typography from '@mui/material/Typography'
import Zoom from '@mui/material/Zoom'

interface ModalDialogProps extends PropsWithChildren {
  onClosed: () => void
  width?: number | string
  height?: number | string
  title?: string
  closeDisabled?: boolean
}

const ModalDialog: FC<ModalDialogProps> = ({
  children,
  onClosed,
  width,
  height,
  title,
  closeDisabled,
}) => {
  const [open, setOpen] = useState<boolean>(true)
  return (
    <Modal
      data-testid="modal-dialog"
      open={open}
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Zoom in={open}>
        <Box
          sx={{
            margin: theme.spacing(5),
            marginTop: 0,
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            //   flexGrow: 1,
            borderRadius: '5px',
            padding: theme.spacing(2),
            width: width ?? '640px',
            height: height ?? 'auto',
            '&:focus-visible': { outline: 'none' },
          }}
        >
          <CloseModalButton
            onClick={() => {
              if (!closeDisabled) {
                setOpen(false)
                onClosed()
              }
            }}
          />
          {title && (
            <Typography
              variant="h6"
              color={'primary'}
              sx={{ fontSize: '.8rem', textTransform: 'uppercase' }}
            >
              {title}
            </Typography>
          )}
          {children}
        </Box>
      </Zoom>
    </Modal>
  )
}

export default ModalDialog
