import Box from '@mui/material/Box'
import {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import theme from '../../styles/theme'
import { locale } from '../../locales'
import {
  createRecoilPageState,
  PageAction,
  PageReducer,
  PageState,
  usePageState,
} from '../PageState'
import {
  StockProductNestedItem,
  stockProductNestedItemFromStockProductLevelResponse,
  StockTrackingItemResponse,
  StockTrackingListResponse,
  StockTrackingNegativeStockState,
  StockTrackingResetState,
} from './entities/EntitiesV3'
import { RecoilPageIds, WebSocketActions } from '../../constants'
import { useAbortableRequest } from '../../hooks/useAbortableRequest'
import { ServiceContext } from '../../providers/ServicesProvider'
import { PlatformApiPaths } from '../../PlatformApiPaths'
import { GetErrorMessage } from '../../utils/ErrorHandling'
import { useGlobalIsLoading } from '../../hooks/useIsLoading'
import {
  VirtualizedTreeViewDataItem,
  VirtualizedTreeViewState,
} from '../../components/Data/VirtualizedTreeView'
import {
  TableTreeViewCell,
  VirtualizedTableTreeView,
} from '../../components/Data/VirtualizedTableTreeView'
import Typography, { TypographyProps } from '@mui/material/Typography'
import SwitchWithInfo from '../../components/Forms/SwitchWithInfo'
import SearchBox from '../../components/Forms/SearchBox'
import { textFiltering } from '../../utils/FilteringUtls'
import ProductFilterBar, {
  InitialProductFilterBarState,
  productFilterBarFilter,
  ProductFilterBarState,
} from '../../components/Interactions/ProductFilterBar'
import EditIcon from '@mui/icons-material/Edit'
import IconButton from '@mui/material/IconButton'
import InfoTooltip from '../../components/Interactions/InfoTooltip'
import AdjustmentFormNew from './components/AdjustmentFormNew'
import { useRecoilState } from 'recoil'
import { webSocketLastMessageState } from '../../state/WebSocketState'
import Alert from '@mui/material/Alert/Alert'
import { DateTime } from 'luxon'
import ButtonBase from '@mui/material/ButtonBase'
import { sortArrayByPropertyKey } from '../../utils/SortingUtils'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import ArrowUpward from '@mui/icons-material/ArrowUpward'

const CellTypography = (
  props: { heavier?: boolean; leftAlign?: boolean } & TypographyProps
) => {
  const { heavier, leftAlign, ...rest } = props
  return (
    <Typography
      variant="body2"
      sx={{
        fontWeight: props.heavier ? '600' : '500',
        textAlign: props.leftAlign ? 'left' : 'center',
      }}
      {...rest}
    />
  )
}

const translation = locale.translation.StockTrackingPage.TabInventory
const levelDistanceChUnit = 1.4

// Page-specific state
interface TabInventoryPageState extends PageState<StockProductNestedItem> {
  showUninitialisedProducts: boolean
  productFilterBarState: ProductFilterBarState
  virtualizedTreeViewState?: VirtualizedTreeViewState
  selectedItem?: StockProductNestedItem | null
}

type SetVirtualizedTreeViewStateAction = {
  type: 'SET_VIRTUALIZED_TREE_VIEW_STATE'
  payload: VirtualizedTreeViewState
}

type SetShowUnitialisedProductsAction = {
  type: 'SET_SHOW_UNINITIALISED_PRODUCTS'
  payload: boolean
}

type SetProductsFilterBarStateAction = {
  type: 'SET_PRODUCTS_FILTER_BAR_STATE'
  payload: ProductFilterBarState
}

type SetSelectedItemAction = {
  type: 'SET_SELECTED_ITEM'
  payload: StockProductNestedItem | null
}

type TabInventoryPageAction =
  | PageAction<StockProductNestedItem>
  | SetVirtualizedTreeViewStateAction
  | SetShowUnitialisedProductsAction
  | SetProductsFilterBarStateAction
  | SetSelectedItemAction

const processSortingAndFiltering = (state: TabInventoryPageState) => {
  if (state.items) {
    let items = state.items
    if (!state.showUninitialisedProducts) {
      items = items.filter((item) =>
        [StockTrackingResetState.MIXED, StockTrackingResetState.RESET].includes(
          item.flags?.resetState ?? StockTrackingResetState.NOT_RESET
        )
      )
    }
    items = productFilterBarFilter(
      state.productFilterBarState,
      items
    ) as StockProductNestedItem[]
    if (state.searchText) {
      items = items.filter((i) =>
        textFiltering(state.searchText, i.productName)
      )
    }
    if (state.sorting) {
      sortArrayByPropertyKey(items, {
        sortingType: state.sorting.sortingType,
        sortingPropertyKey: state.sorting.dataKey,
      })
    }

    return items
  }
  return []
}

class TabInventoryReducer extends PageReducer<StockProductNestedItem> {
  reducer(
    state: TabInventoryPageState,
    action: TabInventoryPageAction
  ): TabInventoryPageState {
    const updateFilteredItems = () => {
      state = {
        ...state,
        filteredItems: processSortingAndFiltering(state),
      }
    }

    state = {
      ...(super.reducer(
        state,
        action as PageAction<StockProductNestedItem>
      ) as TabInventoryPageState),
    }

    switch (action.type) {
      case 'SET_VIRTUALIZED_TREE_VIEW_STATE':
        state = {
          ...state,
          virtualizedTreeViewState: action.payload,
        }
        break
      case 'SET_SHOW_UNINITIALISED_PRODUCTS':
        state = {
          ...state,
          showUninitialisedProducts: action.payload,
        }
        updateFilteredItems()
        break
      case 'setSearchField':
        state = { ...state, searchText: action.payload.text }
        updateFilteredItems()
        break
      case 'setSorting':
        state = {
          ...state,
          sorting: action.payload.sorting,
        }
        updateFilteredItems()
        break
      case 'SET_PRODUCTS_FILTER_BAR_STATE':
        state = {
          ...state,
          productFilterBarState: action.payload,
        }
        updateFilteredItems()
        break
      case 'SET_SELECTED_ITEM':
        state = {
          ...state,
          selectedItem: action.payload,
        }
        break
    }

    return state
  }
}

// Create static objects for the recoil state and reducer
const { recoilPageState, recoilTableState, recoilLoadingAbortedState } =
  createRecoilPageState<StockProductNestedItem>(
    RecoilPageIds.TabStockTrackingInventory
  )

const pageReducer = new TabInventoryReducer()

interface ProductRowCellProps extends PropsWithChildren {
  level?: number
  noLeftBorder?: boolean
  flexStart?: boolean
}
const ProductRowCell: FC<ProductRowCellProps> = (props) => {
  return (
    <TableTreeViewCell
      sx={{
        borderLeft: props.noLeftBorder
          ? 'none'
          : `1px solid ${theme.palette.grey[300]}`,
        padding: theme.spacing(1),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: props.flexStart ? 'flex-start' : 'center',
        gap: theme.spacing(0.5),
        minHeight: '5ch',
      }}
    >
      {props.level != null && (
        <div style={{ width: `${props.level * levelDistanceChUnit}ch` }}></div>
      )}
      {props.children}
    </TableTreeViewCell>
  )
}

const EditButton: FC<{
  item: StockProductNestedItem
  level: number
  onClick: (item: StockProductNestedItem, level: number) => void
}> = (props) => {
  return (
    <IconButton
      size="small"
      onClick={() => props.onClick(props.item, props.level)}
    >
      <EditIcon
        sx={{
          width: theme.spacing(3),
          color: theme.palette.grey[700],
          cursor: 'pointer',
        }}
      />
    </IconButton>
  )
}

const WarningTooltip: FC<{ item: StockProductNestedItem }> = (props) => {
  const warningTexts = []
  if (
    [StockTrackingResetState.MIXED, StockTrackingResetState.NOT_RESET].includes(
      props.item.flags?.resetState ?? StockTrackingResetState.NOT_RESET
    )
  ) {
    warningTexts.push(translation.WarningTrackingNeverReset)
  }

  if (
    [
      StockTrackingNegativeStockState.MIXED,
      StockTrackingNegativeStockState.NEGATIVE,
    ].includes(
      props.item.flags?.negativeStockState ??
        StockTrackingNegativeStockState.POSITIVE
    )
  ) {
    warningTexts.push(translation.WarningTrackingNegativeBalance)
  }

  return (
    <Box
      sx={{
        width: theme.spacing(3),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
      }}
    >
      {warningTexts.length > 0 && (
        <InfoTooltip mode="warning" text={warningTexts.join('\n')} />
      )}
    </Box>
  )
}

const TabInventory: FC<{}> = () => {
  // Handle page state for this page
  const {
    state: genericPageState,
    dispatch: genericDispatch,
    virtuosoTableState,
    shouldRunLoadingEffect,
    virtuosoTableHelpers,
    setLoadingAbortedState,
  } = usePageState<StockProductNestedItem>(
    pageReducer,
    recoilPageState,
    recoilTableState,
    recoilLoadingAbortedState,
    (item) => `${item.productId}`,
    { sortingType: 'ASC', dataKey: 'productName' },
    undefined,
    {
      showUninitialisedProducts: false,
      productFilterBarState: {
        ...InitialProductFilterBarState,
        hideConcessionaryStates: true,
      },
    },
    (items, state) => processSortingAndFiltering(state as TabInventoryPageState)
  )
  // Type conversion to page specific state
  const state = genericPageState as TabInventoryPageState
  const dispatch = genericDispatch as React.Dispatch<TabInventoryPageAction>
  const { platformHttpService } = useContext(ServiceContext)
  const { setIsLoading } = useGlobalIsLoading()
  const { abortControllerRef, startAbortableRequest, finishAbortableRequest } =
    useAbortableRequest(setLoadingAbortedState)
  const [webSocketLastMessage, setWebSocketLastMessage] = useRecoilState(
    webSocketLastMessageState
  )
  const [batchNumberNesting, setBatchNumberNesting] = useState(false)

  // Main useEffect to load data
  useEffect(() => {
    const getData = async () => {
      return await platformHttpService.getAsync<StockTrackingListResponse>(
        PlatformApiPaths.GetAllTrackedProductsGrouped(
          state.selectedClient!.clientId
        ),
        'StockBaseUrl',
        abortControllerRef.current?.signal
      )
    }
    if (shouldRunLoadingEffect) {
      startAbortableRequest()
      dispatch({ type: 'setError', payload: null })

      getData().then((response) => {
        // Check if the request was cancelled
        if (finishAbortableRequest(response.wasCancelled)) {
          return
        }
        // Process response
        if (response?.data && !response.hasErrors) {
          setBatchNumberNesting(response.data.includesBatchNumbers)
          dispatch({
            type: 'addItems',
            payload: {
              items: response.data.products.map((i) =>
                stockProductNestedItemFromStockProductLevelResponse(
                  i,
                  response.data!.includesBatchNumbers
                )
              ),
              hasMorePages: false,
            },
          })
        } else {
          dispatch({
            type: 'setError',
            payload: GetErrorMessage(response?.statusCode),
          })
        }
        setIsLoading(false)
      })
    }
  }, [
    abortControllerRef,
    dispatch,
    finishAbortableRequest,
    platformHttpService,
    setIsLoading,
    shouldRunLoadingEffect,
    startAbortableRequest,
    state.selectedClient,
  ])

  const onVirtualizedTreeViewStateUpdated = useCallback(
    (state: VirtualizedTreeViewState) => {
      dispatch({ type: 'SET_VIRTUALIZED_TREE_VIEW_STATE', payload: state })
    },
    [dispatch]
  )

  const handleLoadSingleProduct = useCallback(
    async (productId: string) => {
      setIsLoading(true)
      const response =
        await platformHttpService.getAsync<StockTrackingItemResponse>(
          PlatformApiPaths.GetTrackedProductGroupedById(
            state.selectedClient!.clientId,
            productId
          ),
          'StockBaseUrl'
        )
      setIsLoading(false)
      if (response?.data && !response.hasErrors) {
        if (!response.data.product) {
          dispatch({
            type: 'deleteItem',
            payload: productId,
          })
        } else {
          dispatch({
            type: 'replaceItems',
            payload: [
              stockProductNestedItemFromStockProductLevelResponse(
                response.data.product,
                response.data.includesBatchNumbers
              ),
            ],
          })
        }
      } else {
        dispatch({
          type: 'setError',
          payload: GetErrorMessage(response?.statusCode),
        })
      }
    },
    [dispatch, platformHttpService, setIsLoading, state.selectedClient]
  )

  // Websocket useEffect
  useEffect(() => {
    if (
      webSocketLastMessage?.action ===
      WebSocketActions.StockTrackingProductUpdated
    ) {
      handleLoadSingleProduct(webSocketLastMessage.productId)
      dispatch({ type: 'SET_SELECTED_ITEM', payload: null })
    }
    setWebSocketLastMessage(null)
  }, [
    dispatch,
    handleLoadSingleProduct,
    setWebSocketLastMessage,
    state.selectedItem,
    webSocketLastMessage,
  ])

  const handleEditButtonClick = useCallback(
    async (item: StockProductNestedItem, level: number) => {
      dispatch({ type: 'SET_SELECTED_ITEM', payload: item })
    },
    [dispatch]
  )

  const renderHeaderCell = useCallback(
    (title: string, noLeftBorder?: boolean, sortingKey?: string) => {
      const moveToNextSorting = () => {
        if (state.sorting?.dataKey === sortingKey) {
          return state.sorting?.sortingType === 'ASC'
            ? 'DESC'
            : state.sorting?.sortingType === 'NONE'
            ? 'ASC'
            : 'NONE'
        }
        return 'ASC'
      }

      return (
        <TableTreeViewCell
          sx={{
            padding: theme.spacing(1),
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            borderLeft: noLeftBorder
              ? 'none'
              : `1px solid ${theme.palette.grey[300]}`,
          }}
        >
          <ButtonBase
            disabled={!Boolean(sortingKey)}
            onClick={() => {
              dispatch({
                type: 'setSorting',
                payload: {
                  sorting: {
                    dataKey: sortingKey,
                    sortingType: moveToNextSorting(),
                  },
                  resetPaging: false,
                },
              })
            }}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: Boolean(sortingKey) ? 'pointer' : 'default',
              minHeight: '6ch',
            }}
          >
            <Typography
              variant="button"
              sx={{
                fontWeight: 400,
                color: theme.palette.common.black,
                cursor: 'inherit',
              }}
            >
              {title}
            </Typography>
            {state.sorting?.dataKey === sortingKey &&
              state.sorting?.sortingType === 'ASC' && (
                <ArrowUpward fontSize="inherit" sx={{ fontSize: '1rem' }} />
              )}
            {state.sorting?.dataKey === sortingKey &&
              state.sorting?.sortingType === 'DESC' && (
                <ArrowDownwardIcon
                  fontSize="inherit"
                  sx={{ fontSize: '1rem' }}
                />
              )}
          </ButtonBase>
        </TableTreeViewCell>
      )
    },
    [dispatch, state.sorting?.dataKey, state.sorting?.sortingType]
  )

  const renderHeaderRow = useCallback(() => {
    return (
      <>
        {renderHeaderCell(
          translation.TableColumnTitles.Product,
          true,
          'productName'
        )}
        {renderHeaderCell(
          translation.TableColumnTitles.UnitOfMeasure,
          false,
          'unitOfMeasure'
        )}
        {renderHeaderCell(
          translation.TableColumnTitles.UnitsInStock,
          false,
          'currentStockUnits'
        )}
        {renderHeaderCell(
          translation.TableColumnTitles.PacksInStock,
          false,
          'packStockUnits'
        )}
        {renderHeaderCell(
          translation.TableColumnTitles.SplitPackUnits,
          false,
          'packSplitStockUnits'
        )}
        {batchNumberNesting &&
          renderHeaderCell(translation.TableColumnTitles.BatchNumber)}
        {renderHeaderCell('')}
        {renderHeaderCell('')}
      </>
    )
  }, [batchNumberNesting, renderHeaderCell])

  const renderProductRow = useCallback(
    (item: StockProductNestedItem) => {
      return (
        <>
          <ProductRowCell level={0} flexStart noLeftBorder>
            <CellTypography heavier leftAlign>
              {item.productName}
            </CellTypography>
          </ProductRowCell>
          <ProductRowCell>
            <CellTypography heavier>{item.unitOfMeasure}</CellTypography>
          </ProductRowCell>
          <ProductRowCell>
            <CellTypography heavier>{item.currentStockUnits}</CellTypography>
          </ProductRowCell>
          <ProductRowCell>
            <CellTypography heavier>{item.packStockUnits}</CellTypography>
          </ProductRowCell>
          <ProductRowCell>
            <CellTypography heavier>{item.packSplitStockUnits}</CellTypography>
          </ProductRowCell>
          {batchNumberNesting && <ProductRowCell></ProductRowCell>}
          <ProductRowCell></ProductRowCell>
          <ProductRowCell>
            <WarningTooltip item={item} />
          </ProductRowCell>
        </>
      )
    },
    [batchNumberNesting]
  )

  const renderPackRow = useCallback(
    (item: StockProductNestedItem) => {
      return (
        <>
          <ProductRowCell level={1} flexStart noLeftBorder>
            <CellTypography variant="body2">
              {item.packSize
                ? `${item.packSize} ${item.unitOfMeasure}
            ${
              item.subPackDescription ? '(' + item.subPackDescription + ')' : ''
            }`
                : translation.UnspecifiedPackSize}
            </CellTypography>
          </ProductRowCell>
          <ProductRowCell>
            <CellTypography></CellTypography>
          </ProductRowCell>
          <ProductRowCell>
            <CellTypography variant="body2">
              {item.currentStockUnits}
            </CellTypography>
          </ProductRowCell>
          <ProductRowCell>
            <CellTypography>{item.packStockUnits}</CellTypography>
          </ProductRowCell>
          <ProductRowCell>
            <CellTypography>{item.packSplitStockUnits}</CellTypography>
          </ProductRowCell>
          {batchNumberNesting && <ProductRowCell></ProductRowCell>}
          <ProductRowCell></ProductRowCell>
          <ProductRowCell>
            <WarningTooltip item={item} />
          </ProductRowCell>
        </>
      )
    },
    [batchNumberNesting]
  )

  const renderDistributorRow = useCallback(
    (item: StockProductNestedItem) => {
      return (
        <>
          <ProductRowCell level={2} flexStart noLeftBorder>
            <CellTypography>
              {item.distributor ??
                (item.amppId === 'PARALLEL_IMPORT'
                  ? translation.ParallelImport
                  : translation.UnspecifiedDistributor)}
            </CellTypography>
          </ProductRowCell>
          <ProductRowCell>
            <CellTypography></CellTypography>
          </ProductRowCell>
          <ProductRowCell>
            <CellTypography>{item.currentStockUnits}</CellTypography>
          </ProductRowCell>
          <ProductRowCell>
            <CellTypography>{item.packStockUnits}</CellTypography>
          </ProductRowCell>
          <ProductRowCell>
            <CellTypography>{item.packSplitStockUnits}</CellTypography>
          </ProductRowCell>
          {batchNumberNesting && <ProductRowCell></ProductRowCell>}
          {!batchNumberNesting && (
            <ProductRowCell>
              <EditButton
                item={item}
                level={3}
                onClick={handleEditButtonClick}
              />
            </ProductRowCell>
          )}
          <ProductRowCell>
            <WarningTooltip item={item} />
          </ProductRowCell>
        </>
      )
    },
    [batchNumberNesting, handleEditButtonClick]
  )

  const renderExpiryDateRow = useCallback(
    (item: StockProductNestedItem) => {
      return (
        <>
          <ProductRowCell level={3} flexStart noLeftBorder>
            <CellTypography>
              {item.expiryDate
                ? translation.Expires(DateTime.fromISO(item.expiryDate))
                : translation.UnspecifiedExpiryDate}
            </CellTypography>
          </ProductRowCell>
          <ProductRowCell>
            <CellTypography></CellTypography>
          </ProductRowCell>
          <ProductRowCell>
            <CellTypography>{item.currentStockUnits}</CellTypography>
          </ProductRowCell>
          <ProductRowCell>
            <CellTypography>{item.packStockUnits}</CellTypography>
          </ProductRowCell>
          <ProductRowCell>
            <CellTypography>{item.packSplitStockUnits}</CellTypography>
          </ProductRowCell>
          <ProductRowCell>
            <CellTypography>{item.expiryDate ?? '-'}</CellTypography>
          </ProductRowCell>
          {batchNumberNesting && (
            <ProductRowCell>
              <EditButton
                item={item}
                level={3}
                onClick={handleEditButtonClick}
              />
            </ProductRowCell>
          )}
          <ProductRowCell>
            <WarningTooltip item={item} />
          </ProductRowCell>
        </>
      )
    },
    [batchNumberNesting, handleEditButtonClick]
  )

  const renderTreeItem = useCallback(
    (item: VirtualizedTreeViewDataItem, level: number) => {
      const i = item as StockProductNestedItem
      switch (level) {
        case 0:
          return renderProductRow(i)
        case 1:
          return renderPackRow(i)
        case 2:
          return renderDistributorRow(i)
        case 3:
          return renderExpiryDateRow(i)
        default:
          return <> </>
      }
    },
    [renderDistributorRow, renderExpiryDateRow, renderPackRow, renderProductRow]
  )

  const handleProductFilterBarStateChange = useCallback(
    (productFilterState: ProductFilterBarState) => {
      dispatch({
        type: 'SET_PRODUCTS_FILTER_BAR_STATE',
        payload: productFilterState,
      })
    },
    [dispatch]
  )

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        paddingY: theme.spacing(2),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: theme.spacing(1),
        }}
      >
        <SearchBox
          placeholder={translation.SearchPlaceholder}
          searchText={state.searchText}
          onTextUpdated={(text) => {
            dispatch({
              type: 'setSearchField',
              payload: { text, resetPaging: false },
            })
          }}
          testId={'search-text-field'}
        />
      </Box>
      <ProductFilterBar
        state={state.productFilterBarState}
        onProductFilterBarStateChange={handleProductFilterBarStateChange}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          gap: theme.spacing(1),
          marginBottom: theme.spacing(1),
        }}
      >
        <SwitchWithInfo
          checked={state.showUninitialisedProducts}
          onChange={() => {
            dispatch({
              type: 'SET_SHOW_UNINITIALISED_PRODUCTS',
              payload: !state.showUninitialisedProducts,
            })
          }}
          testId="use-trade-price-toggle"
          label={translation.ShowUninitialisedProductsToggle}
        />
      </Box>
      {!state.error && (
        <Box
          sx={{
            display: 'block',
            height: '100%',
            width: '100%',
            paddingY: 0,
            border: `1px solid ${theme.palette.grey[300]}`,
            borderRadius: theme.spacing(1),
            overflow: 'hidden',
          }}
        >
          <VirtualizedTableTreeView
            gridTemplateColumns={
              batchNumberNesting
                ? '52fr 12fr 7fr 7fr 7fr 7fr 64px 64px'
                : '54fr 14fr 8fr 8fr 8fr 64px 64px'
            }
            initialState={state.virtualizedTreeViewState}
            data={state.filteredItems ?? []}
            maxNestingLevel={batchNumberNesting ? 3 : 2}
            levelDistanceChUnit={levelDistanceChUnit}
            onStateUpdated={onVirtualizedTreeViewStateUpdated}
            onRangeChanged={virtuosoTableHelpers.handleRangeChanged}
            initialFirstItemIndex={virtuosoTableState.firstItemIndex}
            renderHeaderRow={renderHeaderRow}
            renderItemRow={renderTreeItem}
            headerSx={{ backgroundColor: theme.palette.grey[100] }}
            rowSx={{
              borderBottom: `1px solid ${theme.palette.grey[300]}`,
              '&:hover': {
                backgroundColor: theme.palette.grey[100],
              },
            }}
          />
        </Box>
      )}
      {state.error && (
        <Alert
          sx={{
            marginBottom: theme.spacing(2),
          }}
          variant="filled"
          severity="error"
        >
          {state.error}
        </Alert>
      )}

      {state.selectedItem && (
        <AdjustmentFormNew
          selectedProductDetails={state.selectedItem}
          onCloseCallback={() =>
            dispatch({ type: 'SET_SELECTED_ITEM', payload: null })
          }
        />
      )}
    </Box>
  )
}

export default TabInventory
