import {
  FC,
  useContext,
  useEffect,
  useReducer,
  useState,
  useCallback,
  useMemo,
  useRef,
  useTransition,
} from 'react'
import ModalContainer from '../../../components/Interactions/ModalContainer'
import { ServiceContext } from '../../../providers/ServicesProvider'
import { PlatformApiPaths } from '../../../PlatformApiPaths'
import {
  BookIn,
  InvoicePage,
  PresignedUrlResponse,
  ProductItem,
} from '../entities/BookIn'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Button from '@mui/material/Button'
import theme from '../../../styles/theme'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { locale } from '../../../locales'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import MultiDeliveryFormProduct, {
  MultiDeliveryFormProductState,
  validateProductState,
} from './MultiDeliveryFormProduct'
import InvoiceHeader, {
  InvoiceHeaderState,
  validateInvoiceHeaderState,
} from './InvoiceHeader'
import { useSuppliersWithFinancials } from '../../../hooks/useSuppliers'
import {
  BookInStatuses,
  CreationMediums,
  EditDeliveryModes,
  OrganisationFeatureFlags,
  PharmacyFeatureFlags,
} from '../../../constants'
import { SupplierWithFinancials } from '../../../types/entities/Supplier'
import SplitPane, { Pane } from '../../../third-party/split-pane-react'
import '../../../third-party/split-pane-react/themes/default.scss'
import AutoSizingBox from '../../../components/Util/AutoSizingBox'
import {
  GroupedVirtuoso,
  GroupedVirtuosoHandle,
  StateSnapshot,
} from 'react-virtuoso'
import { groupBy, orderBy } from 'lodash'
import InvoiceItemsPane, {
  InvoiceItemsPaneHandle,
  InvoiceItemsPaneState,
} from './InvoiceItemsPane'
import { InvoiceType, invoiceTypeToExtension } from '../entities/InvoiceType'
import InvoiceImageButton from './InvoiceImageButton'
import LoadingButton from '@mui/lab/LoadingButton'
import { DateTime } from 'luxon'
import Alert from '@mui/material/Alert'
import Markdown from 'marked-react'
import SelectProductModal from './SelectProductModal'
import ConfirmDialog from '../../../components/Interactions/ConfirmDialog'
import { stockrxUserState } from '../../../state/AuthenticationState'
import { useRecoilValue } from 'recoil'
import { User } from '../../../types/entities/User'
import { GetErrorMessage } from '../../../utils/ErrorHandling'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import { FeatureFlagHelpers } from '../../../utils/FeatureFlagHelpers'

const translation = locale.translation.GoodsInPage.MultiDeliveryForm

interface TabState {
  bookInId: string | null
  supplierName: string | null
  odsCode: string | null
  vatEditingMode: string | null
  isSortingAz: boolean
  isPdfDownloadButtonOpen: boolean
  header: InvoiceHeaderState | null
  products: MultiDeliveryFormProductState[]
  virtuosoState?: StateSnapshot
  healthScorePenalties?: { [key: string]: string[] }
  scanningMandatory?: boolean
  error?: string | null
  invoicePageItems: InvoiceItemsPaneState | null
}

export interface MultiDeliveryFormState {
  user: User | null
  suppliersWithFinancials: SupplierWithFinancials[]
  bookIns: BookIn[]
  editDeliveryMode: string
  selectedTab: number | null
  tabStates: TabState[]
  busyProductsCount: number
  deletedBookInIds: string[]
  shouldAutoClose: boolean
  hasModifiedHeaders?: boolean
  hasModifiedProducts?: boolean
  hasModifiedStatus?: boolean
  isCloseConfirmationModalOpen?: boolean
  isShowingMultipleSuppliers?: boolean
}

type SetSelectedTabAction = { type: 'SET_SELECTED_TAB'; payload: number }
type SetUserAction = { type: 'SET_USER'; payload: User }
type SetSuppliersWithFinancialsAction = {
  type: 'SET_SUPPLIERS_FINANCIALS'
  payload: SupplierWithFinancials[]
}
type SetBookInsAction = {
  type: 'SET_BOOKINS'
  payload: BookIn[]
}
type SetProductStateAction = {
  type: 'SET_PRODUCT_STATE'
  payload: MultiDeliveryFormProductState
}
type SetHeaderStateAction = {
  type: 'SET_HEADER_STATE'
  payload: InvoiceHeaderState
}
type SetImagesPaneStateAction = {
  type: 'SET_IMAGES_PANE_STATE'
  payload: InvoiceItemsPaneState
}
type SetVirtuosoStateAction = {
  type: 'SET_VIRTUOSO_STATE'
  payload: StateSnapshot
}
type SetSortingAzStateAction = {
  type: 'SET_SORTING_AZ'
  payload: boolean
}
type SetPdfDownloadButtonStateAction = {
  type: 'SET_PDF_DOWNLOAD_BUTTON_STATE'
  payload: boolean
}
type RemoveProductAction = {
  type: 'REMOVE_PRODUCT'
  payload: MultiDeliveryFormProductState
}
type IncreaseBusyProductsAction = {
  type: 'INCREASE_BUSY_PRODUCTS'
}
type DecreaseBusyProductsAction = {
  type: 'DECREASE_BUSY_PRODUCTS'
}
type AddProductAction = {
  type: 'ADD_PRODUCT'
  payload: {
    pageNumber: number
    lineNumber: number
    productId: string
    packSize: number
    productName: string
    unitOfMeasure: string | null
    subPackDescription: string | null
    amppId: string | null
    hasAmpp?: boolean
  }
}
type SetBookInCompletedAction = {
  type: 'SET_BOOKIN_COMPLETED'
  payload: string
}
type SetBookInReEditAction = {
  type: 'SET_BOOKIN_REEDIT'
  payload: string
}
type RemoveDeliveryAction = { type: 'REMOVE_DELIVERY'; payload: string }
type SetTabErrorAction = {
  type: 'SET_TAB_ERROR'
  payload: string | null
}
type SetInvoiceImageLoadedAction = {
  type: 'SET_INVOICE_IMAGE_LOADED'
  payload: { bookInId: string; pageNumber: number; base64: string }
}
type SetTabInvoiceImagesPaneScrollTopAction = {
  type: 'SET_TAB_INVOICE_IMAGES_PANE_SCROLL_TOP'
  payload: number
}
type SetIsCloseConfirmationModalOpen = {
  type: 'SET_IS_CLOSE_CONFIRMATION_MODAL_OPEN'
  payload: boolean
}
type Action =
  | SetSelectedTabAction
  | SetSuppliersWithFinancialsAction
  | SetUserAction
  | SetBookInsAction
  | SetProductStateAction
  | SetHeaderStateAction
  | SetImagesPaneStateAction
  | SetVirtuosoStateAction
  | SetSortingAzStateAction
  | SetPdfDownloadButtonStateAction
  | RemoveProductAction
  | IncreaseBusyProductsAction
  | DecreaseBusyProductsAction
  | AddProductAction
  | SetBookInCompletedAction
  | SetBookInReEditAction
  | RemoveDeliveryAction
  | SetTabErrorAction
  | SetInvoiceImageLoadedAction
  | SetTabInvoiceImagesPaneScrollTopAction
  | SetIsCloseConfirmationModalOpen

const reducer = (
  state: MultiDeliveryFormState,
  action: Action
): MultiDeliveryFormState => {
  const supplierWithFinancials = (bookIn: BookIn) => {
    var result =
      state.suppliersWithFinancials.find(
        (s) =>
          s.supplierId === bookIn.supplierId &&
          s.supplierComposedSortKey === bookIn.supplierSortKey
      ) ?? null
    return result
  }

  const vatEditingMode = (
    supplierWithFinancials: SupplierWithFinancials | null
  ) =>
    state.editDeliveryMode === EditDeliveryModes.HealthScore ||
    state.editDeliveryMode === EditDeliveryModes.MonthEndReconciliation
      ? supplierWithFinancials?.vatMapping ?? null
      : null

  const showGrossPrice = (bookIn: BookIn) =>
    (state.editDeliveryMode === EditDeliveryModes.HealthScore ||
      state.editDeliveryMode === EditDeliveryModes.MonthEndReconciliation) &&
    supplierWithFinancials(bookIn)?.vatMapping != null

  const tabsStatesForBookInId = (bookInId: string) => {
    const selectedTabState = state.tabStates[state.selectedTab!]
    const otherTabState = selectedTabState.bookInId
      ? state.tabStates.find((ts) => ts.bookInId === null)
      : state.tabStates.find((ts) => ts.bookInId === bookInId)
    return [selectedTabState, otherTabState]
  }

  const mapHealthscoreToProducts = (
    healthscorePenalties: { [key: string]: string[] },
    productRangeKey: string
  ): string[] => {
    const result: string[] = []
    Object.keys(healthscorePenalties).forEach((key: string) => {
      if (healthscorePenalties[key].includes(productRangeKey)) {
        result.push(key)
      }
    })
    return result
  }

  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        user: action.payload,
      }
    case 'SET_SELECTED_TAB':
      return {
        ...state,
        selectedTab: action.payload,
      }
    case 'SET_SUPPLIERS_FINANCIALS':
      return {
        ...state,
        suppliersWithFinancials: action.payload,
      }
    case 'SET_BOOKINS':
      // Create initial tab states from loaded book-ins
      const tabStates: TabState[] = []

      // Add invoice tabs
      for (const bookIn of action.payload) {
        const featureFlags = FeatureFlagHelpers.getPharmacyWithOrgFeatureFlags(
          state.user,
          bookIn.odsCode
        )
        const isScanningMandatory =
          bookIn.status !== BookInStatuses.ReEdit &&
          featureFlags.includes(PharmacyFeatureFlags.EnableMandatoryScanning)
        const invoiceDate = bookIn.invoiceDate
          ? DateTime.fromISO(bookIn.invoiceDate)
          : null
        const invoiceHeaderState: InvoiceHeaderState = {
          bookInId: bookIn.bookInId,
          odsCode: bookIn.odsCode,
          supplierId: bookIn.supplierId,
          isReEdit: bookIn.status === BookInStatuses.ReEdit,
          invoiceDate: invoiceDate,
          initialInvoiceDate: invoiceDate,
          invoiceNumber: bookIn.invoiceNumber,
          initialInvoiceNumber: bookIn.invoiceNumber,
          totalExVat: bookIn.totalPrice,
          initialTotalExVat: bookIn.totalPrice,
          totalWithVat: bookIn.totalPriceWithTax,
          initialTotalWithVat: bookIn.totalPriceWithTax,
          editingDisabled:
            bookIn.status === BookInStatuses.Completed || isScanningMandatory,
          showGrossPrice: showGrossPrice(bookIn),
          duplicateInvoiceNumberState:
            bookIn.extraData?.duplicateInvoiceNumberBookInState,
        }

        const supplierWithVat = supplierWithFinancials(bookIn)
        const vatEditing = vatEditingMode(supplierWithVat)
        const enableStockTransfer = featureFlags.includes(
          OrganisationFeatureFlags.StockTransfer
        )
        const products = bookIn.invoicePages!.flatMap((p) =>
          p.productItems!.map((pi) => {
            const productState: MultiDeliveryFormProductState = {
              bookInId: bookIn.bookInId,
              odsCode: bookIn.odsCode,
              altPriceType: bookIn.supplierAltProductPriceField,
              productId: pi.productId,
              packSize: pi.packSize,
              unitOfMeasure: pi.unitOfMeasure,
              subPackDescription: pi.subPackDescription,
              pageNumber: p.pageNumber,
              lineNumber: pi.lineNumber,
              verified: pi.verified,
              manuallyCreated: pi.manuallyCreated,
              productName: pi.vmpName,
              productRawOcrName: pi.productRawOcrName,
              quantity: pi.productQuantity,
              unitPrice: pi.productUnitPrice,
              netPrice: pi.totalPrice,
              grossPrice: pi.totalPriceWithVat,
              vatRate: pi.vatRate,
              vatCode: pi.vatCode,
              adjustmentCreditPacks: pi.adjustment?.numberOfPacks ?? 0,
              adjustmentCreditReason:
                pi.adjustment?.adjustmentReasonCode ?? null,
              gtin: pi.gtin,
              batchNumber: pi.batchNumber,
              expiryDate: pi.expiryDate,
              adjustmentId: pi.adjustment?.adjustmentId ?? null,
              adjustmentPhotoUploadId: pi.adjustment?.photoUploadId ?? null,
              adjustmentNotes: pi.adjustment?.notes ?? null,
              adjustmentProductEditingDisabled:
                pi.adjustment?.isProductEditingDisabled ?? false,
              editingDisabled:
                bookIn.status === BookInStatuses.Completed ||
                isScanningMandatory,
              vatEditingMode: vatEditing,
              supplierWithVat: supplierWithVat,
              isReEdit: bookIn.status === BookInStatuses.ReEdit,
              healthscorePenaltyMessages:
                state.editDeliveryMode === EditDeliveryModes.HealthScore
                  ? mapHealthscoreToProducts(
                      bookIn.bookInHealthscorePenaltyListDict ?? {},
                      `PRODUCT|1|${p.pageNumber}|${pi.lineNumber}`
                    )
                  : undefined,
              amppId: pi.amppId,
              enableStockTransfer,
              transferQuantity: enableStockTransfer
                ? pi.transferQuantity ?? 0
                : null,
            }
            return {
              ...productState,
              errors: validateProductState(productState),
            }
          })
        )
        tabStates.push({
          bookInId: bookIn.bookInId,
          supplierName: bookIn.supplierDisplayName,
          odsCode: bookIn.odsCode,
          isSortingAz: false,
          isPdfDownloadButtonOpen: false,
          vatEditingMode: vatEditing,
          header: {
            ...invoiceHeaderState,
            validationErrors: validateInvoiceHeaderState(invoiceHeaderState),
          },
          products,
          healthScorePenalties: bookIn.bookInHealthscorePenaltyListDict,
          scanningMandatory: isScanningMandatory,
          invoicePageItems: {
            bookInId: bookIn.bookInId,
            invoicePageItemStates:
              !bookIn.invoicePages || bookIn.invoicePages.length === 0
                ? []
                : bookIn.feedInvoiceProductsJson != null
                ? bookIn.invoicePages.map((p) => {
                    return {
                      pageNumber: p.pageNumber,
                      bookInId: bookIn.bookInId,
                      odsCode: bookIn.odsCode,
                      fileKey: `${bookIn.bookInId}_FEED_TABLE`,
                      alt: '',
                      feedTableData: {
                        tableData: bookIn.feedInvoiceProductsJson,
                        creationDate: bookIn.creationDate,
                        supplierName: bookIn.supplierDisplayName,
                      },
                    }
                  })
                : bookIn.invoiceMimeType === InvoiceType.JPG ||
                  bookIn.invoicePages[0].hasPreviewImageFromPdf
                ? bookIn.invoicePages
                    .filter((p) => !p.isManualAdditionsPage)
                    .map((p) => {
                      const keySuffix = p.hasPreviewImageFromPdf
                        ? `.pdf.page.${p.pageNumber}.jpg`
                        : '.jpg'
                      return {
                        pageNumber: p.pageNumber,
                        bookInId: bookIn.bookInId,
                        odsCode: bookIn.odsCode,
                        fileKey: `${bookIn.bookInId}_${p.uploadId}${keySuffix}`,
                        alt: '',
                      }
                    })
                : [],
            selectedPage: null,
          },
        })
      }

      // Add aggregated products tab
      if (action.payload.length > 1) {
        tabStates.push({
          bookInId: null,
          odsCode: null,
          isSortingAz: true,
          isPdfDownloadButtonOpen: false,
          vatEditingMode: null,
          header: null,
          supplierName: tabStates?.[0].supplierName,
          scanningMandatory: tabStates.some((ts) => ts.scanningMandatory),
          products: tabStates
            .map((ts) =>
              ts.products.map((p) => ({ ...p, vatEditingMode: null }))
            )
            .flat(),
          invoicePageItems: {
            bookInId: null,
            selectedPage: null,
            invoicePageItemStates: tabStates
              .filter(
                (ts) =>
                  (ts.invoicePageItems?.invoicePageItemStates.length ?? 0) > 0
              )
              .map((ts) => ts.invoicePageItems!.invoicePageItemStates)
              .flat(),
          },
        })
      }

      const isShowingMultipleSuppliers =
        Object.keys(groupBy(action.payload, (b) => b.supplierId)).length > 1

      return {
        ...state,
        bookIns: action.payload,
        tabStates,
        selectedTab: tabStates.length > 0 ? tabStates.length - 1 : null,
        isShowingMultipleSuppliers: isShowingMultipleSuppliers,
      }

    case 'SET_PRODUCT_STATE':
      const [setProductSelectedTabState, setProductOtherTabState] =
        tabsStatesForBookInId(action.payload.bookInId)

      return {
        ...state,
        hasModifiedProducts: true,
        tabStates: state.tabStates.map((ts) => {
          return ts === setProductSelectedTabState ||
            ts === setProductOtherTabState
            ? {
                ...ts,
                products: ts.products.map((tsp) =>
                  tsp.pageNumber === action.payload.pageNumber &&
                  tsp.lineNumber === action.payload.lineNumber &&
                  tsp.bookInId === action.payload.bookInId
                    ? action.payload
                    : tsp
                ),
              }
            : ts
        }),
      }

    case 'REMOVE_PRODUCT':
      const [removeProductSelectedTabState, removeProductOtherTabState] =
        tabsStatesForBookInId(action.payload.bookInId)
      return {
        ...state,
        hasModifiedProducts: true,
        tabStates: state.tabStates.map((ts) => {
          return ts === removeProductSelectedTabState ||
            ts === removeProductOtherTabState
            ? {
                ...ts,
                products: ts.products.filter(
                  (p) =>
                    p.pageNumber !== action.payload.pageNumber ||
                    p.lineNumber !== action.payload.lineNumber ||
                    p.bookInId !== action.payload.bookInId
                ),
              }
            : ts
        }),
      }

    case 'ADD_PRODUCT':
      const bookIn = state.bookIns[state.selectedTab!]
      const [addProductSelectedTabState, addProductOtherTabState] =
        tabsStatesForBookInId(bookIn.bookInId)
      const supplierWithVat = supplierWithFinancials(bookIn)
      const vatEditing = vatEditingMode(supplierWithVat)
      const enableStockTransfer =
        FeatureFlagHelpers.getPharmacyWithOrgFeatureFlags(
          state.user,
          bookIn.odsCode
        ).includes(OrganisationFeatureFlags.StockTransfer)

      const productToAdd: MultiDeliveryFormProductState = {
        bookInId: bookIn.bookInId,
        odsCode: bookIn.odsCode,
        pageNumber: action.payload.pageNumber,
        lineNumber: action.payload.lineNumber,
        productId: action.payload.productId,
        packSize: action.payload.packSize,
        productName: action.payload.productName,
        unitOfMeasure: action.payload.unitOfMeasure,
        subPackDescription: action.payload.subPackDescription,
        verified: false,
        manuallyCreated: true,
        editingDisabled: false,
        vatEditingMode: vatEditing,
        isReEdit:
          state.bookIns[state.selectedTab!].status === BookInStatuses.ReEdit,
        errors: [],
        unitPrice: 0,
        netPrice: 0,
        grossPrice: 0,
        vatRate: null,
        vatCode: null,
        gtin: null,
        batchNumber: null,
        expiryDate: null,
        adjustmentCreditPacks: 0,
        adjustmentCreditReason: null,
        adjustmentId: null,
        adjustmentPhotoUploadId: null,
        adjustmentNotes: null,
        adjustmentProductEditingDisabled: false,
        productRawOcrName: null,
        quantity: 0,
        supplierWithVat: supplierWithFinancials(bookIn),
        altPriceType: bookIn.supplierAltProductPriceField,
        amppId: action.payload.amppId,
        enableStockTransfer,
        transferQuantity: enableStockTransfer ? 0 : null,
      }

      const bookInManualAdditionsPage = bookIn.invoicePages?.find(
        (p) => p.isManualAdditionsPage
      )
      const hasManualAdditionsPage = bookInManualAdditionsPage != null
      let nextBookInManualAdditionsPage: InvoicePage | null = null
      if (!hasManualAdditionsPage) {
        nextBookInManualAdditionsPage = {
          isManualAdditionsPage: true,
          pageNumber: action.payload.pageNumber,
          productItems: new Array<ProductItem>(),
          hasPreviewImageFromPdf: null,
          ocrSuccessfull: null,
          uploadId: null,
        }
      } else {
        nextBookInManualAdditionsPage = { ...bookInManualAdditionsPage }
      }
      nextBookInManualAdditionsPage!.productItems = [
        ...(nextBookInManualAdditionsPage.productItems ?? []),
        {
          lineNumber: action.payload.lineNumber,
          productId: action.payload.productId,
          packSize: action.payload.packSize,
          vmpName: action.payload.productName,
          unitOfMeasure: action.payload.unitOfMeasure,
          subPackDescription: action.payload.subPackDescription,
          productQuantity: 0,
          productUnitPrice: 0,
          totalPrice: 0,
          totalPriceWithVat: 0,
          vatRate: null,
          vatCode: null,
          verified: false,
          manuallyCreated: true,
          adjustment: null,
          gtin: null,
          batchNumber: null,
          expiryDate: null,
          productRawOcrName: null,
          productRawOcrPackSize: null,
          prescribable: null,
          productDisplayableCode: null,
          amppId: action.payload.amppId,
          transferQuantity: enableStockTransfer ? 0 : null,
        },
      ]

      const nextStateBookInPages = [
        ...(bookIn.invoicePages ?? []).map((ip) =>
          ip.pageNumber === nextBookInManualAdditionsPage!.pageNumber
            ? nextBookInManualAdditionsPage!
            : ip
        ),
        ...(hasManualAdditionsPage ? [] : [nextBookInManualAdditionsPage!]),
      ]

      return {
        ...state,
        hasModifiedProducts: true,
        tabStates: state.tabStates.map((ts) => {
          return ts === addProductSelectedTabState ||
            ts === addProductOtherTabState
            ? {
                ...ts,
                products: [...ts.products, productToAdd],
              }
            : ts
        }),
        bookIns: state.bookIns.map((bi) => {
          return bookIn.bookInId === bi.bookInId
            ? { ...bi, invoicePages: nextStateBookInPages }
            : bi
        }),
      }

    case 'SET_HEADER_STATE':
      return {
        ...state,
        hasModifiedHeaders: true,
        tabStates: state.tabStates.map((ts) => {
          return ts === state.tabStates[state.selectedTab!]
            ? {
                ...ts,
                header: action.payload,
              }
            : ts
        }),
      }

    case 'SET_IMAGES_PANE_STATE':
      return {
        ...state,
        tabStates: state.tabStates.map((ts) => {
          return ts === state.tabStates[state.selectedTab!]
            ? {
                ...ts,
                invoicePageItems: action.payload,
              }
            : ts
        }),
      }
    case 'SET_VIRTUOSO_STATE':
      return {
        ...state,
        tabStates: state.tabStates.map((ts) => {
          return ts === state.tabStates[state.selectedTab!]
            ? {
                ...ts,
                virtuosoState: action.payload,
              }
            : ts
        }),
      }

    case 'SET_SORTING_AZ':
      return {
        ...state,
        tabStates: state.tabStates.map((ts) => {
          return ts === state.tabStates[state.selectedTab!]
            ? {
                ...ts,
                isSortingAz: action.payload,
              }
            : ts
        }),
      }
    case 'SET_PDF_DOWNLOAD_BUTTON_STATE':
      return {
        ...state,
        tabStates: state.tabStates.map((ts) => {
          return ts === state.tabStates[state.selectedTab!]
            ? {
                ...ts,
                isPdfDownloadButtonOpen: action.payload,
              }
            : ts
        }),
      }
    case 'INCREASE_BUSY_PRODUCTS':
      return {
        ...state,
        busyProductsCount: state.busyProductsCount + 1,
      }

    case 'DECREASE_BUSY_PRODUCTS':
      return {
        ...state,
        busyProductsCount: state.busyProductsCount - 1,
      }

    case 'SET_BOOKIN_COMPLETED':
      const [bookInCompletedSelectedTabState, bookInCompletedOtherTabState] =
        tabsStatesForBookInId(action.payload)
      const bookInCompletedState = {
        ...state,
        hasModifiedStatus: true,
        isCloseConfirmationModalOpen: false,
        bookIns: state.bookIns.map((bi) =>
          bi.bookInId === action.payload
            ? { ...bi, status: BookInStatuses.Completed }
            : bi
        ),
        tabStates: state.tabStates.map((ts) => {
          return ts === bookInCompletedSelectedTabState ||
            ts === bookInCompletedOtherTabState
            ? {
                ...ts,
                header: ts.header
                  ? { ...ts.header, editingDisabled: true }
                  : null,
                products: ts.products.map((tsp) => {
                  return tsp.bookInId === action.payload
                    ? {
                        ...tsp,
                        editingDisabled: true,
                      }
                    : tsp
                }),
              }
            : ts
        }),
      }
      return {
        ...bookInCompletedState,
        shouldAutoClose: bookInCompletedState.bookIns.every(
          (bi) => bi.status === BookInStatuses.Completed
        ),
      }

    case 'SET_BOOKIN_REEDIT':
      const [bookInReEditSelectedTabState, bookInReEditOtherTabState] =
        tabsStatesForBookInId(action.payload)
      return {
        ...state,
        hasModifiedStatus: true,
        bookIns: state.bookIns.map((bi) =>
          bi.bookInId === action.payload
            ? { ...bi, status: BookInStatuses.ReEdit }
            : bi
        ),
        tabStates: state.tabStates.map((ts) => {
          return ts === bookInReEditSelectedTabState ||
            ts === bookInReEditOtherTabState
            ? {
                ...ts,
                scanningMandatory: false,
                header: ts.header
                  ? { ...ts.header, isReEdit: true, editingDisabled: false }
                  : null,
                products: ts.products.map((tsp) => {
                  return tsp.bookInId === action.payload
                    ? {
                        ...tsp,
                        isReEdit: true,
                        editingDisabled: false,
                      }
                    : tsp
                }),
              }
            : ts
        }),
      }

    case 'REMOVE_DELIVERY':
      const removeDeliveryAggregatedTabState = state.tabStates.find(
        (ts) => ts.bookInId === null
      )
      const bookInRemovedState = {
        ...state,
        bookIns: state.bookIns.filter((bi) => bi.bookInId !== action.payload),
        tabStates: state.tabStates
          .map((ts) => {
            return ts === removeDeliveryAggregatedTabState
              ? {
                  ...ts,
                  products: ts.products.filter(
                    (p) => p.bookInId !== action.payload
                  ),
                }
              : ts
          })
          .filter((ts) => ts.bookInId !== action.payload),
        deletedBookInIds: [...state.deletedBookInIds, action.payload],
      }

      const isShowingMultipleSuppliersAfterDeletion =
        Object.keys(groupBy(bookInRemovedState.bookIns, (b) => b.supplierId))
          .length > 1

      return {
        ...bookInRemovedState,
        shouldAutoClose: bookInRemovedState.bookIns.length === 0,
        isShowingMultipleSuppliers: isShowingMultipleSuppliersAfterDeletion,
      }

    case 'SET_TAB_ERROR':
      return {
        ...state,
        tabStates: state.tabStates.map((ts) => {
          return ts === state.tabStates[state.selectedTab!]
            ? {
                ...ts,
                error: action.payload,
              }
            : ts
        }),
      }

    case 'SET_INVOICE_IMAGE_LOADED':
      if (state.tabStates.length <= 1) {
        return state
      }
      const targetTabState =
        state.tabStates[state.selectedTab!].bookInId === null
          ? state.tabStates.find(
              (ts) => ts.bookInId === action.payload.bookInId
            )
          : state.tabStates.find((ts) => ts.bookInId === null)

      return {
        ...state,
        tabStates: state.tabStates.map((ts) => {
          return ts === targetTabState
            ? {
                ...ts,
                invoicePageItems: {
                  ...ts.invoicePageItems!,
                  invoicePageItemStates:
                    ts.invoicePageItems!.invoicePageItemStates.map((p) =>
                      p.bookInId === action.payload.bookInId &&
                      p.pageNumber === action.payload.pageNumber
                        ? { ...p, base64: action.payload.base64 }
                        : p
                    ),
                },
              }
            : ts
        }),
      }

    case 'SET_TAB_INVOICE_IMAGES_PANE_SCROLL_TOP':
      return {
        ...state,
        tabStates: state.tabStates.map((ts) => {
          return ts === state.tabStates[state.selectedTab!]
            ? {
                ...ts,
                invoicePageItems: {
                  ...ts.invoicePageItems!,
                  scrollTop: action.payload,
                },
              }
            : ts
        }),
      }
    case 'SET_IS_CLOSE_CONFIRMATION_MODAL_OPEN':
      return {
        ...state,
        isCloseConfirmationModalOpen: action.payload,
      }
    default:
      return state
  }
}

const MultiDeliveryForm: FC<{
  bookInsToLoad: { bookInId: string; odsCode: string }[]
  editDeliveryMode: string
  onClosed: (state: MultiDeliveryFormState) => void
}> = ({ onClosed, ...props }) => {
  const { platformHttpService } = useContext(ServiceContext)
  const { suppliersWithVat } = useSuppliersWithFinancials()
  const user = useRecoilValue(stockrxUserState)
  const [splitPaneSizes, setSplitPaneSizes] = useState<(string | number)[]>([
    'auto',
    '712px',
  ])
  const [state, dispatch] = useReducer(reducer, {
    editDeliveryMode: props.editDeliveryMode,
    user: null,
    suppliersWithFinancials: [],
    bookIns: [],
    selectedTab: null,
    tabStates: [],
    busyProductsCount: 0,
    deletedBookInIds: [],
    shouldAutoClose: false,
  })
  const [isLoading, setIsLoading] = useState(true)
  const [addProductOpen, setAddProductOpen] = useState(false)
  const virtuosoRef = useRef<GroupedVirtuosoHandle>(null)
  const invoiceImagesPaneRef = useRef<InvoiceItemsPaneHandle>(null)
  const selectedTabState: TabState | null =
    state.selectedTab != null ? state.tabStates[state.selectedTab] : null
  const cannotDeleteActionedCredits = useMemo(() => {
    return (
      selectedTabState?.products.some(
        (p) => p.adjustmentProductEditingDisabled
      ) ?? false
    )
  }, [selectedTabState?.products])
  const cannotEditBhs = useMemo(() => {
    return (
      !(
        state.editDeliveryMode === EditDeliveryModes.HealthScore ||
        state.editDeliveryMode === EditDeliveryModes.MonthEndReconciliation
      ) && state.bookIns[state.selectedTab!]?.bookInHealthscoreReviewedUserId
    )
  }, [state.bookIns, state.editDeliveryMode, state.selectedTab])

  const virtuosoGroups = useMemo<{
    groupCounts: number[]
    groupsItems: MultiDeliveryFormProductState[]
    groupsKeys: string[]
  }>(() => {
    if (!selectedTabState?.products || selectedTabState.products.length === 0)
      return { groupCounts: [], groupsItems: [], groupsKeys: [] }
    const groups = !selectedTabState.isSortingAz
      ? groupBy(selectedTabState.products, (p) => p.pageNumber)
      : {
          AZ: orderBy(
            selectedTabState.products ?? [],
            (p: MultiDeliveryFormProductState) => p.productName
          ),
        }
    const result = {
      groupCounts: Object.keys(groups).map((key) => groups[key].length),
      groupsItems: Object.keys(groups).flatMap((key) => groups[key]),
      groupsKeys: Object.keys(groups).map((key) => key),
    }
    return result
  }, [selectedTabState?.products, selectedTabState?.isSortingAz])

  const invoiceFileImages = useMemo(() => {
    const bookIn = state.bookIns[state.selectedTab!]
    if (bookIn && bookIn.invoicePages) {
      const invoiceType = bookIn.invoiceMimeType as InvoiceType
      if (invoiceType === InvoiceType.PDF) {
        return [
          {
            key: 'page-0',
            rawDownloadPath: `${bookIn.bookInId}_${
              bookIn.invoicePages![0].uploadId
            }.${invoiceTypeToExtension(invoiceType)}`,
            invoiceType,
          },
        ]
      }
    } else {
      return []
    }
  }, [state.bookIns, state.selectedTab])

  const hasCloseConfirmationModal = useMemo(() => {
    return (
      (state.editDeliveryMode === EditDeliveryModes.Analytics ||
        state.editDeliveryMode === EditDeliveryModes.MonthEndReconciliation) &&
      state.bookIns[state.selectedTab!]?.status === BookInStatuses.ReEdit
    )
  }, [state.editDeliveryMode, state.bookIns, state.selectedTab])

  const hasInvoiceImagesPane = useMemo(() => {
    //if there are no invoice pages, then the invoice images pane should not be shown
    if (
      (selectedTabState?.invoicePageItems?.invoicePageItemStates.length ??
        0) === 0
    ) {
      return false
    } else {
      if (
        [
          CreationMediums.SupplierFeed,
          CreationMediums.AutoDownloadedFeed,
        ].includes(state.bookIns[state.selectedTab!]?.creationMedium ?? '')
      ) {
        return (
          state.bookIns[state.selectedTab!]?.feedInvoiceProductsJson != null ||
          state.bookIns[state.selectedTab!]?.invoiceMimeType === InvoiceType.PDF
        )
      }
      return true
    }
  }, [selectedTabState, state.bookIns, state.selectedTab])

  useEffect(() => {
    if (user) {
      dispatch({
        type: 'SET_USER',
        payload: user,
      })
    }
  }, [user])

  useEffect(() => {
    if ((suppliersWithVat?.length ?? 0) > 0) {
      dispatch({
        type: 'SET_SUPPLIERS_FINANCIALS',
        payload: suppliersWithVat,
      })
    }
  }, [suppliersWithVat])

  const [globalError, setGlobalError] = useState<string | null>(null)
  useEffect(() => {
    const getBookIn = async (bookInId: string, odsCode: string) => {
      return await platformHttpService.getAsync<BookIn>(
        PlatformApiPaths.GetBookIn(odsCode, bookInId, false),
        'StockBaseUrl'
      )
    }
    const getBookIns = async () => {
      return await Promise.all(
        props.bookInsToLoad.map((bookInId) =>
          getBookIn(bookInId.bookInId, bookInId.odsCode)
        )
      )
    }
    if (state.suppliersWithFinancials.length > 0) {
      setIsLoading(true)
      getBookIns().then((responses) => {
        setIsLoading(false)
        if (responses.every((r) => !r.hasErrors && r.data)) {
          dispatch({
            type: 'SET_BOOKINS',
            payload: responses.map((r) => r.data!),
          })
        } else {
          const firstResponseWithError = responses.find((r) => r.hasErrors)
          setGlobalError(GetErrorMessage(firstResponseWithError?.statusCode))
        }
      })
    }
  }, [platformHttpService, props.bookInsToLoad, state.suppliersWithFinancials])

  useEffect(() => {
    if (state.shouldAutoClose) {
      onClosed(state)
    }
  }, [onClosed, state])

  const setError = (error: string | null) => {
    dispatch({
      type: 'SET_TAB_ERROR',
      payload: error,
    })
  }

  const handleProductStateUpdated = useCallback(
    (productState: MultiDeliveryFormProductState) => {
      dispatch({
        type: 'SET_PRODUCT_STATE',
        payload: productState,
      })
    },
    []
  )

  const disablePageScroll = useMemo(() => {
    if (
      state.bookIns[state.selectedTab!]?.creationMedium !==
      CreationMediums.SupplierFeed
    ) {
      return false
    }
    // count of invoice images
    const invoiceImagesCount =
      state.tabStates[state.selectedTab!]?.invoicePageItems
        ?.invoicePageItemStates.length ?? 0
    // pages count where  page is not manual additions page and has product items
    const pagesCount =
      state.bookIns[state.selectedTab!]?.invoicePages?.filter(
        (page) => !page.isManualAdditionsPage && page.productItems?.length !== 0
      ).length ?? 0
    // Covers the case when a single page feed was appended with possible multiple pages of pdf images
    return (
      invoiceImagesCount > pagesCount &&
      state.tabStates[state.selectedTab!]?.header
    )
  }, [state.bookIns, state.selectedTab, state.tabStates])

  const handleProductInteraction = useCallback(
    (productState: MultiDeliveryFormProductState) => {
      if (disablePageScroll) {
        return
      }
      invoiceImagesPaneRef.current?.selectPage(
        productState.bookInId,
        productState.pageNumber
      )
    },
    [disablePageScroll]
  )

  const handleProductRemoved = useCallback(
    (productState: MultiDeliveryFormProductState) => {
      dispatch({
        type: 'REMOVE_PRODUCT',
        payload: productState,
      })
    },
    []
  )

  const handleHeaderStateUpdated = useCallback(
    (headerState: InvoiceHeaderState) => {
      dispatch({
        type: 'SET_HEADER_STATE',
        payload: headerState,
      })
    },
    []
  )

  const handleInvoiceImagesPaneStateUpdated = useCallback(
    (imagesPaneState: InvoiceItemsPaneState) => {
      dispatch({
        type: 'SET_IMAGES_PANE_STATE',
        payload: imagesPaneState,
      })
    },
    []
  )

  const handleProductBusyStateChanged = useCallback((isBusy: boolean) => {
    dispatch({
      type: isBusy ? 'INCREASE_BUSY_PRODUCTS' : 'DECREASE_BUSY_PRODUCTS',
    })
  }, [])

  const handleDownloadInvoice = async (fileName: string) => {
    const response = await platformHttpService.getAsync<PresignedUrlResponse>(
      PlatformApiPaths.ReadPresignedUrl(
        state.bookIns[state.selectedTab!].odsCode,
        fileName
      ),
      'StockBaseUrl'
    )
    if (!response.hasErrors && response.data?.presignedUrl) {
      window.open(response.data.presignedUrl, '_blank', 'noreferrer')
    }
  }

  const handleAddProduct = async (
    productId: string,
    packSize: number,
    productName: string,
    unitOfMeasure: string | null,
    subPackDescription: string | null,
    amppId: string | null,
    hasAmpp?: boolean
  ) => {
    const bookIn = state.bookIns[state.selectedTab!]
    const payload = {
      bookInId: bookIn.bookInId,
      vmpName: productName,
      productUuid: productId,
      packSize,
      quantity: 0,
      unitPrice: 0,
      netPrice: 0,
      grossPrice: 0,
      verified: false,
      isReEdit: bookIn.status === BookInStatuses.ReEdit,
      vatRate: null,
      vatCode: null,
      unitOfMeasure,
      subPackDescription,
      amppId,
    }
    // setErrorButtonBar(null)
    setIsAddingProduct(true)
    const response = await platformHttpService.postAsync<{
      lineNumber: number
      pageNumber: number
    }>(
      PlatformApiPaths.CreateUpdateDeleteProduct(bookIn.odsCode),
      payload,
      'StockBaseUrl'
    )
    setIsAddingProduct(false)
    if (!response.hasErrors && response.data) {
      dispatch({
        type: 'ADD_PRODUCT',
        payload: {
          pageNumber: response.data.pageNumber,
          lineNumber: response.data.lineNumber,
          productId,
          packSize,
          productName,
          unitOfMeasure,
          subPackDescription,
          amppId,
          hasAmpp,
        },
      })
    } else {
      // setErrorButtonBar(GetErrorMessage(response?.statusCode))
    }
  }

  const handleCompleteDelivery = async (
    tabState: TabState,
    isMultiple: boolean = false
  ) => {
    const updateInvoice = async (tabState: TabState) => {
      return await platformHttpService.putAsync<void>(
        PlatformApiPaths.UpdateInvoice(tabState.odsCode!),
        {
          bookInId: tabState.bookInId!,
          invoiceNumber: tabState.header!.invoiceNumber,
          totalPriceWithTax: tabState.header!.initialTotalWithVat,
          totalPrice: tabState.header!.totalExVat,
          date: tabState.header!.invoiceDate!.toISODate(),
          isReEdit: tabState.header!.isReEdit,
        },
        'StockBaseUrl'
      )
    }
    const completeBookIn = async (tabState: TabState) => {
      return await platformHttpService.postAsync<void>(
        PlatformApiPaths.CompleteDelivery(
          tabState.odsCode!,
          tabState.bookInId!
        ),
        null,
        'StockBaseUrl'
      )
    }

    if (state.tabStates.some((s) => s.header?.isEditingInvoiceNumber)) {
      return
    }

    if (!isMultiple) {
      setIsCompleting(true)
    }
    setError(null)
    const updateResponse = await updateInvoice(tabState)
    if (!updateResponse.hasErrors) {
      const completeResponse = await completeBookIn(tabState)
      if (!completeResponse.hasErrors) {
        dispatch({
          type: 'SET_BOOKIN_COMPLETED',
          payload: tabState.bookInId!,
        })
      } else {
        setError(GetErrorMessage(completeResponse.statusCode))
      }
    } else {
      setError(GetErrorMessage(updateResponse.statusCode))
    }
    if (!isMultiple) {
      setIsCompleting(false)
    }
  }

  const handleCompleteAllDeliveries = async () => {
    const tabStates = state.tabStates
    setIsCompleting(true)
    for (const tabState of tabStates.filter((ts) => Boolean(ts.bookInId))) {
      await handleCompleteDelivery(tabState)
    }
    setIsCompleting(false)
  }

  const [isEnablingReEdit, setIsEnablingReEdit] = useState(false)
  const handleReEditDelivery = async (tabState: TabState) => {
    setIsEnablingReEdit(true)
    const response = await platformHttpService.postAsync(
      PlatformApiPaths.ReEditBookIn(
        tabState.odsCode!,
        tabState.bookInId!,
        props.editDeliveryMode
      ),
      null,
      'StockBaseUrl'
    )
    setIsEnablingReEdit(false)
    if (!response.hasErrors) {
      dispatch({
        type: 'SET_BOOKIN_REEDIT',
        payload: tabState.bookInId!,
      })
    } else {
      setError(GetErrorMessage(response.statusCode))
    }
  }

  const handleDeleteDelivery = async () => {
    setIsDeletingDelivery(true)
    const deleteInvoiceResponse = await platformHttpService.deleteAsync(
      PlatformApiPaths.DeleteBookIn(
        selectedTabState!.odsCode!,
        selectedTabState!.bookInId!,
        false
      ),
      null,
      'StockBaseUrl'
    )
    setIsDeletingDelivery(false)
    if (!deleteInvoiceResponse.hasErrors) {
      dispatch({
        type: 'REMOVE_DELIVERY',
        payload: selectedTabState!.bookInId!,
      })
    } else {
      setError(GetErrorMessage(deleteInvoiceResponse.statusCode))
    }
  }

  const handleImageLoaded = useCallback(
    (bookInId: string, pageNumber: number, base64: string) => {
      dispatch({
        type: 'SET_INVOICE_IMAGE_LOADED',
        payload: { bookInId, pageNumber, base64 },
      })
    },
    []
  )

  const tabHasHeaderValidationErrors = (tabState: TabState) =>
    (tabState.header?.validationErrors?.length ?? 0) > 0 ||
    (Boolean(tabState.header?.invoiceNumber) &&
      duplicateTabs.includes(tabState.header!.invoiceNumber!)) ||
    tabState.products?.length === 0

  const canCompleteBookInTab = (tabState: TabState | null) =>
    tabState &&
    (tabState.products.length ?? 0) > 0 &&
    tabState.products.every((p) => p.verified) &&
    !tabHasHeaderValidationErrors(tabState)

  const duplicateTabs = useMemo(() => {
    const groupedInvoiceNumbers = groupBy(
      state.tabStates
        .map((ts) => ts.header?.invoiceNumber)
        .filter((i) => Boolean(i))
    )
    return Object.keys(groupedInvoiceNumbers).filter(
      (k) => groupedInvoiceNumbers[k].length > 1
    )
  }, [state.tabStates])

  const renderTabsBar = () => {
    const tabsDisabled =
      state.tabStates.some((s) => s.header?.isEditingInvoiceNumber) ||
      state.busyProductsCount > 0 ||
      isAddingProduct ||
      isCompleting ||
      isDeletingDelivery ||
      isEnablingReEdit ||
      isLoading

    if (state.tabStates.length > 1) {
      return (
        <Tabs
          value={state.selectedTab}
          onChange={(event: React.SyntheticEvent, newValue: number) => {
            dispatch({
              type: 'SET_TAB_INVOICE_IMAGES_PANE_SCROLL_TOP',
              payload: invoiceImagesPaneRef.current?.getCurrentScrollTop() ?? 0,
            })
            dispatch({ type: 'SET_SELECTED_TAB', payload: newValue })
          }}
          variant="scrollable"
          scrollButtons="auto"
          sx={{ borderBottom: 1, borderColor: 'divider' }}
          TabIndicatorProps={{
            style: {
              backgroundColor: tabHasHeaderValidationErrors(
                state.tabStates[state.selectedTab!]
              )
                ? theme.palette.error.main
                : theme.palette.primary.main,
            },
          }}
        >
          {state.tabStates.map((tab) => {
            return tab.header ? (
              <Tab
                key={tab.bookInId}
                label={
                  <span
                    style={{
                      color: tabHasHeaderValidationErrors(tab)
                        ? theme.palette.error.main
                        : theme.palette.primary.main,
                    }}
                  >
                    {tab.header!.invoiceNumber || translation.Unspecified}
                  </span>
                }
                disabled={tabsDisabled}
                data-testid="bookin-tab"
              />
            ) : (
              <Tab
                key={'SRX_AGGREGATED_PRODUCTS'}
                label={translation.AggregatedProducts}
                disabled={tabsDisabled}
                sx={{
                  color: theme.palette.primary.main,
                }}
                data-testid="aggregated-products-tab"
              />
            )
          })}
        </Tabs>
      )
    } else {
      return <Box></Box>
    }
  }

  const renderProductGroupHeader = (pageNumber: string, groupIndex: number) => {
    const isAz = pageNumber === 'AZ'
    const isManualPage =
      !isAz &&
      selectedTabState?.products?.find(
        (p) => p.pageNumber === parseInt(pageNumber)
      )?.manuallyCreated
    return (
      <ButtonBase
        data-testid="invoice-page-number-button"
        key={`invoicepage-${pageNumber}`}
        sx={{
          width: '100%',
          marginBottom: theme.spacing(1),
          backgroundColor: theme.palette.common.white,
        }}
        disabled={isAz || isManualPage}
        onClick={() =>
          invoiceImagesPaneRef.current?.selectPage(
            selectedTabState!.bookInId!,
            parseInt(pageNumber)
          )
        }
      >
        <Typography
          variant="button"
          sx={{
            textAlign: 'center',
            cursor: 'pointer',
            fontWeight: 'medium',
          }}
        >
          {isAz
            ? translation.AlphaBetaHeader
            : isManualPage
            ? translation.ManuallyAddedProducts
            : `${translation.InvoicePage} ${pageNumber}`}
        </Typography>
      </ButtonBase>
    )
  }

  const renderProductRow = (productState: MultiDeliveryFormProductState) => {
    return (
      <MultiDeliveryFormProduct
        key={`${productState.bookInId}-${productState.pageNumber}-${productState.lineNumber}-${productState.editingDisabled}`}
        initialState={{
          ...productState,
        }}
        onStateUpdated={handleProductStateUpdated}
        onInteraction={handleProductInteraction}
        onProductRemoved={handleProductRemoved}
        onBusyStateChanged={handleProductBusyStateChanged}
      />
    )
  }

  const renderFilesAndSortButtons = () => {
    const bookIn = state.bookIns[state.selectedTab!]
    if (!bookIn) return null
    const invoicePages = bookIn.invoicePages ?? []

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {bookIn.creationMedium === CreationMediums.InternalSupplierBatch ||
        (bookIn.invoiceMimeType as InvoiceType) === InvoiceType.PDF ? (
          <Button
            data-testid="invoice-images-button"
            onClick={() => {
              dispatch({
                type: 'SET_PDF_DOWNLOAD_BUTTON_STATE',
                payload: !selectedTabState?.isPdfDownloadButtonOpen,
              })
            }}
            sx={{ alignSelf: 'flex-start' }}
          >
            {translation.PdfInvoice}
          </Button>
        ) : (
          <Typography
            variant="button"
            sx={{
              paddingLeft: theme.spacing(1),
              marginBottom: theme.spacing(0.5),
            }}
          >
            {translation.NInvoicePages(
              invoicePages.filter((p) => !p.isManualAdditionsPage).length
            )}
          </Typography>
        )}
        {(selectedTabState?.products?.length ?? 0) > 1 && (
          <Button
            variant={selectedTabState?.isSortingAz ? 'contained' : 'outlined'}
            color="primary"
            size="small"
            data-testid="sort-products-button"
            onClick={() =>
              dispatch({
                type: 'SET_SORTING_AZ',
                payload: !selectedTabState?.isSortingAz,
              })
            }
            sx={{
              padding: theme.spacing(0),
              paddingLeft: theme.spacing(0.5),
              minWidth: 'unset',
              minHeight: '3ch',
            }}
          >
            <Typography
              variant="body1"
              sx={{ cursor: 'inherit', fontSize: '.9em' }}
            >
              {translation.AlphaBeticalOrder}
            </Typography>
            <ArrowDownwardIcon sx={{ fontSize: '1.0em', cursor: 'inherit' }} />
          </Button>
        )}
      </Box>
    )
  }

  const renderInvoicePageImages = () => {
    return (selectedTabState?.invoicePageItems?.invoicePageItemStates?.length ??
      0) > 0 ? (
      <InvoiceItemsPane
        key={selectedTabState?.bookInId}
        initialState={selectedTabState!.invoicePageItems!}
        ref={invoiceImagesPaneRef}
        onStateUpdated={handleInvoiceImagesPaneStateUpdated}
        onImageLoaded={handleImageLoaded}
      />
    ) : null
  }

  const renderMultipleSuppliersSelectedWarning = () => {
    return state.isShowingMultipleSuppliers ? (
      <Alert
        severity="warning"
        variant="filled"
        sx={{
          marginBottom: theme.spacing(1),
          paddingRight: theme.spacing(2),
          marginRight: theme.spacing(2),
        }}
      >
        <Markdown value={translation.WarningMultipleSuppliersBookIn} />
      </Alert>
    ) : null
  }

  const renderInvoiceFilesPane = () => {
    const bookIn = state.bookIns[state.selectedTab!]
    if (bookIn) {
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: theme.spacing(0.5),
            paddingX: theme.spacing(1),
          }}
        >
          {selectedTabState?.isPdfDownloadButtonOpen && (
            <Box
              sx={{
                minHeight: '120px',
                width: '100%',
                overflowX: 'auto',
                overflowY: 'hidden',
                paddingBottom: theme.spacing(1),
                display: selectedTabState?.isPdfDownloadButtonOpen
                  ? 'flex'
                  : 'none',
              }}
            >
              {invoiceFileImages?.map((file) => (
                <InvoiceImageButton
                  key={file.key}
                  fileName={file.rawDownloadPath}
                  invoiceType={file.invoiceType}
                  onClickedDownload={handleDownloadInvoice}
                />
              ))}
            </Box>
          )}
        </Box>
      )
    }
    return null
  }

  const renderFooterMessages = () => {
    const selectedBookIn = state.bookIns[state.selectedTab!]
    return (
      <Box sx={{ marginTop: theme.spacing(1) }}>
        {selectedBookIn?.status !== BookInStatuses.Completed &&
          cannotDeleteActionedCredits && (
            <Alert
              variant="filled"
              severity="warning"
              sx={{ marginTop: theme.spacing(1) }}
            >
              {translation.ActionedOrReceivedCreditAlert}
            </Alert>
          )}
        {cannotEditBhs && (
          <Alert variant="filled" severity="info">
            <Markdown value={translation.DisableEditAlertHealthscore} />
          </Alert>
        )}
        {state.editDeliveryMode === EditDeliveryModes.PendingDeliveries &&
          selectedTabState?.scanningMandatory && (
            <Alert variant="filled" severity="info">
              <Markdown value={translation.DisableEditAlertMandatoryScanning} />
            </Alert>
          )}
        {selectedTabState?.error && (
          <Alert
            variant="filled"
            severity="error"
            sx={{
              flexGrow: 1,
            }}
          >
            {selectedTabState?.error}
          </Alert>
        )}
        {state.tabStates.some((ts) => tabHasHeaderValidationErrors(ts)) &&
          selectedTabState?.bookInId === null && (
            <Alert
              severity="error"
              variant="filled"
              sx={{ marginTop: theme.spacing(1) }}
            >
              <Markdown value={translation.ErrorAggregatedProducts}></Markdown>
            </Alert>
          )}
        {state.selectedTab != null &&
          state.bookIns[state.selectedTab]?.status ===
            BookInStatuses.ReEdit && (
            <Alert
              severity="warning"
              variant="filled"
              sx={{ marginTop: theme.spacing(1) }}
            >
              <Markdown value={translation.WarningAnalyticsEdit} />
            </Alert>
          )}
      </Box>
    )
  }

  const [isCompleting, setIsCompleting] = useState(false)
  const [isAddingProduct, setIsAddingProduct] = useState(false)
  const [isDeletingDelivery, setIsDeletingDelivery] = useState(false)
  const renderFormButtons = () => {
    if (cannotEditBhs) return null

    const bookIn = state.bookIns[state.selectedTab!]
    const canComplete = canCompleteBookInTab(selectedTabState)

    return (
      <Box
        component={'footer'}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: theme.spacing(1),
          height: '32px',
          marginTop: theme.spacing(1),
        }}
      >
        {bookIn && bookIn.status !== BookInStatuses.Completed && (
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexGrow: 1,
                justifyContent: 'flex-start',
                gap: theme.spacing(1),
                minWidth: '140px',
              }}
            >
              <LoadingButton
                data-testid="add-product-button"
                disableElevation
                variant="contained"
                loading={isAddingProduct}
                fullWidth
                color="secondary"
                sx={{
                  maxWidth: '200px',
                }}
                onClick={() => setAddProductOpen(true)}
                disabled={
                  isCompleting ||
                  isDeletingDelivery ||
                  selectedTabState?.scanningMandatory
                }
              >
                {translation.AddProduct}
              </LoadingButton>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                gap: theme.spacing(1),
                flexGrow: 1,
              }}
            >
              <LoadingButton
                data-testid="delete-delivery-button"
                disableElevation
                variant="contained"
                color="error"
                loading={isDeletingDelivery}
                onClick={() => setShowDeleteDelivery(true)}
                disabled={
                  isCompleting || isAddingProduct || cannotDeleteActionedCredits
                }
                sx={{
                  minWidth: '160px',
                }}
              >
                {translation.DeleteDelivery}
              </LoadingButton>
              <LoadingButton
                data-testid="complete-delivery-button"
                disableElevation
                variant="contained"
                color="success"
                loading={isCompleting}
                onClick={async () =>
                  await handleCompleteDelivery(
                    state.tabStates[state.selectedTab!]
                  )
                }
                disabled={
                  !canComplete ||
                  isDeletingDelivery ||
                  selectedTabState?.scanningMandatory
                }
                sx={{
                  minWidth: '160px',
                }}
              >
                {bookIn.status === BookInStatuses.ReEdit
                  ? state.editDeliveryMode === EditDeliveryModes.HealthScore
                    ? translation.FinaliseEditsHealthscore
                    : translation.FinaliseEdits
                  : translation.Complete}
              </LoadingButton>
            </Box>
          </>
        )}
        {bookIn && bookIn.status === BookInStatuses.Completed && (
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              gap: theme.spacing(1),
            }}
          >
            <LoadingButton
              data-testid="reedit-delivery-button"
              disableElevation
              variant="contained"
              color="secondary"
              onClick={() =>
                handleReEditDelivery(state.tabStates[state.selectedTab!])
              }
              loading={isEnablingReEdit}
              disabled={
                state.editDeliveryMode === EditDeliveryModes.HealthScore &&
                bookIn.creationMedium === CreationMediums.InternalSupplierBatch
              }
            >
              {state.editDeliveryMode === EditDeliveryModes.HealthScore
                ? translation.ReEditHealthscore
                : translation.ReEdit}
            </LoadingButton>
          </Box>
        )}
        {!bookIn && (
          <>
            <Box></Box>
            <LoadingButton
              data-testid="complete-all-deliveries-button"
              disableElevation
              variant="contained"
              color="success"
              disabled={
                state.tabStates[state.selectedTab!].products.some(
                  (p) => !p.verified
                ) ||
                state.tabStates.some((ts) =>
                  tabHasHeaderValidationErrors(ts)
                ) ||
                state.tabStates.some((ts) => !canCompleteBookInTab(ts))
              }
              loading={isCompleting}
              onClick={async () => await handleCompleteAllDeliveries()}
              sx={{
                minWidth: '160px',
                justifySelf: 'flex-end',
              }}
            >
              {translation.CompleteAllDeliveries}
            </LoadingButton>
          </>
        )}
      </Box>
    )
  }

  const renderInvoiceHeader = () => {
    if (selectedTabState?.header) {
      const duplicateState =
        selectedTabState?.header?.duplicateInvoiceNumberState
      const duplicateUi =
        duplicateState === 'COMPLETED' ? (
          <Alert
            variant="filled"
            severity="error"
            sx={{
              marginTop: theme.spacing(1),
              marginLeft: hasInvoiceImagesPane ? theme.spacing(2) : 0,
            }}
          >
            <Markdown value={translation.ErrorDuplicateInvoiceNumber} />
          </Alert>
        ) : duplicateState === 'PENDING' ? (
          <Alert
            variant="filled"
            severity="warning"
            sx={{
              marginTop: theme.spacing(1),
              marginLeft: hasInvoiceImagesPane ? theme.spacing(2) : 0,
            }}
          >
            <Markdown value={translation.WarningDuplicateInvoiceNumber} />
          </Alert>
        ) : null
      const emptyInvoiceUi =
        selectedTabState?.products?.length === 0 ? (
          <Alert
            severity="error"
            variant="filled"
            sx={{ marginTop: theme.spacing(1) }}
          >
            <Markdown value={translation.ErrorEmptyInvoice}></Markdown>
          </Alert>
        ) : null
      return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: theme.spacing(2),
          }}
        >
          <InvoiceHeader
            key={`${selectedTabState.bookInId}-${selectedTabState.header?.editingDisabled}`}
            initialState={selectedTabState.header}
            onStateUpdated={handleHeaderStateUpdated}
          />
          {duplicateUi}
          {emptyInvoiceUi}
        </Box>
      )
    }
    return null
  }

  const renderHealthScorePenalties = () => {
    return state.editDeliveryMode === EditDeliveryModes.HealthScore &&
      selectedTabState?.healthScorePenalties ? (
      <Box
        sx={{
          marginBottom: theme.spacing(2),
          maxHeight: '7em',
          overflowY: 'auto',
          flex: '1 0 auto',
        }}
      >
        {Object.entries(selectedTabState.healthScorePenalties).map(
          ([key], index) => (
            <Alert
              key={`BHS_I_${index}`}
              severity="warning"
              variant="filled"
              sx={{ minHeight: '2em', marginBottom: theme.spacing(0.5) }}
            >
              <Markdown>{translation.HealthscorePenalties[key]}</Markdown>
            </Alert>
          )
        )}
      </Box>
    ) : null
  }

  const transition = useTransition()
  const renderEditDeliveryForm = () => {
    return selectedTabState ? (
      <Box
        key={selectedTabState.bookInId}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          paddingLeft: hasInvoiceImagesPane ? theme.spacing(2) : 0,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            marginLeft: theme.spacing(1),
            color:
              state?.isShowingMultipleSuppliers && !selectedTabState?.bookInId
                ? theme.palette.warning.main
                : theme.palette.text.primary,
          }}
        >
          {!selectedTabState?.bookInId
            ? state?.isShowingMultipleSuppliers
              ? translation.MultipleSuppliers
              : selectedTabState?.supplierName
            : selectedTabState?.supplierName}
        </Typography>

        {renderFilesAndSortButtons()}
        {renderInvoiceFilesPane()}
        {renderHealthScorePenalties()}
        {renderInvoiceHeader()}

        {/* Products */}
        <GroupedVirtuoso
          ref={virtuosoRef}
          groupCounts={virtuosoGroups.groupCounts}
          groupContent={(groupIndex) =>
            renderProductGroupHeader(
              virtuosoGroups.groupsKeys[groupIndex],
              groupIndex
            )
          }
          itemContent={(itemIndex, groupIndex) =>
            renderProductRow(virtuosoGroups.groupsItems[itemIndex])
          }
          rangeChanged={() => {
            if (virtuosoRef.current) {
              virtuosoRef.current.getState((s) => {
                transition[1](() => {
                  setTimeout(() =>
                    dispatch({
                      type: 'SET_VIRTUOSO_STATE',
                      payload: s,
                    })
                  )
                })
              })
            }
          }}
          restoreStateFrom={selectedTabState.virtuosoState}
        />
        {renderFooterMessages()}
        {renderFormButtons()}
      </Box>
    ) : null
  }

  const [modalAnimationFinished, setModalAnimationFinished] = useState(false)
  const renderSelectedTab = () => {
    if (selectedTabState && modalAnimationFinished) {
      return (
        <AutoSizingBox>
          {hasInvoiceImagesPane && (
            <SplitPane
              split="vertical"
              sizes={splitPaneSizes}
              onChange={setSplitPaneSizes}
              sashRender={(i, a) => {
                return (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '12px',
                      height: '100%',
                      backgroundColor: theme.palette.grey[100],
                    }}
                  >
                    <DragIndicatorIcon sx={{ fontSize: '12pt' }} />
                  </Box>
                )
              }}
              style={{
                height: '100%',
              }}
            >
              {/* Invoice Images */}
              <Pane minSize={'460px'}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    width: '100%',
                    height: '100%',
                    padding: theme.spacing(1),
                    borderRight: `2px solid ${theme.palette.divider}`,
                  }}
                >
                  {renderInvoicePageImages()}
                </Box>
              </Pane>
              {/* Edit Delivery Form */}
              <Pane
                minSize={'480px'}
                maxSize={'1000px'}
                // style={{ marginLeft: theme.spacing(1.5) }}
              >
                {renderEditDeliveryForm()}
              </Pane>
            </SplitPane>
          )}
          {!hasInvoiceImagesPane && renderEditDeliveryForm()}
        </AutoSizingBox>
      )
    }
  }

  const renderAddProductDialog = () => {
    return addProductOpen ? (
      <SelectProductModal
        product={{
          packSize: null,
          productId: null,
          productName: '',
          productRawOcrName: null,
          amppId: null,
          hasAmpp: false,
        }}
        onClosedCallback={async (selectedProduct) => {
          setAddProductOpen(false)
          if (selectedProduct) {
            await handleAddProduct(
              selectedProduct.productId,
              selectedProduct.packSize,
              selectedProduct.productName,
              selectedProduct.unitOfMeasure,
              selectedProduct.subPackDescription,
              selectedProduct.ampp,
              selectedProduct.hasAmpp
            )
          }
        }}
        ignoreSpecials={false}
        showUnlistedProduct={true}
      />
    ) : null
  }

  const renderCloseConfirmationDialog = () => {
    return state.isCloseConfirmationModalOpen ? (
      <ConfirmDialog
        okText={translation.MultiDeliveryFormCloseConfirmaionModal.ConfirmText}
        cancelText={
          translation.MultiDeliveryFormCloseConfirmaionModal.CancelText
        }
        title={translation.MultiDeliveryFormCloseConfirmaionModal.Title}
        text={
          state.editDeliveryMode === EditDeliveryModes.MonthEndReconciliation
            ? translation.MultiDeliveryFormCloseConfirmaionModal.TextMER
            : translation.MultiDeliveryFormCloseConfirmaionModal.TextAnalytics
        }
        isCancelPrimary={false}
        onOk={() => {
          dispatch({
            type: 'SET_IS_CLOSE_CONFIRMATION_MODAL_OPEN',
            payload: false,
          })
        }}
        onCancel={() => {
          onClosed(state)
          dispatch({
            type: 'SET_IS_CLOSE_CONFIRMATION_MODAL_OPEN',
            payload: false,
          })
        }}
        disableBackdropClick={true}
      />
    ) : null
  }

  const [showDeleteDelivery, setShowDeleteDelivery] = useState(false)
  const renderConfirmRemoveDeliveryDialog = () => {
    return showDeleteDelivery ? (
      <ConfirmDialog
        okText={translation.DeleteDeliveryOkText}
        cancelText={translation.DeleteDeliveryCancelText}
        title={translation.DeleteDeliveryDialogTitle}
        text={translation.DeleteDeliveryDialogText(
          selectedTabState?.supplierName
        )}
        isCancelPrimary={true}
        onOk={async () => {
          setShowDeleteDelivery(false)
          await handleDeleteDelivery()
        }}
        onCancel={() => {
          setShowDeleteDelivery(false)
        }}
      />
    ) : null
  }

  return (
    <>
      <ModalContainer
        open={true}
        onClickedClose={() => {
          hasCloseConfirmationModal
            ? dispatch({
                type: 'SET_IS_CLOSE_CONFIRMATION_MODAL_OPEN',
                payload: true,
              })
            : onClosed(state)
        }}
        alignSelf={hasInvoiceImagesPane ? 'stretch' : 'center'}
        sx={{ minWidth: '1024px' }}
        isLoading={isLoading}
        isNestedInModal={
          state.editDeliveryMode === EditDeliveryModes.Analytics ||
          state.editDeliveryMode === EditDeliveryModes.MonthEndReconciliation
        }
        onOpeningAnimationEnd={() => {
          setModalAnimationFinished(true)
        }}
      >
        <Box
          sx={{
            display: 'flex',
            marginTop: '-24px',
            flexDirection: 'column',
            flex: '1 1 auto',
          }}
        >
          {isLoading && <CircularProgress size="24px" />}
          {globalError && (
            <Alert variant="filled" severity="error" sx={{}}>
              {globalError}
            </Alert>
          )}
          {/* Loading, Supplier name, Tabs bar  */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box>{renderMultipleSuppliersSelectedWarning()}</Box>
            <Box sx={{ display: 'block', maxWidth: '1000px' }}>
              {renderTabsBar()}
            </Box>
          </Box>

          {/* Tab Content */}
          {renderSelectedTab()}
        </Box>
      </ModalContainer>
      {renderAddProductDialog()}
      {renderConfirmRemoveDeliveryDialog()}
      {renderCloseConfirmationDialog()}
    </>
  )
}

export default MultiDeliveryForm
