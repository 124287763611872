import Box from '@mui/material/Box'
import { FC, useContext, useReducer, useRef, useState } from 'react'
import theme from '../../../styles/theme'
import TextField from '@mui/material/TextField'
import { SxProps, Theme } from '@mui/material/styles'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { locale } from '../../../locales'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import CurrencyInput from '../../../components/Forms/CurrencyInput'
import * as yup from 'yup'
import { DateTime } from 'luxon'
import { useStateUpdatedCallback } from '../../../hooks/useStateUpdatedCallback'
import Button from '@mui/material/Button'
import { ServiceContext } from '../../../providers/ServicesProvider'
import { PlatformApiPaths } from '../../../PlatformApiPaths'
import CircularProgress from '@mui/material/CircularProgress'

const translation = locale.translation.GoodsInPage.MultiDeliveryForm

const editFieldTextFieldStyle: SxProps<Theme> = {
  maxWidth: '18ch',
  '& .MuiInputBase-input.Mui-disabled': {
    backgroundColor: theme.palette.grey[50],
    WebkitTextFillColor: theme.palette.grey[800],
  },
}

export interface InvoiceHeaderState {
  bookInId: string
  supplierId: string | null
  odsCode: string
  isReEdit: boolean
  invoiceNumber: string | null
  initialInvoiceNumber: string | null
  invoiceDate: DateTime | null
  initialInvoiceDate: DateTime | null
  totalExVat: number | null
  initialTotalExVat: number | null
  totalWithVat: number | null
  initialTotalWithVat: number | null
  editingDisabled: boolean
  showGrossPrice: boolean
  validationErrors?: string[]
  showSaveButton?: boolean
  duplicateInvoiceNumberState?: string | null
  isEditingInvoiceNumber?: boolean
}

const validationSchema = yup.object().shape({
  invoiceNumber: yup
    .string()
    .nullable()
    .required('INVOICE_NUMBER')
    .when(
      ['duplicateInvoiceNumberState'],
      ([duplicateInvoiceNumberState], schema) => {
        return duplicateInvoiceNumberState === 'COMPLETED'
          ? schema.test('unique', 'INVOICE_NUMBER', () => false)
          : schema
      }
    ),
  invoiceDate: yup
    .string()
    .nullable()
    .required('INVOICE_DATE')
    .test('valid-date', 'INVOICE_DATE', (dt) => {
      if (!dt) {
        return false
      }
      return DateTime.fromISO(dt).isValid
    }),
  totalExVat: yup.number().nullable().required('TOTAL_EX_VAT'),
})

export const validateInvoiceHeaderState = (
  state: InvoiceHeaderState
): string[] => {
  try {
    validationSchema.validateSync(state, { abortEarly: false })
    return []
  } catch (err: any) {
    return err.errors
  }
}

type SetIsEditingInvoiceNumberAction = {
  type: 'SET_IS_EDITING_INVOICE_NUMBER'
  payload: boolean
}
type SetInvoiceNumberAction = {
  type: 'SET_INVOICE_NUMBER'
  payload: string | null
}

type SetInvoiceDateAction = {
  type: 'SET_INVOICE_DATE'
  payload: DateTime | null
}

type SetTotalExVatAction = {
  type: 'SET_TOTAL_EX_VAT'
  payload: number | null
}

type SetTotalWithVatAction = {
  type: 'SET_TOTAL_WITH_VAT'
  payload: number | null
}

type ResetInitials = {
  type: 'RESET_INITIALS'
}

type SetDuplicateInvoiceError = {
  type: 'SET_DUPLICATE_INVOICE_ERROR'
  payload: string | null
}

const reducer = (
  state: InvoiceHeaderState,
  action:
    | SetInvoiceNumberAction
    | SetInvoiceDateAction
    | SetTotalExVatAction
    | SetTotalWithVatAction
    | ResetInitials
    | SetDuplicateInvoiceError
    | SetIsEditingInvoiceNumberAction
): InvoiceHeaderState => {
  let nextState: InvoiceHeaderState = state
  switch (action.type) {
    case 'SET_IS_EDITING_INVOICE_NUMBER':
      nextState = {
        ...state,
        isEditingInvoiceNumber: action.payload,
      }
      break
    case 'SET_INVOICE_NUMBER':
      nextState = {
        ...state,
        invoiceNumber: action.payload === '' ? null : action.payload,
      }
      break
    case 'SET_INVOICE_DATE':
      nextState = { ...state, invoiceDate: action.payload }
      break
    case 'SET_TOTAL_EX_VAT':
      nextState = { ...state, totalExVat: action.payload }
      break
    case 'SET_TOTAL_WITH_VAT':
      nextState = { ...state, totalWithVat: action.payload }
      break
    case 'RESET_INITIALS':
      nextState = {
        ...state,
        initialInvoiceDate: state.invoiceDate,
        initialInvoiceNumber: state.invoiceNumber,
        initialTotalExVat: state.totalExVat,
        initialTotalWithVat: state.totalWithVat,
      }
      break
    case 'SET_DUPLICATE_INVOICE_ERROR':
      nextState = {
        ...state,
        duplicateInvoiceNumberState: action.payload,
      }
      break
  }

  return {
    ...nextState,
    validationErrors: validateInvoiceHeaderState(nextState),
  }
}

const InvoiceHeader: FC<{
  initialState: InvoiceHeaderState
  onStateUpdated: (state: InvoiceHeaderState) => void
}> = ({ initialState, onStateUpdated }) => {
  const { platformHttpService } = useContext(ServiceContext)
  const [state, dispatch] = useReducer(reducer, initialState)
  const lastUniqueInvoiceNumberSearched = useRef<string | null>(
    initialState.invoiceNumber
  )
  useStateUpdatedCallback(state, onStateUpdated)

  const isDirty =
    state.initialInvoiceDate !== state.invoiceDate ||
    state.initialInvoiceNumber !== state.invoiceNumber ||
    state.initialTotalExVat !== state.totalExVat ||
    state.initialTotalWithVat !== state.totalWithVat

  const handleUpdateInvoice = async () => {
    if (state.isEditingInvoiceNumber) {
      return
    }
    const response = await platformHttpService.putAsync<void>(
      PlatformApiPaths.UpdateInvoice(state.odsCode),
      {
        bookInId: state.bookInId,
        invoiceNumber: state.invoiceNumber,
        totalPriceWithTax: state.showGrossPrice ? state.totalWithVat : null,
        totalPrice: state.totalExVat,
        date: state.invoiceDate!.toISODate(),
        isReEdit: state.isReEdit,
      },
      'StockBaseUrl'
    )
    if (!response.hasErrors) {
      dispatch({ type: 'RESET_INITIALS' })
    } else {
      if (
        response.errors.length > 0 &&
        response.errors[0].message ===
          'ERROR_CANNOT_UPDATE_INVOICE_NUMBER_IN_USE_FOR_SUPPLIER'
      ) {
        dispatch({ type: 'SET_DUPLICATE_INVOICE_ERROR', payload: 'COMPLETED' })
      }
    }
  }

  const [isCheckingInvoiceNumber, setIsCheckingInvoiceNumber] = useState(false)
  const handleInvoiceNumberBlur = async () => {
    if (state.invoiceNumber != null && state.supplierId) {
      if (state.invoiceNumber === lastUniqueInvoiceNumberSearched.current) {
        dispatch({ type: 'SET_IS_EDITING_INVOICE_NUMBER', payload: false })
        return
      }
      setIsCheckingInvoiceNumber(true)
      const response = await platformHttpService.getAsync<{
        duplicateBookInState: string | null
        isUnique: boolean
      }>(
        PlatformApiPaths.GetInvoiceNumberUnique(
          state.odsCode,
          state.bookInId,
          state.supplierId,
          state.invoiceNumber
        ),
        'StockBaseUrl'
      )
      setIsCheckingInvoiceNumber(false)
      if (response.hasErrors) {
        // setError(GetErrorMessage(response.statusCode))
        return
      }
      if (response.data) {
        lastUniqueInvoiceNumberSearched.current = state.invoiceNumber
        dispatch({
          type: 'SET_DUPLICATE_INVOICE_ERROR',
          payload: response.data.duplicateBookInState ?? null,
        })
      }
    }
    dispatch({ type: 'SET_IS_EDITING_INVOICE_NUMBER', payload: false })
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(1),
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <TextField
        variant="outlined"
        value={state.invoiceNumber ?? ''}
        name="invoiceNumber"
        onChange={(e) => {
          dispatch({ type: 'SET_INVOICE_NUMBER', payload: e.target.value })
        }}
        onFocus={() => {
          dispatch({ type: 'SET_IS_EDITING_INVOICE_NUMBER', payload: true })
        }}
        onBlur={handleInvoiceNumberBlur}
        label={translation.InvoiceNumber}
        size="small"
        sx={{ ...editFieldTextFieldStyle }}
        InputProps={{
          sx: { '& input': { textAlign: 'right' } },
        }}
        error={state.validationErrors?.includes('INVOICE_NUMBER')}
        disabled={state.editingDisabled}
        data-testid="invoice-number-field"
      />
      {isCheckingInvoiceNumber && (
        <CircularProgress size={20} sx={{ alignSelf: 'center' }} />
      )}

      <LocalizationProvider
        dateAdapter={AdapterLuxon}
        adapterLocale={locale.code}
      >
        <DatePicker
          label={translation.InvoiceDate}
          value={state.invoiceDate ?? ''}
          onChange={(v) => {
            dispatch({ type: 'SET_INVOICE_DATE', payload: v as DateTime })
          }}
          renderInput={(params: any) => (
            <TextField
              {...params}
              size="small"
              sx={{
                ...editFieldTextFieldStyle,
                '& input': { textAlign: 'right' },
              }}
            />
          )}
          disabled={state.editingDisabled}
          data-testid="invoice-date-field"
        />
      </LocalizationProvider>
      <CurrencyInput
        variant="outlined"
        value={state.totalExVat}
        onValueChangedCallback={(v) => {
          dispatch({ type: 'SET_TOTAL_EX_VAT', payload: v })
        }}
        label={translation.TotalExVat}
        sx={{ ...editFieldTextFieldStyle }}
        size="small"
        disabled={state.editingDisabled}
        data-testid="invoice-total-field"
        error={state.validationErrors?.includes('TOTAL_EX_VAT')}
      />
      {state.showGrossPrice && (
        <CurrencyInput
          variant="outlined"
          value={state.totalWithVat}
          onValueChangedCallback={(v) => {
            dispatch({ type: 'SET_TOTAL_WITH_VAT', payload: v })
          }}
          label={translation.TotalWithVat}
          sx={{ ...editFieldTextFieldStyle }}
          size="small"
          disabled={state.editingDisabled}
          data-testid="invoice-total-with-vat-field"
        />
      )}
      {isDirty && (state.validationErrors?.length ?? 0) === 0 && (
        <Button
          data-testid="save-changes-button"
          onClick={async () => {
            await handleUpdateInvoice()
          }}
        >
          {translation.SaveChanges}
        </Button>
      )}
    </Box>
  )
}

export default InvoiceHeader
