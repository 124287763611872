import { FC, useContext, useState } from 'react'
import ModalDialog from '../../../components/Interactions/ModalDialog'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import FileUpload from '../../../components/Interactions/FileUpload'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import { locale } from '../../../locales'
import { Accept, FileRejection } from 'react-dropzone'
import { SupplierUniquePerId } from '../../../types/entities/Supplier'
import theme from '../../../styles/theme'
import { useSuppliersWithFinancials } from '../../../hooks/useSuppliers'
import { ServiceContext } from '../../../providers/ServicesProvider'
import { PlatformApiPaths } from '../../../PlatformApiPaths'
import { ClientSelection } from '../../../types/entities/ClientPermission'
import DateRangePickerPopover from '../../../components/Forms/DateRangePickers'
import { DateTime } from 'luxon'
import { PriceFileRequestUploadResponse } from '../price-file-entities'
import { PriceFileUploadsStates } from '../../../constants'
import { UploadedPriceFile } from '../price-file-entities'

const translation =
  locale.translation.PriceFilesPage.TabUploads.PriceFileUploadPopup
const acceptedFileTypes: Accept = {
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
  ],
  'text/csv': ['.csv'],
  'application/vnd.ms-excel': ['.xls'],
}

const PriceFileUploadPopup: FC<{
  selectedClient: ClientSelection | null
  onClose: () => void
  onProcessedSuccess: (newPriceFile: UploadedPriceFile) => void
}> = ({ selectedClient, onClose, onProcessedSuccess }) => {
  const { platformHttpService, baseHttpService } = useContext(ServiceContext)
  const { priceFilesSuppliers } = useSuppliersWithFinancials()
  const [fileError, setFileError] = useState<string | null>(null)
  const [acceptedFile, setAcceptedFile] = useState<File | null>(null)
  const [selectedSupplier, setSelectedSupplier] =
    useState<SupplierUniquePerId | null>(null)
  const [isProcessing, setIsProcessing] = useState(false)
  const [fileDateRange, setFileDateRange] = useState<{
    dateFrom: DateTime | null
    dateTo: DateTime | null
  }>({
    dateFrom: null,
    dateTo: null,
  })

  const handleAcceptedFiles = (files: File[]) => {
    if (files.length > 1) {
      setFileErrorAndClearAcceptedFile(
        translation.ErrorCannotAcceptMultipleFiles
      )
    } else if (files.length === 1) {
      const file = files[0]
      if (acceptedFileTypes[file.type]) {
        setFileError(null)
        setAcceptedFile(file)
      } else {
        const er = translation.ErrorNotAValidFile(file.name, 'EXCEL OR CSV')
        setFileErrorAndClearAcceptedFile(er)
      }
    }
  }

  const handleRejectedFiles = (files: FileRejection[]) => {
    if (files.length > 1) {
      setFileErrorAndClearAcceptedFile(
        translation.ErrorCannotAcceptMultipleFiles
      )
    } else if (files.length === 1) {
      setFileErrorAndClearAcceptedFile(
        translation.ErrorNotAValidFile(files[0].file.name, 'EXCEL OR CSV')
      )
    }
  }

  const setFileErrorAndClearAcceptedFile = (error: string) => {
    setFileError(error)
    setAcceptedFile(null)
  }

  const handleSelectSupplier = (e: SelectChangeEvent<string>) => {
    const supplier = priceFilesSuppliers.find(
      (s) => s.supplierId === e.target.value
    )
    if (supplier) {
      setSelectedSupplier(supplier)
    }
  }

  const handleUpload = async () => {
    setFileError(null)
    try {
      if (selectedClient && selectedSupplier && acceptedFile) {
        setIsProcessing(true)
        // Request upload url
        const requestUploadResponse =
          await platformHttpService.postAsync<PriceFileRequestUploadResponse>(
            PlatformApiPaths.RequestUploadPriceFile(selectedClient.clientId),
            {
              mimeType: acceptedFile?.type,
              supplierId: selectedSupplier.supplierId,
              originalFileName: acceptedFile?.name,
              originalFileSize: acceptedFile?.size,
              pricesStartDate: fileDateRange.dateFrom?.toISODate(),
              pricesEndDate: fileDateRange.dateTo?.toISODate(),
            },
            'SuppliersPricesBaseUrl'
          )
        if (
          requestUploadResponse.hasErrors ||
          !requestUploadResponse.data?.uploadId
        ) {
          throw new Error('REQUEST_PRICE_FILE_UPLOAD')
        }
        // Upload file to S3
        const s3Response = await baseHttpService.performRequestAsync(
          requestUploadResponse.data?.methodToUse as 'PUT' | 'POST',
          requestUploadResponse.data?.preSignedUrl as string,
          new Uint8Array(await acceptedFile.arrayBuffer()),
          requestUploadResponse.data?.headersToUse,
          undefined,
          false
        )
        if (s3Response.hasErrors) {
          throw new Error('S3_UPLOAD')
        }

        const requestProcessorResponse =
          await platformHttpService.postAsync<void>(
            PlatformApiPaths.ProcessPriceFile(
              selectedClient.clientId,
              requestUploadResponse.data?.uploadId
            ),
            null,
            'SuppliersPricesBaseUrl'
          )
        if (requestProcessorResponse.hasErrors) {
          throw new Error('REQUEST_PRICE_FILE_PROCESSOR')
        }
        setIsProcessing(false)
        onProcessedSuccess({
          uploadId: requestUploadResponse.data?.uploadId,
          fileOriginalName: acceptedFile.name,
          uploadedAt: DateTime.utc().toISO(),
          supplierId: selectedSupplier.supplierId,
          currentState: PriceFileUploadsStates.Processing,
          pricesStartDate: fileDateRange.dateFrom?.toISODate(),
          pricesEndDate: fileDateRange.dateTo?.toISODate(),
          failedReason: null,
        } as UploadedPriceFile)
      }
    } catch (error) {
      setIsProcessing(false)
      setFileError(translation.ErrorUploadingPriceFile(error))
    }
  }
  return (
    <ModalDialog
      closeDisabled={isProcessing}
      onClosed={() => {
        onClose()
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(2),
        }}
      >
        {fileError && (
          <Alert variant="filled" severity="error">
            {fileError}
          </Alert>
        )}
        {acceptedFile && (
          <Alert variant="filled" severity="success">
            {translation.SelectedFile(acceptedFile.name)}
          </Alert>
        )}
        <FileUpload
          dropzoneText={translation.DropZoneTitle}
          dragRejectText={translation.DragRejectText}
          accept={acceptedFileTypes}
          onAcceptedFiles={handleAcceptedFiles}
          onRejectedFiles={handleRejectedFiles}
          isUploading={isProcessing}
          disabled={isProcessing}
        />
        <FormControl>
          <InputLabel id="select-supplier-label">
            {translation.SelectASupplier}
          </InputLabel>
          <Select
            value={selectedSupplier?.supplierId || ''}
            onChange={(e) => {
              handleSelectSupplier(e)
            }}
            data-testid="supplier-select"
            labelId="select-supplier-label"
            label={translation.SelectASupplier}
            disabled={!acceptedFile || isProcessing}
          >
            {priceFilesSuppliers.map((s) => (
              <MenuItem key={s.supplierId} value={s.supplierId}>
                {s.displayName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl>
          <DateRangePickerPopover
            dateFrom={null}
            dateTo={null}
            onDatesSelected={(from, to) => {
              setFileDateRange({ dateFrom: from, dateTo: to })
            }}
            disabled={!acceptedFile || isProcessing}
            maxDate={DateTime.now().plus({ days: 60 })}
            showClearButton={true}
          />
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={() => handleUpload()}
          disabled={
            !acceptedFile ||
            !selectedSupplier ||
            isProcessing ||
            !fileDateRange.dateFrom
          }
          data-testid="show-price-file-upload-button"
        >
          {translation.UploadAndProcess(
            selectedSupplier?.displayName,
            acceptedFile?.name
          )}
        </Button>
      </Box>
    </ModalDialog>
  )
}
export default PriceFileUploadPopup
