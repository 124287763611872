import { FC, useContext, useReducer, useState } from 'react'
import Box from '@mui/material/Box'
import ButtonBase from '@mui/material/ButtonBase'
import Typography from '@mui/material/Typography'
import Chip from '@mui/material/Chip'
import theme from '../../../styles/theme'
import LoadingButton from '@mui/lab/LoadingButton'
import TextField from '@mui/material/TextField'
import { NumericFormat } from 'react-number-format'
import CurrencyInput from '../../../components/Forms/CurrencyInput'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import InfoTooltip from '../../../components/Interactions/InfoTooltip'
import CheckIcon from '@mui/icons-material/Check'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import { SxProps, Theme } from '@mui/material/styles'
import * as yup from 'yup'
import { locale } from '../../../locales'
import { packSizeConverter } from '../../../utils/Helpers'
import {
  AltPriceTypes,
  UnlistedProductId,
  AdjustmentReasons,
  VatEditingModes,
} from '../../../constants'
import { SupplierWithFinancials } from '../../../types/entities/Supplier'
import { useStateUpdatedCallback } from '../../../hooks/useStateUpdatedCallback'
import SelectProductModal from './SelectProductModal'
import { ServiceContext } from '../../../providers/ServicesProvider'
import { CreateUpdateProductResponse } from '../entities/CreateUpdateProductResponse'
import { PlatformApiPaths } from '../../../PlatformApiPaths'
import ConfirmDialog from '../../../components/Interactions/ConfirmDialog'
import { GetErrorMessage } from '../../../utils/ErrorHandling'
import Alert from '@mui/material/Alert'
const translation =
  locale.translation.GoodsInPage.MultiDeliveryForm.MultiDeliveryFormProduct

const editFieldStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  minWidth: 'max-content',
  alignItems: 'center',
  '& label': {
    marginRight: theme.spacing(1),
    fontSize: '.9em',
    '&::after': { content: '":"' },
  },
}

const editFieldTextFieldStyle: SxProps<Theme> = {
  maxWidth: '7ch',
  '& .MuiInputBase-input.Mui-disabled': {
    backgroundColor: theme.palette.grey[50],
    WebkitTextFillColor: theme.palette.grey[800],
  },
}

const loadingButtonStyle: SxProps<Theme> = {
  minWidth: 'unset',
  paddingY: theme.spacing(0.8),
  paddingX: theme.spacing(1),
  maxWidth: '24ch',
}

const creditReasons = AdjustmentReasons[0].reasons.map((r) => {
  return {
    code: r.code,
    description: locale.translation.CreditReasons[r.code],
  }
})

export interface MultiDeliveryFormProductState {
  bookInId: string
  odsCode: string
  productId: string | null
  packSize: number | null
  unitOfMeasure: string | null
  subPackDescription: string | null
  pageNumber: number
  lineNumber: number
  verified: boolean
  manuallyCreated: boolean
  productName: string | null
  productRawOcrName: string | null
  quantity: number | null
  unitPrice: number | null
  netPrice: number | null
  grossPrice: number | null
  vatRate: number | null
  vatCode: string | null
  adjustmentId: string | null
  adjustmentCreditPacks: number | null
  adjustmentCreditReason: string | null
  adjustmentNotes: string | null
  adjustmentPhotoUploadId: string | null
  adjustmentProductEditingDisabled: boolean
  gtin: string | null
  batchNumber: string | null
  expiryDate: string | null
  editingDisabled: boolean
  supplierWithVat?: SupplierWithFinancials | null
  altPriceType?: string | null
  vatEditingMode?: string | null
  isReEdit: boolean
  errors?: string[]
  healthscorePenaltyMessages?: string[]
  amppId: string | null

  transferQuantity?: number | null

  enableStockTransfer?: boolean
}

const validationSchema = yup.object().shape({
  productId: yup.string().nullable().required('product'),
  productName: yup.string().nullable().required('product'),
  packSize: yup.number().nullable().required('product'),
  quantity: yup.number().nullable().required('quantity'),
  unitPrice: yup
    .number()
    .nullable()
    .when('altPriceType', (altPriceType, schema) => {
      return altPriceType ? schema.notRequired() : schema.required('unitPrice')
    }),
  netPrice: yup
    .number()
    .nullable()
    .when('altPriceType', ([altPriceType], schema) => {
      return altPriceType === AltPriceTypes.Net
        ? schema.required('netPrice')
        : schema.notRequired()
    })
    .when(['altPriceType', 'quantity'], ([altPriceType, quantity], schema) => {
      return altPriceType === AltPriceTypes.Net && quantity === 0
        ? schema.min(0, 'netPriceOverQuantity').max(0, 'netPriceOverQuantity')
        : schema
    }),
  grossPrice: yup
    .number()
    .nullable()
    .when('vatEditingMode', ([vatEditingMode], schema) => {
      return vatEditingMode === VatEditingModes.Amount
        ? schema.required('grossPrice')
        : schema.notRequired()
    }),
  vatRate: yup
    .number()
    .nullable()
    .when('vatEditingMode', ([vatEditingMode], schema) => {
      return vatEditingMode === VatEditingModes.Rate
        ? schema.required('vatRate')
        : schema.notRequired()
    }),
  vatCode: yup
    .string()
    .nullable()
    .when('vatEditingMode', ([vatEditingMode], schema) => {
      return vatEditingMode === VatEditingModes.Code
        ? schema.required('vatCode')
        : schema.notRequired()
    }),
  adjustmentCreditPacks: yup
    .number()
    .nullable()
    .required('adjustmentCreditPacks')
    .when(
      ['quantity', 'transferQuantity', 'enableStockTransfer'],
      ([quantity, transferQuantity, enableStockTransfer], schema) => {
        if (!enableStockTransfer) {
          return schema.max(quantity, 'creditPacksOverQuantity')
        } else {
          return schema.test(
            'packs-xvalidation',
            'creditPacksOverQuantity',
            (value) => {
              return value + transferQuantity <= quantity
            }
          )
        }
      }
    ),
  adjustmentCreditReason: yup
    .string()
    .nullable()
    .when('adjustmentCreditPacks', ([adjustmentCreditPacks], schema) => {
      return adjustmentCreditPacks > 0
        ? schema.required('adjustmentCreditReason')
        : schema.notRequired()
    }),
})

type SetQuantityAction = { type: 'SET_QUANTITY'; payload: number | null }
type SetUnitPriceAction = { type: 'SET_UNIT_PRICE'; payload: number | null }
type SetNetPriceAction = { type: 'SET_NET_PRICE'; payload: number | null }
type SetGrossPriceAction = { type: 'SET_GROSS_PRICE'; payload: number | null }
type SetVatRateAction = { type: 'SET_VAT_RATE'; payload: number | null }
type SetVatCodeAction = { type: 'SET_VAT_CODE'; payload: string | null }
type SetCreditPacksAction = { type: 'SET_CREDIT_PACKS'; payload: number | null }
type SetCreditReasonAction = {
  type: 'SET_CREDIT_REASON'
  payload: string | null
}
type UpdateProductAction = {
  type: 'UPDATE_PRODUCT'
  payload: {
    productId: string
    packSize: number
    productName: string
    unitOfMeasure: string | null
    gtin: string | null
    batchNumber: string | null
    expiryDate: string | null
    amppId: string | null
    hasAmpp?: boolean
  }
}
type UpdateVerifiedAction = {
  type: 'UPDATE_VERIFIED'
  payload: {
    verified: boolean
    adjustmentId: string | null
  }
}
type SetTransferPacksAction = {
  type: 'SET_TRANSFER_PACKS'
  payload: number | null
}

type Action =
  | SetQuantityAction
  | SetUnitPriceAction
  | SetNetPriceAction
  | SetGrossPriceAction
  | SetVatRateAction
  | SetVatCodeAction
  | SetCreditPacksAction
  | SetCreditReasonAction
  | UpdateProductAction
  | UpdateVerifiedAction
  | SetTransferPacksAction

export const validateProductState = (
  state: MultiDeliveryFormProductState
): string[] => {
  try {
    validationSchema.validateSync(state, { abortEarly: false })
    return []
  } catch (err: any) {
    return err.errors
  }
}

const createCreateUpdateProductPayload = (
  state: MultiDeliveryFormProductState
) => {
  let payload: any = {
    bookInId: state.bookInId,
    vmpName: state.productName,
    productUuid: state.productId,
    packSize: state.packSize,
    quantity: state.quantity,
    unitPrice:
      state.altPriceType === AltPriceTypes.Net ? null : state.unitPrice,
    netPrice: state.altPriceType === AltPriceTypes.Net ? state.netPrice : null,
    grossPrice: state.grossPrice,
    vatRate: state.vatRate,
    vatCode: state.vatCode,
    verified: state.verified,
    pageNumber: state.pageNumber,
    lineNumber: state.lineNumber,
    isReEdit: state.isReEdit,
    gtin: state.gtin,
    batchNumber: state.batchNumber,
    expiryDate: state.expiryDate,
    adjustment:
      state.adjustmentCreditPacks === 0
        ? null
        : {
            adjustmentId: state.adjustmentId,
            adjustmentTypeCode: AdjustmentReasons[0].type.code,
            adjustmentReasonCode: state.adjustmentCreditReason,
            numberOfPacks: state.adjustmentCreditPacks,
            notes: state.adjustmentNotes,
            photoUploadId: state.adjustmentPhotoUploadId,
          },
    amppId: state.amppId,
    transferQuantity: state.transferQuantity,
  }
  return payload
}

const reducer = (
  state: MultiDeliveryFormProductState,
  action: Action
): MultiDeliveryFormProductState => {
  let nextState: MultiDeliveryFormProductState = state
  switch (action.type) {
    case 'SET_QUANTITY':
      nextState = {
        ...state,
        quantity: action.payload ?? null,
      }
      break
    case 'SET_UNIT_PRICE':
      nextState = {
        ...state,
        unitPrice: action.payload,
      }
      break
    case 'SET_NET_PRICE':
      nextState = {
        ...state,
        netPrice: action.payload,
      }
      break
    case 'SET_GROSS_PRICE':
      nextState = {
        ...state,
        grossPrice: action.payload,
      }
      break
    case 'SET_VAT_RATE':
      nextState = {
        ...state,
        vatRate: action.payload,
      }
      break
    case 'SET_VAT_CODE':
      nextState = {
        ...state,
        vatCode: action.payload,
      }
      break
    case 'SET_CREDIT_PACKS':
      nextState = {
        ...state,
        adjustmentCreditPacks: action.payload,
        adjustmentCreditReason:
          (action.payload ?? 0) > 0 ? state.adjustmentCreditReason : null,
      }
      break
    case 'SET_CREDIT_REASON':
      nextState = {
        ...state,
        adjustmentCreditReason: action.payload,
      }
      break
    case 'SET_TRANSFER_PACKS':
      nextState = {
        ...state,
        transferQuantity: action.payload,
      }
      break
    case 'UPDATE_PRODUCT':
      nextState = {
        ...state,
        productId: action.payload.productId,
        packSize: action.payload.packSize,
        productName: action.payload.productName,
        unitOfMeasure: action.payload.unitOfMeasure,
        amppId: action.payload.amppId,
      }
      break
    case 'UPDATE_VERIFIED':
      nextState = {
        ...state,
        verified: action.payload.verified,
        adjustmentId: action.payload.adjustmentId,
      }
      break
  }
  return { ...nextState, errors: validateProductState(nextState) }
}

const MultiDeliveryFormProduct: FC<{
  initialState: MultiDeliveryFormProductState
  onStateUpdated: (state: MultiDeliveryFormProductState) => void
  onInteraction: (state: MultiDeliveryFormProductState) => void
  onProductRemoved: (state: MultiDeliveryFormProductState) => void
  onBusyStateChanged: (busy: boolean) => void
}> = ({
  initialState,
  onStateUpdated,
  onInteraction,
  onProductRemoved,
  onBusyStateChanged,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { platformHttpService } = useContext(ServiceContext)
  const [error, setError] = useState<string | null>(null)

  useStateUpdatedCallback(state, onStateUpdated)

  const hasValidationErrors = () => (state.errors?.length ?? 0) > 0

  const [confirming, setConfirming] = useState<boolean>(false)
  const handleConfirmUnconfirm = async (confirming: boolean) => {
    if (state.errors!.length > 0) return
    onBusyStateChanged(true)
    setError(null)
    setConfirming(true)
    const requestPayload = createCreateUpdateProductPayload({
      ...state,
      verified: confirming,
    })
    let response =
      await platformHttpService.putAsync<CreateUpdateProductResponse>(
        PlatformApiPaths.CreateUpdateDeleteProduct(state.odsCode),
        requestPayload,
        'StockBaseUrl'
      )
    setConfirming(false)

    if (response && !response.hasErrors) {
      dispatch({
        type: 'UPDATE_VERIFIED',
        payload: {
          verified: confirming,
          adjustmentId:
            state.adjustmentCreditPacks === 0
              ? null
              : response.data?.adjustment?.adjustmentId ?? state.adjustmentId,
        },
      })
    } else {
      setError(GetErrorMessage(response?.statusCode))
    }
    onBusyStateChanged(false)
  }

  const [removing, setRemoving] = useState<boolean>(false)
  const handleRemove = async () => {
    setError(null)
    setRemoving(true)
    onBusyStateChanged(true)
    const { bookInId, pageNumber, lineNumber, isReEdit } = state
    const response = await platformHttpService.deleteAsync(
      PlatformApiPaths.CreateUpdateDeleteProduct(state.odsCode),
      {
        bookInId,
        pageNumber,
        lineNumber,
        isReEdit,
      },
      'StockBaseUrl'
    )
    setRemoving(false)
    if (response && !response.hasErrors) {
      onProductRemoved(state)
    } else {
      setError(GetErrorMessage(response?.statusCode))
    }
    onBusyStateChanged(false)
  }

  const renderProductNameAndPackSizeButton = () => {
    return (
      <ButtonBase
        data-testid="product-name-button"
        disableRipple={true}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: theme.spacing(1),
          background: 'transparent',
          justifyContent: 'flex-start',
          fontSize: '1.05em',
          '&.Mui-disabled': {
            WebkitTextFillColor: theme.palette.grey[800],
          },
        }}
        onClick={() => {
          setSelectProductOpen(true)
        }}
        disabled={state.verified || state.editingDisabled}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            cursor: 'pointer',
            '& label': {
              color: Boolean(state.errors?.includes('product'))
                ? theme.palette.error.main
                : state.verified
                ? theme.palette.grey[500]
                : theme.palette.grey[900],
            },
            '&:hover': {
              '& label': {
                color: theme.palette.primary.main,
              },
            },
          }}
        >
          <Typography
            component="label"
            sx={{
              fontSize: '.9em',
              marginRight: theme.spacing(1),
              cursor: 'pointer',
              fontWeight: 600,
              textAlign: 'left',
            }}
          >
            {state.productName ?? translation.UnspecifiedProductName}
          </Typography>
          {state.productName && state.productId !== UnlistedProductId && (
            <Typography
              component="label"
              sx={{
                fontSize: '.9em',
                cursor: 'pointer',
                fontWeight: 600,
                textAlign: 'left',
              }}
            >{`[${translation.PackSize}: ${packSizeConverter(
              state.packSize,
              state.unitOfMeasure,
              state.subPackDescription
            )}]`}</Typography>
          )}
        </Box>
      </ButtonBase>
    )
  }

  const renderUnderProductChips = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
          flexWrap: 'wrap',
          gap: theme.spacing(0.5),
          marginBottom: theme.spacing(0.5),
        }}
      >
        {state.adjustmentProductEditingDisabled && (
          <Chip
            title={translation.ActionedOrReceivedCreditTooltip}
            size="small"
            label={translation.ActionedOrReceivedCredit}
            color={'info'}
          />
        )}
        {state.healthscorePenaltyMessages?.map((m, i) => (
          <Chip
            key={`HS_P_${i}`}
            variant="filled"
            size="small"
            label={translation.HealthscorePenaltyList[m]}
            color={'error'}
            sx={{
              backgroundColor: theme.palette.error.main,
            }}
          />
        ))}
      </Box>
    )
  }

  const renderFields = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: theme.spacing(1),
          flexWrap: 'wrap',
        }}
      >
        {/* Invoice packs */}
        <Box sx={editFieldStyle}>
          <Box component="label">{translation.InvoicePacks}</Box>
          <NumericFormat
            data-testid="invoice-packs-field"
            customInput={TextField}
            thousandSeparator={locale.thousandSeparator}
            decimalSeparator={locale.decimalSeparator}
            decimalScale={0}
            fixedDecimalScale
            allowNegative={false}
            onValueChange={async (v) => {
              const value = parseInt(v.value)
              dispatch({
                type: 'SET_QUANTITY',
                payload: isNaN(value) ? null : value,
              })
            }}
            autoComplete="off"
            value={state.quantity}
            size="small"
            InputProps={{
              sx: { '& input': { textAlign: 'right' } },
            }}
            sx={{
              ...editFieldTextFieldStyle,
            }}
            disabled={state.verified || state.editingDisabled}
            error={
              state.errors?.includes('quantity') ||
              state.errors?.includes('creditPacksOverQuantity') ||
              state.errors?.includes('netPriceOverQuantity')
            }
          />
        </Box>
        {/* Price */}
        {!state.altPriceType && (
          <Box sx={editFieldStyle}>
            <Box component="label">{translation.PricePerUnit}</Box>
            <CurrencyInput
              data-testid="unit-price-field"
              variant="outlined"
              value={state.unitPrice}
              onValueChangedCallback={async (v) => {
                dispatch({
                  type: 'SET_UNIT_PRICE',
                  payload: v,
                })
              }}
              size="small"
              InputProps={{
                sx: { '& input': { textAlign: 'right' } },
              }}
              sx={{
                ...editFieldTextFieldStyle,
                maxWidth: '12ch',
              }}
              disabled={state.verified || state.editingDisabled}
              error={state.errors?.includes('unitPrice')}
            ></CurrencyInput>
          </Box>
        )}
        {state.altPriceType === AltPriceTypes.Net && (
          <Box sx={editFieldStyle}>
            <Box component="label">{translation.NetPrice}</Box>
            <CurrencyInput
              data-testid="net-price-field"
              variant="outlined"
              value={state.netPrice}
              onValueChangedCallback={async (v) => {
                dispatch({
                  type: 'SET_NET_PRICE',
                  payload: v,
                })
              }}
              size="small"
              InputProps={{
                sx: { '& input': { textAlign: 'right' } },
              }}
              sx={{
                ...editFieldTextFieldStyle,
                maxWidth: '12ch',
              }}
              disabled={state.verified || state.editingDisabled}
              error={
                state.errors?.includes('netPrice') ||
                state.errors?.includes('netPriceOverQuantity')
              }
            ></CurrencyInput>
          </Box>
        )}
        {/* VAT editing */}
        {(state.vatEditingMode === VatEditingModes.Amount ||
          state.vatEditingMode === VatEditingModes.AmountNullable) && (
          <Box sx={editFieldStyle}>
            <Box component="label">{translation.PriceWithTax}</Box>
            <CurrencyInput
              data-testid="gross-price-field"
              variant="outlined"
              value={state.grossPrice}
              onValueChangedCallback={async (v) => {
                dispatch({
                  type: 'SET_GROSS_PRICE',
                  payload: v,
                })
              }}
              size="small"
              InputProps={{
                sx: { '& input': { textAlign: 'right' } },
              }}
              sx={{
                ...editFieldTextFieldStyle,
                maxWidth: '12ch',
              }}
              disabled={state.verified || state.editingDisabled}
              error={Boolean(state.errors?.includes('grossPrice'))}
            ></CurrencyInput>
          </Box>
        )}
        {(state.vatEditingMode === VatEditingModes.Rate ||
          state.vatEditingMode === VatEditingModes.RateNullable) && (
          <Box sx={editFieldStyle}>
            <Box component="label">{translation.VatRate}</Box>
            <NumericFormat
              data-testid="vat-rate-input"
              customInput={TextField}
              thousandSeparator={locale.thousandSeparator}
              decimalSeparator={locale.decimalSeparator}
              decimalScale={0}
              fixedDecimalScale
              suffix="%"
              allowNegative={false}
              onValueChange={async (v) => {
                const value = parseFloat(v.value)
                dispatch({
                  type: 'SET_VAT_RATE',
                  payload: isNaN(value) ? null : value,
                })
              }}
              autoComplete="off"
              value={state.vatRate}
              size="small"
              InputProps={{
                sx: { '& input': { textAlign: 'right' } },
              }}
              sx={{
                ...editFieldTextFieldStyle,
                maxWidth: '12ch',
              }}
              disabled={state.verified || state.editingDisabled}
              error={state.errors?.includes('vatRate')}
            />
          </Box>
        )}
        {(state.vatEditingMode === VatEditingModes.Code ||
          state.vatEditingMode === VatEditingModes.CodeNullable) &&
          state.supplierWithVat?.vatMappingDict && (
            <Box sx={editFieldStyle}>
              <Box component="label">{translation.VatCode}</Box>
              <Select
                value={state.vatCode ?? '-'}
                size="small"
                sx={{ width: '8ch' }}
                id="vat-code-select"
                onChange={async (e) => {
                  dispatch({
                    type: 'SET_VAT_CODE',
                    payload: e.target.value === '-' ? null : e.target.value,
                  })
                }}
                data-testid="vat-code-select"
                disabled={state.verified || state.editingDisabled}
                error={state.errors?.includes('vatCode')}
              >
                <MenuItem key={'-'} value={'-'}>
                  {'-'}
                </MenuItem>

                {Object.keys(state.supplierWithVat.vatMappingDict).map(
                  (key) => (
                    <MenuItem key={key} value={`${key}`}>
                      {`${key} (${
                        state.supplierWithVat?.vatMappingDict[key] ?? ''
                      }%)`}
                    </MenuItem>
                  )
                )}
              </Select>
            </Box>
          )}
        {/* Transfer packs */}
        {state.enableStockTransfer && (
          <Box
            sx={{
              ...editFieldStyle,
            }}
          >
            <Box component="label">{translation.TransferPacks}</Box>
            <InfoTooltip
              text={translation.TransferPacksTooltip}
              sx={{ marginRight: theme.spacing(1) }}
            />
            <NumericFormat
              data-testid="transfer-packs-field"
              customInput={TextField}
              thousandSeparator={locale.thousandSeparator}
              decimalSeparator={locale.decimalSeparator}
              decimalScale={0}
              fixedDecimalScale
              allowNegative={false}
              onValueChange={async (v) => {
                const value = parseInt(v.value)
                dispatch({
                  type: 'SET_TRANSFER_PACKS',
                  payload: isNaN(value) ? null : value,
                })
              }}
              autoComplete="off"
              value={state.transferQuantity}
              size="small"
              InputProps={{
                sx: { '& input': { textAlign: 'right' } },
              }}
              sx={{
                ...editFieldTextFieldStyle,
              }}
              disabled={state.verified || state.editingDisabled}
              error={
                state.errors?.includes('adjustmentCreditPacks') ||
                state.errors?.includes('creditPacksOverQuantity')
              }
            />
          </Box>
        )}
        {/* Credit packs */}
        <Box
          sx={{
            ...editFieldStyle,
          }}
        >
          <Box component="label">{translation.CreditPacks}</Box>
          <InfoTooltip
            text={translation.CreditPacksTooltip}
            sx={{ marginRight: theme.spacing(1) }}
          />
          <NumericFormat
            data-testid="credit-packs-field"
            customInput={TextField}
            thousandSeparator={locale.thousandSeparator}
            decimalSeparator={locale.decimalSeparator}
            decimalScale={0}
            fixedDecimalScale
            allowNegative={false}
            onValueChange={async (v) => {
              const value = parseInt(v.value)
              dispatch({
                type: 'SET_CREDIT_PACKS',
                payload: isNaN(value) ? null : value,
              })
            }}
            autoComplete="off"
            value={state.adjustmentCreditPacks}
            size="small"
            InputProps={{
              sx: { '& input': { textAlign: 'right' } },
            }}
            sx={{
              ...editFieldTextFieldStyle,
            }}
            disabled={state.verified || state.editingDisabled}
            error={
              state.errors?.includes('adjustmentCreditPacks') ||
              state.errors?.includes('creditPacksOverQuantity')
            }
          />
        </Box>
        {/* Credit Reason */}
        {(state.adjustmentCreditPacks ?? 0) > 0 && (
          <Box
            sx={{
              ...editFieldStyle,
            }}
          >
            <Box component="label">{translation.CreditReason}</Box>
            <Select
              size="small"
              sx={{
                ...editFieldTextFieldStyle,
                minWidth: '20ch',
                maxWidth: '20ch',
              }}
              value={state.adjustmentCreditReason ?? ''}
              onChange={async (e) =>
                dispatch({
                  type: 'SET_CREDIT_REASON',
                  payload: e.target.value,
                })
              }
              error={state.errors?.includes('adjustmentCreditReason')}
              disabled={
                state.verified ||
                state.editingDisabled ||
                !state.adjustmentCreditPacks
              }
              data-testid="credit-reason-select"
            >
              {creditReasons.map((cr) => (
                <MenuItem key={cr.code} value={cr.code}>
                  {cr.description}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
      </Box>
    )
  }

  const renderButtons = () => {
    return (
      <>
        {!state.verified && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: theme.spacing(1),
            }}
          >
            <LoadingButton
              data-testid="confirm-product-button"
              fullWidth
              disableElevation
              variant="contained"
              color="success"
              disabled={
                state.editingDisabled || hasValidationErrors() || removing
              }
              loading={confirming}
              sx={{ ...loadingButtonStyle }}
              onClick={async () => {
                await handleConfirmUnconfirm(true)
              }}
            >
              <CheckIcon sx={{ display: 'inline' }} />
            </LoadingButton>
            <LoadingButton
              data-testid="remove-product-button"
              disableElevation
              variant="outlined"
              color="error"
              disabled={state.editingDisabled || confirming}
              loading={removing}
              onClick={() => {
                setConfirmRemoveDialogOpen(true)
              }}
              sx={{ ...loadingButtonStyle }}
            >
              <DeleteOutlineIcon />
            </LoadingButton>
          </Box>
        )}
        {state.verified && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              gap: theme.spacing(1),
            }}
          >
            <LoadingButton
              data-testid="unlock-product-button"
              fullWidth
              disableElevation
              variant="outlined"
              color="success"
              loading={confirming}
              disabled={
                state.editingDisabled || state.adjustmentProductEditingDisabled
              }
              sx={{ ...loadingButtonStyle }}
              onClick={async () => {
                await handleConfirmUnconfirm(false)
              }}
            >
              <LockOpenIcon sx={{ display: 'inline' }} />
            </LoadingButton>
          </Box>
        )}
      </>
    )
  }

  const [selectProductOpen, setSelectProductOpen] = useState<boolean>(false)
  const renderSelectProductModal = () => {
    if (!selectProductOpen) return null
    return (
      <SelectProductModal
        product={{
          packSize: state.packSize,
          productId: state.productId,
          productName: state.productName ?? '',
          productRawOcrName: state.productRawOcrName,
          amppId: state.amppId,
        }}
        onClosedCallback={async (selectedProduct) => {
          setSelectProductOpen(false)
          if (selectedProduct) {
            const hasProductChanged =
              selectedProduct.productId !== state.productId ||
              selectedProduct.packSize !== state.packSize
            dispatch({
              type: 'UPDATE_PRODUCT',
              payload: {
                productId: selectedProduct.productId,
                packSize: selectedProduct.packSize,
                productName: selectedProduct.productName,
                unitOfMeasure: selectedProduct.unitOfMeasure,
                gtin: hasProductChanged ? null : state.gtin,
                batchNumber: hasProductChanged ? null : state.batchNumber,
                expiryDate: hasProductChanged ? null : state.expiryDate,
                amppId: selectedProduct.ampp,
                hasAmpp: selectedProduct.hasAmpp,
              },
            })
          }
        }}
        ignoreSpecials={false}
        showUnlistedProduct={true}
      />
    )
  }

  const [confirmRemoveDialogOpen, setConfirmRemoveDialogOpen] = useState(false)
  const renderConfirmRemoveDialog = () => {
    if (!confirmRemoveDialogOpen) return null
    return (
      <ConfirmDialog
        cancelText={translation.DeleteCancel}
        okText={translation.DeleteOk}
        onCancel={() => setConfirmRemoveDialogOpen(false)}
        onOk={async () => {
          setConfirmRemoveDialogOpen(false)
          await handleRemove()
        }}
        title={translation.DeleteDialogTitle}
        text={translation.DeleteDialogText(
          state.productName ?? translation.DeleteUnspecifiedProductName
        )}
        isCancelPrimary={true}
      />
    )
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(2),
          borderBottom: `1px solid ${theme.palette.divider}`,
          padding: theme.spacing(1),
          marginBottom: theme.spacing(1),
        }}
        onFocus={() => {
          onInteraction(state)
        }}
      >
        {error && (
          <Alert variant="filled" severity="error">
            {error}
          </Alert>
        )}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            gap: theme.spacing(2),
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(0.5),
            }}
          >
            {renderProductNameAndPackSizeButton()}
            {renderUnderProductChips()}
            {renderFields()}
          </Box>
          {renderButtons()}
        </Box>
      </Box>
      {renderSelectProductModal()}
      {renderConfirmRemoveDialog()}
    </>
  )
}

export default MultiDeliveryFormProduct
