import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react'
import { useSuppliersUniquePerId } from '../../../hooks/useSuppliers'
import Box from '@mui/material/Box/Box'
import TextField from '@mui/material/TextField/TextField'
import FormControl from '@mui/material/FormControl/FormControl'
import { SelectChangeEvent } from '@mui/material/Select/SelectInput'
import Select from '@mui/material/Select/Select'
import InputLabel from '@mui/material/InputLabel/InputLabel'
import MenuItem from '@mui/material/MenuItem/MenuItem'
import { locale } from '../../../locales'
import theme from '../../../styles/theme'
import Button from '@mui/material/Button/Button'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import EditableSection from './EditableSection'
import { removeEmptyDictionaryValues } from '../../../utils/Helpers'
import { ValidationErrorKeys } from '../../../constants'
const translations =
  locale.translation.SettingsPage.PharmacySettingsTab.PharmacyDetailsContainer
    .SupplierAccounIdMappingSection

const errorKeys = ValidationErrorKeys.PharmacyDetails

export interface SupplierAccountIdSettingsState {
  supplierAccountMaps: { [key: string]: string }
}

interface SupplierAccounIdMappingSectionState {
  supplierAccountMapsSettings: SupplierAccountIdSettingsState
  supplierIdValidationErrors?: { [key: string]: string } | null
  isAddingNewSupplier: boolean
}

interface SetSupplierAccountMapsAction {
  type: 'SET_SUPPLIER_ACCOUNT_MAPS'
  payload: { [key: string]: string }
}

interface SetSupplierIdValidationErrorsAction {
  type: 'SET_SUPPLIER_ID_VALIDATION_ERRORS'
  payload: { [key: string]: string }
}

interface SetIsAddingNewSupplierAction {
  type: 'SET_IS_ADDING_NEW_SUPPLIER'
  payload: boolean
}
interface ClearValidationErrorsAction {
  type: 'CLEAR_VALIDATION_ERRORS'
  payload: { [key: string]: string }
}
type SupplierAccounIdMappingSectionAction =
  | SetSupplierAccountMapsAction
  | SetSupplierIdValidationErrorsAction
  | SetIsAddingNewSupplierAction
  | ClearValidationErrorsAction

const reducer = (
  state: SupplierAccounIdMappingSectionState,
  action: SupplierAccounIdMappingSectionAction
) => {
  let nextState: SupplierAccounIdMappingSectionState = state
  switch (action.type) {
    case 'SET_SUPPLIER_ACCOUNT_MAPS':
      nextState = {
        ...state,
        supplierAccountMapsSettings: {
          ...state.supplierAccountMapsSettings,
          supplierAccountMaps: action.payload,
        },
      }
      break
    case 'SET_SUPPLIER_ID_VALIDATION_ERRORS':
      nextState = {
        ...state,
        supplierIdValidationErrors: action.payload,
      }
      break
    case 'SET_IS_ADDING_NEW_SUPPLIER':
      nextState = {
        ...state,
        isAddingNewSupplier: action.payload,
      }
      break
    case 'CLEAR_VALIDATION_ERRORS':
      nextState = {
        ...state,
        supplierIdValidationErrors: null,
        supplierAccountMapsSettings: {
          ...state.supplierAccountMapsSettings,
          supplierAccountMaps: action.payload,
        },
      }
      break
  }
  return nextState
}

export const supplierAccountIdSettingsInitialEmptyState = {
  supplierAccountMaps: {},
}

const SupplierAccounIdMappingSection: FC<{
  onChangesApplied: (state: SupplierAccountIdSettingsState) => Promise<boolean>
  initialState: SupplierAccountIdSettingsState | null
  clientInternalSuppliers?: { [key: string]: string }
  validationErrors?: { [key: string]: string[] } | null
}> = (props) => {
  const { suppliersIdDisplayNameDictionary } = useSuppliersUniquePerId()
  const [isEditingSupplierAccountIds, setIsEditingSupplierAccountIds] =
    useState(false)
  const [state, dispatch] = useReducer(reducer, {
    supplierAccountMapsSettings:
      props.initialState ??
      supplierAccountIdSettingsInitialEmptyState.supplierAccountMaps,
  } as SupplierAccounIdMappingSectionState)

  const [hasAccountIdErrors, setHasAccountIdErrors] = useState(false)

  const [validationErrors, setValidationErrors] = useState(
    props.validationErrors
  )

  useEffect(() => {
    setValidationErrors(props.validationErrors)
  }, [props.validationErrors])

  useEffect(() => {
    const errors: { [key: string]: string } = {}

    if (!isEditingSupplierAccountIds) {
      dispatch({
        type: 'CLEAR_VALIDATION_ERRORS',
        payload:
          props.initialState?.supplierAccountMaps ??
          supplierAccountIdSettingsInitialEmptyState.supplierAccountMaps,
      })
      setHasAccountIdErrors(false)
    }

    // remove errors if the list is empty
    if (
      Object.keys(state.supplierAccountMapsSettings.supplierAccountMaps)
        .length === 0
    ) {
      if (Object.keys(validationErrors ?? {}).length > 0) {
        dispatch({
          type: 'CLEAR_VALIDATION_ERRORS',
          payload: {},
        })
      }
      if (hasAccountIdErrors) {
        setHasAccountIdErrors(false)
      }
      return
    }

    // set unique validation errors
    if (validationErrors?.[errorKeys.SupplierAccountMaps]) {
      validationErrors?.[errorKeys.SupplierAccountMaps].forEach((id) => {
        errors[id] = translations.AccountIdNotUnique
      })
    }
    // set empty validation errors
    Object.keys(state.supplierAccountMapsSettings.supplierAccountMaps).forEach(
      (key) => {
        if (!state.supplierAccountMapsSettings.supplierAccountMaps[key]) {
          errors[key] = translations.AccountIdEmptyError
          setHasAccountIdErrors(true)
        } else {
          setHasAccountIdErrors(false)
        }
      }
    )
    dispatch({
      type: 'SET_SUPPLIER_ID_VALIDATION_ERRORS',
      payload: errors,
    })
  }, [
    isEditingSupplierAccountIds,
    props,
    validationErrors,
    state.supplierAccountMapsSettings.supplierAccountMaps,
    hasAccountIdErrors,
  ])

  const allSuppliersInSystem = useMemo(() => {
    return Object.keys(suppliersIdDisplayNameDictionary)
      .map((k) => {
        return {
          id: k,
          name: suppliersIdDisplayNameDictionary[k],
        }
      })
      .concat(
        props.clientInternalSuppliers
          ? Object.keys(props.clientInternalSuppliers).map((k) => {
              return {
                id: k,
                name: props.clientInternalSuppliers![k],
              }
            })
          : []
      )
  }, [props.clientInternalSuppliers, suppliersIdDisplayNameDictionary])

  const availableSupplers = useMemo(() => {
    return allSuppliersInSystem.filter(
      (s) =>
        !Object.keys(
          state.supplierAccountMapsSettings.supplierAccountMaps
        ).includes(s.id)
    )
  }, [
    allSuppliersInSystem,
    state.supplierAccountMapsSettings.supplierAccountMaps,
  ])

  const handleAddSupplierMap = (key: string, value: string) => {
    dispatch({
      type: 'SET_SUPPLIER_ACCOUNT_MAPS',
      payload: {
        ...state.supplierAccountMapsSettings.supplierAccountMaps,
        [key]: value,
      },
    })
    setHasAccountIdErrors(false)
  }

  const handleRemoveSupplierMap = (key: string) => {
    const filteredSupplierAccountMaps = Object.fromEntries(
      Object.entries(
        state.supplierAccountMapsSettings.supplierAccountMaps
      ).filter(([k]) => k !== key)
    )
    dispatch({
      type: 'SET_SUPPLIER_ACCOUNT_MAPS',
      payload: filteredSupplierAccountMaps,
    })
  }

  const handleApply = useCallback(async () => {
    const updatedSupplierAccountMaps = {
      ...removeEmptyDictionaryValues(
        state.supplierAccountMapsSettings.supplierAccountMaps
      ),
    }
    const isSuccess = await props.onChangesApplied({
      supplierAccountMaps: updatedSupplierAccountMaps,
    })
    if (isSuccess) {
      dispatch({
        type: 'SET_SUPPLIER_ACCOUNT_MAPS',
        payload: updatedSupplierAccountMaps,
      })
      setIsEditingSupplierAccountIds(false)
    }
  }, [props, state.supplierAccountMapsSettings.supplierAccountMaps])

  const handleCancel = useCallback(() => {
    setIsEditingSupplierAccountIds(false)
    dispatch({
      type: 'SET_SUPPLIER_ACCOUNT_MAPS',
      payload: {
        ...(props.initialState?.supplierAccountMaps ??
          supplierAccountIdSettingsInitialEmptyState.supplierAccountMaps),
      },
    })
    setValidationErrors(null)
  }, [props.initialState?.supplierAccountMaps])

  const renderNewSupplierMap = () => {
    if (!state.isAddingNewSupplier || !isEditingSupplierAccountIds) return null

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: theme.spacing(5),
        }}
      >
        <FormControl sx={{ flexGrow: 1 }}>
          <InputLabel id="select-supplier-label">
            {translations.SelectSupplier}
          </InputLabel>
          <Select
            labelId="select-supplier-label"
            value={''}
            onChange={(e: SelectChangeEvent) => {
              dispatch({
                type: 'SET_SUPPLIER_ACCOUNT_MAPS',
                payload: {
                  ...state.supplierAccountMapsSettings.supplierAccountMaps,
                  [e.target.value as string]: '',
                },
              })
              dispatch({
                type: 'SET_IS_ADDING_NEW_SUPPLIER',
                payload: false,
              })
            }}
            label={translations.SelectSupplier}
            disabled={!isEditingSupplierAccountIds}
          >
            {availableSupplers.map((s) => (
              <MenuItem key={s.id} value={s.id}>
                {s.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div
          style={{
            flexGrow: 1,
          }}
        />
        <div
          style={{
            width: '40px',
          }}
        />
      </Box>
    )
  }

  return (
    <EditableSection
      sectionTitle={translations.SupplierAccountIdsTitle}
      onUpdate={handleApply}
      updateDisabled={hasAccountIdErrors}
      onCancel={handleCancel}
      onEditingChanged={setIsEditingSupplierAccountIds}
      titleSx={{ fontWeight: 600 }}
      isEditing={isEditingSupplierAccountIds}
      disableAutoClose={true}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(1),
          marginTop: theme.spacing(1),
        }}
      >
        {state.supplierAccountMapsSettings.supplierAccountMaps &&
          Object.keys(
            state.supplierAccountMapsSettings.supplierAccountMaps
          ).map((k) => {
            const supplier = allSuppliersInSystem.find((s) => s.id === k)
            if (supplier) {
              return (
                <Box
                  key={supplier.id}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: theme.spacing(1),
                  }}
                >
                  <TextField
                    label={translations.Supplier}
                    value={supplier.name}
                    variant="outlined"
                    inputProps={{ readOnly: true }}
                    sx={{ pointerEvents: 'none' }}
                    fullWidth
                    disabled={!isEditingSupplierAccountIds}
                  />
                  <TextField
                    label={translations.AccountId}
                    value={
                      state.supplierAccountMapsSettings.supplierAccountMaps[k]
                    }
                    onChange={(e) => {
                      handleAddSupplierMap(k, e.target.value)
                    }}
                    variant="outlined"
                    fullWidth
                    disabled={!isEditingSupplierAccountIds}
                    error={Boolean(state.supplierIdValidationErrors?.[k])}
                    helperText={state.supplierIdValidationErrors?.[k]}
                  />
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() =>
                      handleRemoveSupplierMap(supplier.id as string)
                    }
                    disabled={!isEditingSupplierAccountIds}
                    sx={{
                      minWidth: '60px',
                      height: '53px',
                      alignSelf: 'flex-start',
                    }}
                  >
                    <CloseIcon sx={{ fontSize: '1.5em' }} />
                  </Button>
                </Box>
              )
            }
            return undefined
          })}
        {renderNewSupplierMap()}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: theme.spacing(1),
            justifyContent: 'flex-start',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              dispatch({ type: 'SET_IS_ADDING_NEW_SUPPLIER', payload: true })
            }
            disabled={
              !isEditingSupplierAccountIds ||
              !Boolean(Object.keys(availableSupplers).length > 0)
            }
          >
            <AddIcon sx={{ fontSize: '1.5em' }} />
          </Button>
        </Box>
      </Box>
    </EditableSection>
  )
}

export default SupplierAccounIdMappingSection
