import { FC, useCallback, useReducer } from 'react'
import Box from '@mui/material/Box'
import theme from '../../../styles/theme'
import Typography from '@mui/material/Typography'
import { locale } from '../../../locales'
import TextField from '@mui/material/TextField/TextField'
import SupplierAccounIdMappingSection, {
  supplierAccountIdSettingsInitialEmptyState,
  SupplierAccountIdSettingsState,
} from './SupplierAccountIdMapping'
import EmailForwardingSection, {
  emailForwardingSettingsInitialEmptyState,
  EmailForwardingSettingsState,
} from './EmailForwardingSection'
import ComplianceEmailSettingsSection, {
  complianceEmailSettingsInitialEmptyState,
  ComplianceEmailSettingsState,
} from './ComplianceEmailSettigsSection'
import { ChildState } from '../../../types/entities/ChildState'
import BarcodeScanningSection, {
  barcodeScanningSettingsInitialEmptyState,
  BarcodeScanningSettingsState,
} from './BarcodeScanningSettingsSection'

const translations =
  locale.translation.SettingsPage.PharmacySettingsTab.PharmacyDetailsContainer

interface PharmacyDetailsContainerState {
  complianceEmailsSectionSettings: ChildState<ComplianceEmailSettingsState>
  barcodeScanningSectionSettings: ChildState<BarcodeScanningSettingsState>
  supplierAccountMapsSectionSettings: ChildState<SupplierAccountIdSettingsState>
  validationErrors: { [key: string]: string[] }
  emailForwardingSectionSettings: ChildState<EmailForwardingSettingsState>
}

type SetComplianceEmailsSectionSettingsAction = {
  type: 'SET_COMPLIANCE_EMAILS_SECTION_SETTINGS'
  payload: ComplianceEmailSettingsState
}

type SetBarcodeScanningSectionAction = {
  type: 'SET_BARCODE_SCANNING_SECTION'
  payload: BarcodeScanningSettingsState
}

type SetSupplierAccountMapsAction = {
  type: 'SET_SUPPLIER_ACCOUNT_MAPS'
  payload: SupplierAccountIdSettingsState
}

type SetEmailForwardingSettingsAction = {
  type: 'SET_EMAIL_FORWARDING_SETTINGS'
  payload: EmailForwardingSettingsState
}

type Actions =
  | SetSupplierAccountMapsAction
  | SetEmailForwardingSettingsAction
  | SetComplianceEmailsSectionSettingsAction
  | SetBarcodeScanningSectionAction

const reducer = (
  state: PharmacyDetailsContainerState,
  action: Actions
): PharmacyDetailsContainerState => {
  switch (action.type) {
    case 'SET_COMPLIANCE_EMAILS_SECTION_SETTINGS':
      return {
        ...state,
        complianceEmailsSectionSettings: {
          state: action.payload,
          key: Math.random(),
        },
      }
    case 'SET_BARCODE_SCANNING_SECTION':
      return {
        ...state,
        barcodeScanningSectionSettings: {
          state: action.payload,
          key: Math.random(),
        },
      }
    case 'SET_SUPPLIER_ACCOUNT_MAPS':
      return {
        ...state,
        supplierAccountMapsSectionSettings: {
          state: action.payload,
          key: Math.random(),
        },
      }
    case 'SET_EMAIL_FORWARDING_SETTINGS':
      return {
        ...state,
        emailForwardingSectionSettings: {
          state: {
            email: action.payload.email,
            suppliers: action.payload.suppliers,
          },
          key: Math.random(),
        },
      }
    default:
      return state
  }
}

const PharmacyDetailsContainer: FC<{
  authId: string
  pharmacyName: string
  odsCode: string
  emails: string[]
  phoneNumber: string
  supplierAccountMaps?: { [key: string]: string }
  emailForwarding?: EmailForwardingSettingsState
  clientInternalSuppliers?: { [key: string]: string }
  autoEdit?: boolean
  isBarcodeScanningEnabled: boolean
  isBarcodeScanningMandatory: boolean
  isScanOneEnterQuantityEnabled: boolean
  onUpdatedPharmacyDetails: (
    odsCode: string,
    emails: string[],
    phoneNumber: string,
    isBarcodeScanningMandatory: boolean,
    isScanOneEnterQuantityEnabled: boolean,
    supplierAccountMaps: { [key: string]: string },
    emailForwarding: EmailForwardingSettingsState
  ) => Promise<boolean>
  validationErrors?: { [key: string]: string[] } | null
}> = (props) => {
  const [state, dispatch] = useReducer(reducer, {
    complianceEmailsSectionSettings: {
      state: {
        emails: props.emails ?? complianceEmailSettingsInitialEmptyState.emails,
        phoneNumber:
          props.phoneNumber ??
          complianceEmailSettingsInitialEmptyState.phoneNumber,
      },
      key: Math.random(),
    },
    barcodeScanningSectionSettings: {
      state: {
        isBarcodeScanningMandatory:
          props.isBarcodeScanningMandatory ??
          barcodeScanningSettingsInitialEmptyState.isBarcodeScanningMandatory,
        isScanOneEnterQuantityEnabled:
          props.isScanOneEnterQuantityEnabled ??
          barcodeScanningSettingsInitialEmptyState.isScanOneEnterQuantityEnabled,
      },
      key: Math.random(),
    },
    supplierAccountMapsSectionSettings: {
      state: {
        supplierAccountMaps:
          props.supplierAccountMaps ??
          supplierAccountIdSettingsInitialEmptyState.supplierAccountMaps,
      },
      key: Math.random(),
    },
    emailForwardingSectionSettings: {
      state: {
        email:
          props.emailForwarding?.email ??
          emailForwardingSettingsInitialEmptyState.email,
        suppliers:
          props.emailForwarding?.suppliers ??
          emailForwardingSettingsInitialEmptyState.suppliers,
      },
      key: Math.random(),
    },
  } as PharmacyDetailsContainerState)

  const handlePharmacyDetailsUpdate = useCallback(
    async (
      states: {
        emailSettingsState?: ComplianceEmailSettingsState
        barcodeScanningSettingsState?: BarcodeScanningSettingsState
        supplierAccountMapsState?: SupplierAccountIdSettingsState
        emailForwardingState?: EmailForwardingSettingsState
      },
      onSuccess: () => void
    ): Promise<boolean> => {
      states.emailSettingsState =
        states.emailSettingsState ?? state.complianceEmailsSectionSettings.state
      states.barcodeScanningSettingsState =
        states.barcodeScanningSettingsState ??
        state.barcodeScanningSectionSettings.state
      states.supplierAccountMapsState =
        states.supplierAccountMapsState ??
        state.supplierAccountMapsSectionSettings.state
      states.emailForwardingState =
        states.emailForwardingState ??
        state.emailForwardingSectionSettings.state

      const isSuccess = await props.onUpdatedPharmacyDetails(
        props.odsCode,
        states.emailSettingsState.emails,
        states.emailSettingsState.phoneNumber,
        states.barcodeScanningSettingsState.isBarcodeScanningMandatory,
        states.barcodeScanningSettingsState.isScanOneEnterQuantityEnabled,
        states.supplierAccountMapsState.supplierAccountMaps,
        states.emailForwardingState
      )
      if (isSuccess) {
        onSuccess()
      }
      return isSuccess
    },

    [
      props,
      state.barcodeScanningSectionSettings.state,
      state.complianceEmailsSectionSettings.state,
      state.emailForwardingSectionSettings.state,
      state.supplierAccountMapsSectionSettings.state,
    ]
  )

  const handleEmailDetailsUpdated = useCallback(
    async (emailSettingsState: ComplianceEmailSettingsState) => {
      return await handlePharmacyDetailsUpdate(
        {
          emailSettingsState,
        },
        () => {
          dispatch({
            type: 'SET_COMPLIANCE_EMAILS_SECTION_SETTINGS',
            payload: emailSettingsState,
          })
        }
      )
    },
    [handlePharmacyDetailsUpdate]
  )

  const handleBarcodeScanningSettingsUpdated = useCallback(
    async (barcodeScanningSettingsState: BarcodeScanningSettingsState) => {
      return await handlePharmacyDetailsUpdate(
        {
          barcodeScanningSettingsState,
        },
        () => {
          dispatch({
            type: 'SET_BARCODE_SCANNING_SECTION',
            payload: barcodeScanningSettingsState,
          })
        }
      )
    },
    [handlePharmacyDetailsUpdate]
  )

  const handleSupplierAccountMapsUpdated = useCallback(
    async (updatedSupplierAccountMaps: SupplierAccountIdSettingsState) => {
      return await handlePharmacyDetailsUpdate(
        {
          supplierAccountMapsState: updatedSupplierAccountMaps,
        },
        () => {
          dispatch({
            type: 'SET_SUPPLIER_ACCOUNT_MAPS',
            payload: updatedSupplierAccountMaps,
          })
        }
      )
    },
    [handlePharmacyDetailsUpdate]
  )

  const handleEmailForwardingConfigurationsUpdated = useCallback(
    async (emailForwardingConfig: EmailForwardingSettingsState) => {
      return await handlePharmacyDetailsUpdate(
        {
          emailForwardingState: emailForwardingConfig,
        },
        () => {
          dispatch({
            type: 'SET_EMAIL_FORWARDING_SETTINGS',
            payload: emailForwardingConfig,
          })
        }
      )
    },
    [handlePharmacyDetailsUpdate]
  )

  const renderComplianceEmailsSection = () => {
    return (
      <ComplianceEmailSettingsSection
        initialState={state.complianceEmailsSectionSettings.state}
        onChangesApplied={handleEmailDetailsUpdated}
        key={state.complianceEmailsSectionSettings.key}
      />
    )
  }

  const renderBarcodeScanningSection = () => {
    return (
      <BarcodeScanningSection
        onChangesApplied={handleBarcodeScanningSettingsUpdated}
        initialState={state.barcodeScanningSectionSettings.state}
        isBarcodeScanningEnabled={props.isBarcodeScanningEnabled}
        pharmacyName={props.pharmacyName}
        key={state.barcodeScanningSectionSettings.key}
      />
    )
  }

  const renderSupplierAccountIdsSection = () => {
    return (
      <SupplierAccounIdMappingSection
        initialState={state.supplierAccountMapsSectionSettings.state}
        clientInternalSuppliers={props.clientInternalSuppliers}
        onChangesApplied={handleSupplierAccountMapsUpdated}
        validationErrors={props.validationErrors}
        key={state.supplierAccountMapsSectionSettings.key}
      />
    )
  }

  const renderEmailForwardingSection = () => {
    return (
      <EmailForwardingSection
        initialState={state.emailForwardingSectionSettings.state}
        onChangesApplied={handleEmailForwardingConfigurationsUpdated}
        key={state.emailForwardingSectionSettings.key}
      />
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        gap: theme.spacing(2),
        padding: theme.spacing(1),
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(1),
        }}
      >
        <Typography
          sx={{
            paddingY: theme.spacing(1),
            margin: 0,
            color: theme.palette.grey[700],
          }}
          variant="button"
        >
          {translations.PharmacyDetails}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: theme.spacing(2),
            marginBottom: theme.spacing(2),
          }}
        >
          <TextField
            label={translations.PharmacyNameLabel}
            value={props.pharmacyName}
            variant="outlined"
            disabled
            fullWidth
          />
          <TextField
            label={translations.OdsCodeLabel}
            value={props.odsCode}
            variant="outlined"
            disabled
            fullWidth
          />
        </Box>
        {renderComplianceEmailsSection()}
        {renderBarcodeScanningSection()}
        {renderSupplierAccountIdsSection()}
        {renderEmailForwardingSection()}
      </Box>
    </Box>
  )
}

export default PharmacyDetailsContainer
