import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Popover from '@mui/material/Popover'
import MenuItem from '@mui/material/MenuItem'
import List from '@mui/material/List'
import { FC, useState } from 'react'
import { SxProps, Theme } from '@mui/material/styles'
import theme from '../../styles/theme'
import CircularProgress from '@mui/material/CircularProgress'

export interface DropdownButtonProps {
  options: { key: string; label: string; isDisabled?: boolean }[]
  onOptionClick: (key: string) => void
  icon?: React.ReactNode
  sx?: SxProps<Theme>
  isDisabled: boolean
  isLoading?: boolean
}

const DropdownButton: FC<DropdownButtonProps> = ({
  options,
  onOptionClick,
  icon,
  sx = {},
  isDisabled,
  isLoading,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const popOverOpen = Boolean(anchorEl)

  const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleOptionClick = (key: string) => {
    onOptionClick(key)
    setAnchorEl(null)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        variant="outlined"
        onClick={handleButtonClick}
        sx={{
          ...sx,
        }}
        data-testid="dropdown-button"
        disabled={isDisabled}
      >
        {isLoading ? <CircularProgress size={20} /> : icon}
      </Button>

      <Popover
        open={popOverOpen}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        elevation={3}
      >
        <Box
          sx={{
            minWidth: 200,
            paddingY: theme.spacing(1),
          }}
        >
          <List>
            {options.map(({ key, label, isDisabled }) => (
              <MenuItem
                key={key}
                onClick={() => handleOptionClick(key)}
                data-testid="dropdown-button-option"
                disabled={isDisabled}
              >
                {label}
              </MenuItem>
            ))}
          </List>
        </Box>
      </Popover>
    </>
  )
}

export default DropdownButton
