import { FC, useCallback, useContext, useState } from 'react'
import ModalDialog from '../../../components/Interactions/ModalDialog'
import { DateTime } from 'luxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import { locale } from '../../../locales'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import theme from '../../../styles/theme'
import { ServiceContext } from '../../../providers/ServicesProvider'
import { PlatformApiPaths } from '../../../PlatformApiPaths'
import { GetErrorMessage } from '../../../utils/ErrorHandling'
import LoadingButton from '@mui/lab/LoadingButton'
import { Alert } from '@mui/material'

const translation =
  locale.translation.PriceFilesPage.TabUploads.EditPriceFileDatesDialog

const EditPriceFileDatesDialog: FC<{
  clientId: string
  uploadId: string
  startDate: DateTime
  endDate: DateTime | null
  onClosed: () => void
}> = ({ onClosed, ...props }) => {
  const { platformHttpService } = useContext(ServiceContext)
  const [dateFrom, setDateFrom] = useState<DateTime>(props.startDate)
  const [dateTo, setDateTo] = useState<DateTime | null>(props.endDate)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const handleUpdate = useCallback(async () => {
    setError(null)
    setIsLoading(true)
    const response = await platformHttpService.putAsync<void>(
      PlatformApiPaths.UpdatePriceFileDates(
        props.clientId,
        props.uploadId,
        dateFrom.toISODate(),
        dateTo?.toISODate()
      ),
      null,
      'SuppliersPricesBaseUrl'
    )
    if (response.hasErrors) {
      setError(GetErrorMessage(response?.statusCode ?? 0))
      return
    }
  }, [dateFrom, dateTo, platformHttpService, props.clientId, props.uploadId])

  return (
    <ModalDialog
      title={translation.ModifyPriceFileEffectiveDates}
      width={'680px'}
      onClosed={() => {
        onClosed()
      }}
      closeDisabled={isLoading}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {error && (
          <Alert
            severity="error"
            variant="filled"
            sx={{ marginTop: theme.spacing(1) }}
          >
            {error}
          </Alert>
        )}
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
            <Box>
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                value={dateFrom}
                onChange={(newDate) => {
                  setDateFrom(newDate!)
                }}
                renderInput={(params: any) => <TextField {...params} />}
                minDate={DateTime.fromISO('2022-01-01')}
                maxDate={dateTo}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
              }}
            >
              <StaticDatePicker
                displayStaticWrapperAs="desktop"
                value={dateTo}
                onChange={(newDate) => {
                  setDateTo(newDate)
                }}
                renderInput={(params: any) => <TextField {...params} />}
                minDate={dateFrom}
              />
              <Button
                data-testid="clear-end-date-button"
                variant="text"
                onClick={() => setDateTo(null)}
              >
                {translation.ClearEndDate}
              </Button>
            </Box>
          </Box>
        </LocalizationProvider>
        <Divider sx={{ marginY: theme.spacing(1) }} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            gap: 1,
            paddingTop: theme.spacing(1),
          }}
        >
          <LoadingButton
            data-testid="apply-button"
            variant="contained"
            color="error"
            onClick={() => {
              handleUpdate()
            }}
            loading={isLoading}
            disabled={
              props.startDate.toISODate() === dateFrom.toISODate() &&
              props.endDate?.toISODate() === dateTo?.toISODate()
            }
          >
            {translation.Apply}
          </LoadingButton>
          <Button
            data-testid="cancel-button"
            variant="contained"
            color="primary"
            disabled={isLoading}
            onClick={() => {
              onClosed()
            }}
          >
            {translation.Cancel}
          </Button>
        </Box>
      </Box>
    </ModalDialog>
  )
}

export default EditPriceFileDatesDialog
