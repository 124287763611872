import { FC, useCallback, useReducer, useState } from 'react'
import { locale } from '../../../locales'
import EditableSection from './EditableSection'
import InfoTooltip from '../../../components/Interactions/InfoTooltip'
import { NumericFormat } from 'react-number-format'
import Box from '@mui/material/Box/Box'
import SwitchWithInfo from '../../../components/Forms/SwitchWithInfo'
import { SxProps } from '@mui/system/styleFunctionSx/styleFunctionSx'
import { Theme } from '@mui/material/styles/createTheme'
import theme from '../../../styles/theme'
import TextField from '@mui/material/TextField/TextField'
const translations =
  locale.translation.SettingsPage.CompanyManagementTab.CompanyDetailsContainer
    .PricingIntelligenceSection

const editFieldStyle: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  minWidth: 'max-content',
  alignItems: 'center',
  '& label': {
    marginLeft: theme.spacing(1),
    fontSize: '.9em',
  },
}

const editFieldTextFieldStyle: SxProps<Theme> = {
  maxWidth: '7ch',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  '& .MuiInputBase-input.Mui-disabled': {
    backgroundColor: theme.palette.grey[50],
    WebkitTextFillColor: theme.palette.grey[800],
  },
}

export interface PricingIntelligenceSettigsState {
  rollConcessionsOver: boolean
  useSupplierPrices: boolean
  useTradePrices: boolean
  profitableProductsLength: number
  profitThreshold: number
  mostExpensiveProductsLength: number
}

interface PricingIntelligenceSettigsSectionState {
  pricingIntelligenceSettings: PricingIntelligenceSettigsState
}

type SetConcessionsToggleAction = {
  type: 'SET_CONSESSIONS_TOGGLE'
  payload: boolean
}

type SetSupplierPricesToggleAction = {
  type: 'SET_SUPPLIER_PRICES_TOGGLE'
  payload: boolean
}

type SetTradePricesToggleAction = {
  type: 'SET_TRADE_PRICES_TOGGLE'
  payload: boolean
}

type SetProfitableProductsLengthAction = {
  type: 'SET_PROFITABLE_PRODUCTS_LENGTH'
  payload: number
}

type SetProfitThresholdAction = {
  type: 'SET_PROFIT_THRESHOLD'
  payload: number
}

type SetMostExpensiveProductsLengthAction = {
  type: 'SET_MOST_EXPENSIVE_PRODUCTS_LENGTH'
  payload: number
}

type ResetPriceFileReconciliationSectionAction = {
  type: 'RESET_PROBLEM_PRODUCTS_SECTION'
  payload: {
    rollConcessionsOver: boolean
    useSupplierPrices: boolean
    useTradePrices: boolean
    profitableProductsLength: number
    profitThreshold: number
    mostExpensiveProductsLength: number
  }
}

type Actions =
  | SetConcessionsToggleAction
  | SetSupplierPricesToggleAction
  | SetTradePricesToggleAction
  | SetProfitableProductsLengthAction
  | SetProfitThresholdAction
  | SetMostExpensiveProductsLengthAction
  | ResetPriceFileReconciliationSectionAction

const reducer = (
  state: PricingIntelligenceSettigsSectionState,
  action: Actions
) => {
  let nextState: PricingIntelligenceSettigsSectionState = state
  switch (action.type) {
    case 'SET_CONSESSIONS_TOGGLE':
      nextState = {
        ...state,
        pricingIntelligenceSettings: {
          ...state.pricingIntelligenceSettings,
          rollConcessionsOver: action.payload,
        },
      }
      break
    case 'SET_SUPPLIER_PRICES_TOGGLE':
      nextState = {
        ...state,
        pricingIntelligenceSettings: {
          ...state.pricingIntelligenceSettings,
          useSupplierPrices: action.payload,
        },
      }
      break
    case 'SET_TRADE_PRICES_TOGGLE':
      nextState = {
        ...state,
        pricingIntelligenceSettings: {
          ...state.pricingIntelligenceSettings,
          useTradePrices: action.payload,
        },
      }
      break
    case 'SET_PROFITABLE_PRODUCTS_LENGTH':
      nextState = {
        ...state,
        pricingIntelligenceSettings: {
          ...state.pricingIntelligenceSettings,
          profitableProductsLength: action.payload,
        },
      }
      break
    case 'SET_PROFIT_THRESHOLD':
      nextState = {
        ...state,
        pricingIntelligenceSettings: {
          ...state.pricingIntelligenceSettings,
          profitThreshold: action.payload,
        },
      }
      break
    case 'SET_MOST_EXPENSIVE_PRODUCTS_LENGTH':
      nextState = {
        ...state,
        pricingIntelligenceSettings: {
          ...state.pricingIntelligenceSettings,
          mostExpensiveProductsLength: action.payload,
        },
      }
      break
    case 'RESET_PROBLEM_PRODUCTS_SECTION':
      nextState = {
        ...state,
        pricingIntelligenceSettings: {
          ...state.pricingIntelligenceSettings,
          rollConcessionsOver: action.payload.rollConcessionsOver,
          useSupplierPrices: action.payload.useSupplierPrices,
          useTradePrices: action.payload.useTradePrices,
          profitableProductsLength: action.payload.profitableProductsLength,
          profitThreshold: action.payload.profitThreshold,
          mostExpensiveProductsLength:
            action.payload.mostExpensiveProductsLength,
        },
      }
      break
  }
  return nextState
}

export const pricingIntelligenceInitialEmptyState = {
  rollConcessionsOver: false,
  useSupplierPrices: false,
  useTradePrices: false,
  profitableProductsLength: 0,
  profitThreshold: 0,
  mostExpensiveProductsLength: 0,
}

const PricingIntelligenceSettingsSection: FC<{
  onChangesApplied: (
    pricingIntelligence: PricingIntelligenceSettigsState
  ) => Promise<boolean>
  initialState: PricingIntelligenceSettigsState | null
}> = (props) => {
  const [state, dispatch] = useReducer(reducer, {
    pricingIntelligenceSettings:
      props.initialState ?? pricingIntelligenceInitialEmptyState,
  } as PricingIntelligenceSettigsSectionState)

  const [
    isEditingProblemProductsSettings,
    setIsEditingProblemProductsSettings,
  ] = useState(false)

  const handleApply = useCallback(async () => {
    const isSuccess = await props.onChangesApplied(
      state.pricingIntelligenceSettings
    )
    if (isSuccess) {
      setIsEditingProblemProductsSettings(false)
    }
  }, [props, state.pricingIntelligenceSettings])

  const handleCancel = useCallback(() => {
    setIsEditingProblemProductsSettings(false)
    dispatch({
      type: 'RESET_PROBLEM_PRODUCTS_SECTION',
      payload: {
        rollConcessionsOver:
          props.initialState?.rollConcessionsOver ??
          pricingIntelligenceInitialEmptyState.rollConcessionsOver,
        useSupplierPrices:
          props.initialState?.useSupplierPrices ??
          pricingIntelligenceInitialEmptyState.useSupplierPrices,
        useTradePrices:
          props.initialState?.useTradePrices ??
          pricingIntelligenceInitialEmptyState.useTradePrices,
        profitableProductsLength:
          props.initialState?.profitableProductsLength ??
          pricingIntelligenceInitialEmptyState.profitableProductsLength,
        profitThreshold:
          props.initialState?.profitThreshold ??
          pricingIntelligenceInitialEmptyState.profitThreshold,
        mostExpensiveProductsLength:
          props.initialState?.mostExpensiveProductsLength ??
          pricingIntelligenceInitialEmptyState.mostExpensiveProductsLength,
      },
    })
  }, [
    props.initialState?.mostExpensiveProductsLength,
    props.initialState?.profitThreshold,
    props.initialState?.profitableProductsLength,
    props.initialState?.rollConcessionsOver,
    props.initialState?.useSupplierPrices,
    props.initialState?.useTradePrices,
  ])

  return (
    <EditableSection
      sectionTitle={
        translations.MostLeastProfitableProductsSettingsSection.Title
      }
      onUpdate={handleApply}
      updateDisabled={!isEditingProblemProductsSettings}
      onCancel={handleCancel}
      onEditingChanged={setIsEditingProblemProductsSettings}
      titleSx={{ fontWeight: 600 }}
      isEditing={isEditingProblemProductsSettings}
      disableAutoClose={true}
    >
      <SwitchWithInfo
        checked={state.pricingIntelligenceSettings.rollConcessionsOver}
        onChange={() => {
          dispatch({
            type: 'SET_CONSESSIONS_TOGGLE',
            payload: !state.pricingIntelligenceSettings.rollConcessionsOver,
          })
        }}
        testId="company-problem-products-settings-roll-concessions-toggle"
        label={
          translations.MostLeastProfitableProductsSettingsSection.Toggles
            .RollOverConcessions.Label
        }
        infoTooltipText={
          translations.MostLeastProfitableProductsSettingsSection.Toggles
            .RollOverConcessions.Info
        }
        updateDisabled={!isEditingProblemProductsSettings}
      />
      <SwitchWithInfo
        checked={state.pricingIntelligenceSettings.useSupplierPrices}
        onChange={() => {
          dispatch({
            type: 'SET_SUPPLIER_PRICES_TOGGLE',
            payload: !state.pricingIntelligenceSettings.useSupplierPrices,
          })
        }}
        testId="company-problem-products-settings-supplier-price-toggle"
        label={
          translations.MostLeastProfitableProductsSettingsSection.Toggles
            .SupplierNetPrice.Label
        }
        infoTooltipText={
          translations.MostLeastProfitableProductsSettingsSection.Toggles
            .SupplierNetPrice.Info
        }
        updateDisabled={!isEditingProblemProductsSettings}
      />
      <SwitchWithInfo
        checked={state.pricingIntelligenceSettings.useTradePrices}
        onChange={() => {
          dispatch({
            type: 'SET_TRADE_PRICES_TOGGLE',
            payload: !state.pricingIntelligenceSettings.useTradePrices,
          })
        }}
        testId="company-problem-products-settings-trade-price-toggle"
        label={
          translations.MostLeastProfitableProductsSettingsSection.Toggles
            .TradePrice.Label
        }
        infoTooltipText={
          translations.MostLeastProfitableProductsSettingsSection.Toggles
            .TradePrice.Info
        }
        updateDisabled={!isEditingProblemProductsSettings}
      />
      <Box sx={editFieldStyle}>
        <NumericFormat
          data-testid="company-problem-products-profitable-items-field"
          customInput={TextField}
          thousandSeparator={locale.thousandSeparator}
          decimalSeparator={locale.decimalSeparator}
          decimalScale={0}
          fixedDecimalScale
          allowNegative={false}
          onValueChange={async (v) => {
            const value = parseInt(v.value)
            dispatch({
              type: 'SET_PROFITABLE_PRODUCTS_LENGTH',
              payload: value,
            })
          }}
          autoComplete="off"
          value={state.pricingIntelligenceSettings.profitableProductsLength}
          size="small"
          InputProps={{
            sx: { '& input': { textAlign: 'right' } },
          }}
          sx={{
            ...editFieldTextFieldStyle,
          }}
          disabled={!isEditingProblemProductsSettings}
        />
        <Box component="label">
          {
            translations.MostLeastProfitableProductsSettingsSection
              .InputNumericFields.TotalProfitableItemsAmount.Label
          }
        </Box>
        <InfoTooltip
          text={
            translations.MostLeastProfitableProductsSettingsSection
              .InputNumericFields.TotalProfitableItemsAmount.Info
          }
          sx={{ marginRight: theme.spacing(1) }}
        />
      </Box>
      <Box sx={editFieldStyle}>
        <NumericFormat
          data-testid="company-problem-products-threshold-profitable-items-field"
          customInput={TextField}
          thousandSeparator={locale.thousandSeparator}
          decimalSeparator={locale.decimalSeparator}
          decimalScale={0}
          fixedDecimalScale
          allowNegative={false}
          onValueChange={async (v) => {
            const value = parseInt(v.value)
            dispatch({
              type: 'SET_PROFIT_THRESHOLD',
              payload: value,
            })
          }}
          autoComplete="off"
          value={state.pricingIntelligenceSettings.profitThreshold}
          size="small"
          InputProps={{
            sx: { '& input': { textAlign: 'right' } },
          }}
          sx={{
            ...editFieldTextFieldStyle,
          }}
          disabled={!isEditingProblemProductsSettings}
        />
        <Box component="label">
          {
            translations.MostLeastProfitableProductsSettingsSection
              .InputNumericFields.ThresholdProfitableItemsNumber.Label
          }
        </Box>
        <InfoTooltip
          text={
            translations.MostLeastProfitableProductsSettingsSection
              .InputNumericFields.ThresholdProfitableItemsNumber.Info
          }
          sx={{ marginRight: theme.spacing(1) }}
        />
      </Box>
      <Box sx={editFieldStyle}>
        <NumericFormat
          data-testid="company-problem-products-most-expensive-items-field"
          customInput={TextField}
          thousandSeparator={locale.thousandSeparator}
          decimalSeparator={locale.decimalSeparator}
          decimalScale={0}
          fixedDecimalScale
          allowNegative={false}
          onValueChange={async (v) => {
            const value = parseInt(v.value)
            dispatch({
              type: 'SET_MOST_EXPENSIVE_PRODUCTS_LENGTH',
              payload: value,
            })
          }}
          autoComplete="off"
          value={state.pricingIntelligenceSettings.mostExpensiveProductsLength}
          size="small"
          InputProps={{
            sx: { '& input': { textAlign: 'right' } },
          }}
          sx={{
            ...editFieldTextFieldStyle,
          }}
          disabled={!isEditingProblemProductsSettings}
        />
        <Box component="label">
          {
            translations.MostLeastProfitableProductsSettingsSection
              .InputNumericFields.MostExpensiveItemsAmount.Label
          }
        </Box>
        <InfoTooltip
          text={
            translations.MostLeastProfitableProductsSettingsSection
              .InputNumericFields.MostExpensiveItemsAmount.Info
          }
          sx={{ marginRight: theme.spacing(1) }}
        />
      </Box>
    </EditableSection>
  )
}

export default PricingIntelligenceSettingsSection
