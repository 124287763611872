import { FC, useCallback, useReducer, useState } from 'react'
import { locale } from '../../../locales'
import EditableSection from './EditableSection'
import SwitchWithInfo from '../../../components/Forms/SwitchWithInfo'
const translations =
  locale.translation.SettingsPage.PharmacySettingsTab.PharmacyDetailsContainer
    .BarcodeScanningSection

const commonTranslations = locale.translation.Common

export interface BarcodeScanningSettingsState {
  isBarcodeScanningMandatory: boolean
  isScanOneEnterQuantityEnabled: boolean
}

interface BarcodeScanningSectionState {
  barcodeScanningSettings: BarcodeScanningSettingsState
}

type SetBarcodeScanningSectionAction = {
  type: 'SET_BARCODE_SCANNING_SECTION'
  payload: {
    isBarcodeScanningMandatory?: boolean
    isScanOneEnterQuantityEnabled?: boolean
  }
}

type Actions = SetBarcodeScanningSectionAction

const reducer = (state: BarcodeScanningSectionState, action: Actions) => {
  let nextState: BarcodeScanningSectionState = state
  switch (action.type) {
    case 'SET_BARCODE_SCANNING_SECTION':
      nextState = {
        ...state,
        barcodeScanningSettings: {
          isBarcodeScanningMandatory:
            action.payload.isBarcodeScanningMandatory != null
              ? action.payload.isBarcodeScanningMandatory
              : state.barcodeScanningSettings.isBarcodeScanningMandatory,
          isScanOneEnterQuantityEnabled:
            action.payload.isScanOneEnterQuantityEnabled != null
              ? action.payload.isScanOneEnterQuantityEnabled
              : state.barcodeScanningSettings.isScanOneEnterQuantityEnabled,
        },
      }
  }
  return nextState
}

export const barcodeScanningSettingsInitialEmptyState = {
  isBarcodeScanningMandatory: false,
  isScanOneEnterQuantityEnabled: false,
}

const BarcodeScanningSection: FC<{
  onChangesApplied: (state: BarcodeScanningSettingsState) => Promise<boolean>
  initialState: BarcodeScanningSettingsState | null
  isBarcodeScanningEnabled: boolean
  pharmacyName: string
}> = (props) => {
  const [state, dispatch] = useReducer(reducer, {
    barcodeScanningSettings:
      props.initialState ?? barcodeScanningSettingsInitialEmptyState,
  } as BarcodeScanningSectionState)

  const [
    isEditingBarcodeScanningSettings,
    setIsEditingBarcodeScanningSettings,
  ] = useState(false)

  const handleApply = useCallback(async () => {
    const isSuccess = await props.onChangesApplied(
      state.barcodeScanningSettings
    )
    if (isSuccess) {
      setIsEditingBarcodeScanningSettings(false)
    }
  }, [props, state.barcodeScanningSettings])

  const handleCancel = useCallback(() => {
    setIsEditingBarcodeScanningSettings(false)
    dispatch({
      type: 'SET_BARCODE_SCANNING_SECTION',
      payload: {
        isBarcodeScanningMandatory:
          props.initialState?.isBarcodeScanningMandatory ??
          barcodeScanningSettingsInitialEmptyState.isBarcodeScanningMandatory,
        isScanOneEnterQuantityEnabled:
          props.initialState?.isScanOneEnterQuantityEnabled ??
          barcodeScanningSettingsInitialEmptyState.isScanOneEnterQuantityEnabled,
      },
    })
  }, [props])

  return (
    <EditableSection
      sectionTitle={translations.BarcodeScanningSectionTitle}
      onUpdate={handleApply}
      updateDisabled={!isEditingBarcodeScanningSettings}
      onCancel={handleCancel}
      onEditingChanged={setIsEditingBarcodeScanningSettings}
      titleSx={{ fontWeight: 600 }}
      disableAutoClose={true}
      isEditing={isEditingBarcodeScanningSettings}
    >
      <SwitchWithInfo
        checked={state.barcodeScanningSettings.isBarcodeScanningMandatory}
        onChange={() => {
          dispatch({
            type: 'SET_BARCODE_SCANNING_SECTION',
            payload: {
              isBarcodeScanningMandatory:
                !state.barcodeScanningSettings.isBarcodeScanningMandatory,
            },
          })
        }}
        testId="mandatory-barcode-scanning-toggle"
        label={translations.MandatoryBarcodeScanningToggleLabel}
        infoTooltipText={
          isEditingBarcodeScanningSettings
            ? props.isBarcodeScanningEnabled
              ? translations.BarcodeScanningInfo
              : commonTranslations.FeatureNotEnabled(props.pharmacyName)
            : null
        }
        updateDisabled={
          !isEditingBarcodeScanningSettings || !props.isBarcodeScanningEnabled
        }
      />
      <SwitchWithInfo
        checked={state.barcodeScanningSettings.isScanOneEnterQuantityEnabled}
        onChange={() => {
          dispatch({
            type: 'SET_BARCODE_SCANNING_SECTION',
            payload: {
              isScanOneEnterQuantityEnabled:
                !state.barcodeScanningSettings.isScanOneEnterQuantityEnabled,
            },
          })
        }}
        testId="allow-scan-one-enter-quantity-toggle"
        label={translations.AllowScanOneEnterQuantityToggleLabel}
        infoTooltipText={
          isEditingBarcodeScanningSettings
            ? props.isBarcodeScanningEnabled
              ? translations.ScanOnEnterQuantityInfo
              : commonTranslations.FeatureNotEnabled(props.pharmacyName)
            : null
        }
        updateDisabled={
          !isEditingBarcodeScanningSettings || !props.isBarcodeScanningEnabled
        }
      />
    </EditableSection>
  )
}

export default BarcodeScanningSection
